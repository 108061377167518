import { Col, Nav, Row } from "react-bootstrap"
import styled from "styled-components"
import branding from "../branding/branding"
import { device } from "../utils/Device"

export const ProfilePageContainer = styled.div`
    font-family: ${branding.font1};
    background-color: #fff;
`

export const smallerScreen = `(max-width: 1501px)`

export const ScrollContainerRoot = styled.div<{ guestBannerHeight: number }>`
    display: flex;
    background-color: #fff;
    flex-direction: column;
    //padding-top: 5px;
    height: calc(100vh - 60px - 50px - ${(props) => props.guestBannerHeight + "px"}); /* topbar + guestBanner */
    overflow: hidden;
`

export const UserFullName = styled.h3`
    margin-bottom: 10px;
    font-weight: bold;
    font-family: ${branding.font1};
    width: 290px;
    text-align: center;
    color: ${branding.primaryColor};

    font-size: 22px;
    line-height: 20px;

    @media (max-width: 1600px) {
        font-size: 20px;
        line-height: 30px;
    }

    @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 25px;
    }
`

export const UserImageDiv = styled.div`
    display: flex;
    align-items: center;
    width: 125px;
    height: 150px;

    .avatar-styled {
        width: 125px;
        height: 125px;
        line-height: 125px;
    }

    .avt-cont {
        width: 125px;
    }

    @media (max-width: 1600px) {
        width: 120px;
        height: 120px;

        .avatar-styled {
            width: 120px;
            height: 120px;
            line-height: 120px;
        }
    }

    @media (max-width: 1400px) {
        width: 100px;
        height: 100px;

        .avatar-styled {
            width: 100px;
            height: 100px;
            line-height: 100px;
        }
    }

    @media (max-width: 1200px) {
        width: 80px;
        height: 80px;

        .avatar-styled {
            width: 80px;
            height: 80px;
            line-height: 80px;
        }
    }

    &.mobile {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
    }
`

export const UserBasicInfoContainer = styled.div`
    @media ${smallerScreen} {
        margin-left: auto;
        margin-right: auto;
    }
`

export const CustomRow = styled(Row)`
    justify-content: center;
    @media ${smallerScreen} {
        margin-left: 50px;
    }
`

export const DummyDiv = styled.div`
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    width: 290px;

    @media ${smallerScreen} {
        width: 673px;
    }
`

export const UserPositionCompany = styled.p`
    white-space: pre-line;
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
    width: 290px;
    text-align: center;
    padding-right: 5px;
    padding-bottom: 15px;
    color: ${branding.primaryColor};

    @media (max-width: 1600px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 20px;
    }
`

export const UserImageAndNameContainer = styled(Row)`
    justify-content: center;
    text-align: center;
`
export const ProfileContactDetailsContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 290px;

    @media (max-width: 1501px) {
        width: 100%;
    }
`

export const ProfileContactDetailsText = styled.div`
    text-decoration: none;
    font-size: 16px;
    line-height: 28px;
    color: ${branding.mainInfoColor};
    border-radius: 10px;
    margin-left: 20px;
    &:hover {
        color: ${branding.mainInfoColor};
    }

    @media (max-width: 1600px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 18px;
    }
`

export const ProfilePageSectionTitle = styled.div`
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
`

export const BiographyText = styled.p`
    white-space: pre-line;
    font-size: 14px;
    line-height: 20px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`

export const CategoriesRoot = styled.div`
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
`

export const CategoriesTitleRow = styled(Row)`
    margin: 30px 0 0 0;
`

export const CategoriesViewRow = styled(Row)`
    display: flex;
    margin: 20px -5px 20px -5px;
    flex-wrap: wrap;
    justify-content: flex-start;
`

export const CategoriesItem = styled.div<{ common?: Boolean; border?: string; textColor?: string }>`
    display: inline-block;
    padding: 0.5rem;
    font-size: 14px;
    border: ${(props) =>
        props.common ? "none" : props.border ?? branding.personDetailPageContent.interestBorderColor ?? "1px solid #d9d9d9"};
    font-family: ${(props) => (props.common ? branding.font2 : branding.font1)};
    //font-weight: ${(props) => (props.common ? "bold" : "normal")};

    background-color: ${(props) => (props.common ? branding.crsTabs.tabItemDefaultActiveStateColor : "#fff")};
    color: ${(props) =>
        props.common
            ? branding.personDetailPageContent.commonInterestTextColor
            : props.textColor ?? branding.personDetailPageContent.interestTextColor ?? "#000"};
    margin: 0.3rem;
    border-radius: 5px;

    @media ${device.mobile} {
        font-size: 12px;
    }
`

export const ProfileDetailRowHeader = styled(Row)`
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 10px 0;
    justify-content: center;
`

export const PersonImageAndLogo = styled(Col)<{ windowWidth?: number }>`
    margin-top: 80px;
    border-right: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};

    @media ${smallerScreen} {
        border-right: none;
        margin-left: -70px;
    }
`

export const ProfileDetailNameTeaserCol = styled(Col)`
    margin-top: 80px;
    margin-left: 50px;
    width: 673px;
`

export const ProfileDetailNameTeaserWrapper = styled.div`
    margin-top: 80px;
    margin-left: 50px;
    flex: 0 0 90%;
    max-width: 90%;

    @media ${device.laptop} {
        width: 673px;
        flex: unset;
    }
`

export const ProfileContactsWrapper = styled(Nav)<{ isRelevantInfoEmpty: boolean }>`
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 0px;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};

    @media ${device.laptop} {
        border-bottom: ${(props) =>
            props.isRelevantInfoEmpty ? "none" : branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    }

    @media ${device.desktop} {
        border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    }
`

export const ProfileSocialsTitle = styled.div`
    margin-top: 15px;
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding-top: 20px;
    width: 270px;

    &.editProfile {
        width: 290px;
        margin-left: auto;
        margin-right: auto;

        @media ${smallerScreen} {
            width: 625px;
            margin-left: 95px;
        }
    }
    @media ${smallerScreen} {
        width: auto;
    }
`

export const ProfileSocialsWrapper = styled(Nav)`
    margin-top: 15px;
    margin-bottom: 40px;
    padding-top: 20px;
`
export const ProfileSocialItem = styled(Nav.Link)`
    margin-right: 20px;

    &.nav-link {
        padding: 0 !important;
    }
`

export const DeleteProfileLink = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: ${branding.sideIconBar.sideIconColorDark};
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    display: inline;
    :hover {
        color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
    }
`
