import LiveIndicator from "./LiveIndicator/LiveIndicator"
import { StageIndicatorsRoot } from "./StageIndicators.styled"

export function StageIndicators() {
    return (
        <StageIndicatorsRoot>
            <LiveIndicator />
        </StageIndicatorsRoot>
    )
}
