import styled from "styled-components"
import branding from "../../../branding/branding"
import { device } from "../../../utils/Device"

interface TileLogoProps {
    url?: string
    alt: string
    entityType: string
    lobby?: boolean
    showContextMenuOnHover?: boolean
    isMobile?: boolean
}
export function TileLogo(props: TileLogoProps) {
    const { url, alt, entityType } = props
    return (
        <TileLogoRoot className={entityType === "mobile" ? "mobile" : entityType}>
            {url ? (
                <>
                    {!props.isMobile && (
                        <CustomDarkenOverlay contextMenuOpened={props.showContextMenuOnHover} entityType={entityType} />
                    )}
                    <img src={url} alt={alt + " logo"} />
                </>
            ) : (
                <>
                    {!props.isMobile && (
                        <CustomDarkenOverlay contextMenuOpened={props.showContextMenuOnHover} entityType={entityType} />
                    )}
                    <PlaceholderImage entityType={entityType} className={props.isMobile ? "mobile" : ""} />
                </>
            )}
        </TileLogoRoot>
    )
}

const TileLogoRoot = styled.div<{ lobby?: boolean }>`
    width: ${(props) => (props.lobby ? "70%" : "240px")};
    height: ${(props) => (props.lobby ? "45%" : "240px")};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    margin-top: ${(props) => (props.lobby ? "0px" : "-65px")};

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: #fff;
        border: ${(props) => (props.lobby ? "0px solid transparent" : "1px solid #efefef")};
        border-radius: 5px 5px 0px 0px;
    }

    &.product {
        width: 100%;
        height: 80%;
    }

    &.mobile {
        width: 70%;
        height: 45%;
        margin-top: 0px;
        border: 0px solid transparent;
        border-radius: 5px 5px 0px 0px;
    }

    p  {
        font-family: ${branding.font1};
        font-size: 16px;
        margin: 0px !important;

        @media ${device.mobile} {
            font-size: 12px;
        }
    }
`

export const PlaceholderImage = styled.div<{ entityType: string }>`
    position: relative;
    display: flex;
    background: url("/branding/applicationMedia/myFairPagePlaceholder.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: -webkit-fill-available;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #efefef;
    width: ${(props) => (props.entityType === "product" ? "500px" : "240px")};
    height: ${(props) => (props.entityType === "product" ? "250px" : "240px")};
    z-index: 0;

    &.mobile {
        width: 90%;
        height: 90%;
        margin-top: 0px;
    }
`

const CustomDarkenOverlay = styled.div<{ contextMenuOpened?: boolean; entityType: string }>`
    display: flex;
    visibility: ${(props) => (props.contextMenuOpened ? "visible" : "hidden")};
    position: absolute;
    background: ${(props) =>
        props.contextMenuOpened ? "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 78.65%)" : "transparent"};
    width: ${(props) => (props.entityType === "product" ? "458px" : "238px")};
    height: 70px;
    border-radius: 5px 5px 0px 0px;
    z-index: 1;
    top: ${(props) => (props.entityType === "product" ? "20px" : "26px")};
    right: ${(props) => (props.entityType === "product" ? "20px" : "20px")};

    & > * {
        flex-shrink: 0;
    }

    @media ${device.mobile} {
        display: none;
    }
`
