import FileSaver from "file-saver"
import { useEffect, useState } from "react"
import * as React from "react"
import { Col, Row, Tooltip } from "react-bootstrap"
import ReactDOM from "react-dom"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { hasAccessToOrganization } from "../../../utils/UserUtils"
import { trackVisit } from "../../../backendServices/TrackingServices"
import { Attachment, Company } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLoggedInState, User } from "../../../globalStates/LoggedInUser"
import { IconClose, IconPlay, GalleryPlay, IconDownload, IconGalleryArrowLeft, IconGalleryArrowRight } from "../../../ui/Icons"
import { usePrivacyPolicyModal } from "../../../ui/modals/PrivacyPolicyModal"
import { HeaderTitle } from "./StyledComponents"
import { SimpleOrganization } from "../../ExhibitorsPageContent"
import NonGalleryMedia from "./NonGalleryMedia"
import { device } from "../../../utils/Device"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import Slider from "react-slick"
import { DetailSectionTitle } from "./DetailPageSections"
import { trackPageView, trackTakeAction } from "../../../utils/GTMTracking"
import { capitalizeFirstLetter } from "../../../utils/StringUtils"
import VideoPlayerReal from "../../videoPlayer/VideoPlayerReal"

enum GalleryContentTypes {
    JPG = "jpg",
    JPEG = "jpeg",
    PNG = "png",
    MP4 = "mp4",
    GIF = "gif",
    BMP = "bmp",
    MOV = "mov"
}

export enum GalleryTypes {
    VIDEO = "video",
    IMAGE = "image"
}

function isGalleryType(value: string | null) {
    return (
        value === GalleryTypes.VIDEO ||
        value === GalleryTypes.IMAGE ||
        value === GalleryContentTypes.JPEG ||
        value === GalleryContentTypes.JPG ||
        value === GalleryContentTypes.PNG ||
        value === GalleryContentTypes.MP4 ||
        value === GalleryContentTypes.BMP ||
        value === GalleryContentTypes.GIF ||
        value === GalleryContentTypes.MOV
    )
}

export const getContentType = (item: Attachment) => {
    const contentTypeTemp = item.contentType ? item.contentType.substring(0, item.contentType.lastIndexOf("/")) : null
    const contentType = contentTypeTemp
        ? contentTypeTemp
        : item.url
        ? item.url.substring(item.url.lastIndexOf(".") + 1, item.url.length)
        : null
    return contentType
}

export const filterMedia = (items: Attachment[], galleryType: boolean): Attachment[] => {
    if (items && items.length > 0) {
        return items.filter((x) => {
            if (!x.url) return false

            const contentType = getContentType(x)
            return (isGalleryType(contentType) && galleryType) || (!isGalleryType(contentType) && !galleryType)
        })
    }
    return []
}

const GalleryItemRoot = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;

    video {
        object-fit: cover;
        border-radius: 5px;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    & .react-player__preview {
        border-radius: 5px;
    }
`
const DarkenOverlay = styled.div`
    display: flex;
    position: absolute;
    background: linear-gradient(180deg, rgba(32, 36, 40, 0) 0%, #000000 100%);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: 0;
    top: 0;
    right: 0;

    & > * {
        flex-shrink: 0;
    }
`
const MediaTitleRoot = styled.div`
    display: flex;
    flex-flow: column;
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    z-index: 1;

    & ::selection {
        background: transparent;
    }
    & ::-moz-selection {
        background: transparent;
    }
`
const MediaTitle = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    max-width: 220px;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    color: white;
    font-family: ${branding.font1};

    @media (max-width: 1400px) {
        max-width: 70px;
        font-size: 14px;
    }

    @media ${device.mobile} {
        font-size: 12px;
    }

    &.forceMobileDesign {
        font-size: 12px;
    }
`

const PlayIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface GalleryItemProps {
    media: Attachment
    index: number
    onAction: () => void
}

const GalleryItem: React.FunctionComponent<GalleryItemProps> = (props) => {
    const contentType = props.media.contentType
        ? props.media.contentType.substring(0, props.media.contentType.lastIndexOf("/"))
        : props.media.url.substring(props.media.url.lastIndexOf(".") + 1, props.media.url.length)
    const tooltipMessage = branding.organizationDetailPageContent.showFullScreen

    if (
        contentType !== GalleryTypes.VIDEO &&
        contentType !== GalleryContentTypes.MP4 &&
        contentType !== GalleryContentTypes.MOV &&
        contentType !== GalleryContentTypes.GIF
    ) {
        return (
            <CustomOverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {tooltipMessage}
                    </Tooltip>
                }
            >
                <GalleryItemRoot onClick={() => props.onAction()}>
                    <img src={props.media.url} alt={""}></img>
                    <DarkenOverlay />
                    <MediaTitleRoot>
                        <MediaTitle>{props.media.title}</MediaTitle>
                    </MediaTitleRoot>
                </GalleryItemRoot>
            </CustomOverlayTrigger>
        )
    } else {
        return (
            <CustomOverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {tooltipMessage}
                    </Tooltip>
                }
            >
                <GalleryItemRoot onClick={() => props.onAction()}>
                    <PlayIcon>{IconPlay({ width: "40", height: "40", fill: "#fff" })}</PlayIcon>
                    <ReactPlayer
                        width={"100%"}
                        height={"100%"}
                        url={props.media.url}
                        light={
                            props.media.largeThumbnailUrl
                                ? props.media.largeThumbnailUrl
                                : props.media.smallThumbnailUrl
                                ? props.media.smallThumbnailUrl
                                : false
                        }
                        config={{
                            file: {
                                attributes: {
                                    controlsList: "nodownload",
                                    onContextMenu: (e: any) => e.preventDefault()
                                }
                            }
                        }}
                    />
                    <DarkenOverlay />
                    <MediaTitleRoot>
                        <MediaTitle>{props.media.title}</MediaTitle>
                    </MediaTitleRoot>
                </GalleryItemRoot>
            </CustomOverlayTrigger>
        )
    }
}

const GalleryItemCol = styled(Col)<{ newDesign?: boolean }>`
    height: 300px;
    padding: ${(props) => (props.newDesign ? "4px" : "10px")};

    @media (max-width: 1600px) {
        height: 200px;
    }

    @media ${device.mobile} {
        height: 95px;
        padding: 3px;
    }
`

interface GalleryProps {
    media: Attachment[]
    organizationId?: string
    onAction: (att: Attachment) => void
    newDesign?: boolean
}

const GalleryRow = styled(Row)<{ newDesign?: boolean }>`
    margin-left: -3px;
    margin-right: -3px;

    @media ${device.tablet} {
        margin-left: ${(props) => (props.newDesign ? "-3px" : "-15px")};
        margin-right: -15px;
    }
`

export const Gallery: React.FunctionComponent<GalleryProps> = (props) => {
    const logedInUser = useLoggedInState().user()
    const isGuest = !props.organizationId || hasAccessToOrganization(logedInUser!, props.organizationId) === false

    //making grid for gallery
    let x = [3, 6, 3, 6, 3, 3, 3, 3, 6]
    let colValues: Array<number> = []

    props.media.forEach((item, index) => {
        colValues.push(x[index % 9])
    })

    return (
        <GalleryRow newDesign={props.newDesign}>
            {props.media.map((item, index) => {
                return (
                    <GalleryItemCol
                        disable={isGuest}
                        key={index}
                        xs={colValues[index]}
                        onContextMenu={(e: { preventDefault: () => any }) => {
                            if (isGuest) e.preventDefault()
                        }}
                        newDesign={props.newDesign}
                    >
                        <GalleryItem media={item} index={index} onAction={() => props.onAction(item)} />
                    </GalleryItemCol>
                )
            })}
        </GalleryRow>
    )
}

const MediaContainer = styled.div<{ newDesign?: boolean }>`
    width: 100% !important;
    margin-left: ${(props) => (props.newDesign ? "0px" : "25px")};
    margin-right: 25px;

    @media ${device.tablet} {
        margin-left: ${(props) => (props.newDesign ? "0px" : "20px")};
    }
`
const NonGalleryMediaRoot = styled.div<{ marginTop?: string }>`
    margin-top: "0.5rem";
    @media ${device.tablet} {
        margin-top: ${(props) => (props.marginTop ? props.marginTop : "0.5rem")};
    }
`

export interface MediaProps {
    media: Attachment[]
    organizationId?: string
    organization?: Company
    entityType: string
    entityName: string
    newDesign?: boolean
}

export const Media: React.FunctionComponent<MediaProps> = (props) => {
    const filteredMedia = filterMedia(props.media, true)
    filteredMedia.forEach((item, index) => {
        item.index = index
    })

    const user = useLoggedInState().user()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    /* Media Slider */
    const [showMediaSlider, setShowMediaSlider] = useState(false)
    const [selectedAtt, setSelecedAtt] = useState<Attachment>()

    // Exporting media from attachments
    const galleryMedia = filterMedia(props.media, true)
    const otherMedia = filterMedia(props.media, false)

    useEffect(() => {
        if (showMediaSlider) {
            trackPageView(props.entityName, "Media", capitalizeFirstLetter(props.entityType))
        }
        // eslint-disable-next-line
    }, [showMediaSlider])

    const mediaItems: Array<any> = []

    galleryMedia.forEach((item) => {
        mediaItems.push({
            src: item.url,
            width: 4,
            height: 3
        })
    })

    const titleHeader =
        branding.organizationDetailPageContent.mediaTitle +
        " (" +
        (props.entityType && props.entityType === "product" ? otherMedia.length : props.media.length) +
        ")"

    if (!props.media || props.media.length === 0) return null

    return (
        <MediaContainer newDesign={props.newDesign}>
            {(props.entityType && props.entityType === "product" ? otherMedia.length > 0 : props.media.length > 0) &&
                (props.newDesign ? (
                    <DetailSectionTitle marginTop="50px" marginBottom="20px">
                        {titleHeader}
                    </DetailSectionTitle>
                ) : (
                    <HeaderTitle className={props.entityType === "product" ? "product" : "mb-3"}>{titleHeader}</HeaderTitle>
                ))}

            {props.entityType && props.entityType === "product" ? (
                <div />
            ) : (
                <Gallery
                    media={galleryMedia}
                    organizationId={props.organizationId}
                    onAction={(att) => {
                        if (props.organizationId && props.organization)
                            showPrivacyPolicyModal(props.organization, () => {
                                trackVisit(user!.profileId, props.organizationId!, "MEDIA#PREVIEW", att.id)
                                setSelecedAtt(att)
                                setShowMediaSlider(true)
                            })
                        else {
                            setSelecedAtt(att)
                            setShowMediaSlider(true)
                        }
                    }}
                    newDesign={props.newDesign}
                />
            )}

            <NonGalleryMediaRoot
                className="col-12"
                marginTop={props.entityType && props.entityType === "product" ? "0rem" : "0.5rem"}
            >
                <NonGalleryMedia
                    media={otherMedia}
                    marginTop={props.entityType && props.entityType === "product" ? "0rem" : "1.25rem"}
                    entityType={props.entityType}
                    entityName={props.entityName}
                />
            </NonGalleryMediaRoot>
            <PrivacyModal />
            {ReactDOM.createPortal(
                showMediaSlider && (
                    <div>
                        <MediaSlider
                            organizationId={props.organizationId}
                            media={filteredMedia}
                            selectedAtt={selectedAtt}
                            setShowMediaSlider={setShowMediaSlider}
                            isOpenCurrently={showMediaSlider}
                            initialSlide={selectedAtt?.index ?? 0}
                            entityType={props.entityType}
                        />
                    </div>
                ),
                document.body
            )}
        </MediaContainer>
    )
}

/* #region  Media slider */
const MediaSliderContainer = styled.div`
    align-items: center;
    justify-content: center;
    background: black;
    position: static;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999999;
    height: 100%;

    .awssld__bullets {
        bottom: 0px;
        z-index: 10;
    }

    .awssld__bullets button {
        width: 5px;
        height: 5px;
    }

    .awssld__controls {
        visibility: visible !important;
    }

    .awssld__controls button {
        width: 20px !important;
        height: 20px !important;
        top: 50% !important;
    }

    &:focus {
        outline: none;
    }
`

const CenteredDiv = styled.div`
    width: 70%;
`

const CloseIconDiv = styled.div`
    position: absolute;
    //top: -12%;
    //right: -5%;
    top: -40%;
    right: -10%;
    cursor: pointer;

    @media ${device.mobileXL} {
        top: -20%;
        right: -10%;
    }

    @media (min-width: 880px) {
        top: -15%;
    }

    @media ${device.laptop} {
        top: -12%;
        right: -5%;
    }
`

interface MediaSliderProps {
    organizationId?: string
    organization?: Company
    media: Attachment[]
    selectedAtt: Attachment | undefined
    setShowMediaSlider: (value: boolean) => void
    isOpenCurrently: boolean
    initialSlide?: number
    entityType: string
}

export const MediaSlider: React.FunctionComponent<MediaSliderProps> = (props) => {
    const filteredMedia = filterMedia(props.media, true)
    const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

    const sliderRef = React.useRef<any>(null)
    const containerRef = React.useRef<any>(null)
    const previewsRef = React.useRef<any>(null)

    const loggedInUser = useLoggedInState().user()

    const handleSlide = (event: any) => {
        if (event.key === "Escape") props.setShowMediaSlider(false)
        else if (event.key === "ArrowLeft") sliderRef?.current?.slickPrev()
        else if (event.key === "ArrowRight") sliderRef?.current?.slickNext()
    }

    function isPhoto(value: string | null) {
        return (
            value === `${GalleryTypes.IMAGE}/${GalleryContentTypes.JPEG}` ||
            value === `${GalleryTypes.IMAGE}/${GalleryContentTypes.BMP}` ||
            value === `${GalleryTypes.IMAGE}/${GalleryContentTypes.JPG}` ||
            value === `${GalleryTypes.IMAGE}/${GalleryContentTypes.PNG}` ||
            value === `${GalleryTypes.IMAGE}/${GalleryContentTypes.GIF}`
        )
    }

    const sliderSettings = {
        accessibility: true,
        arrows: false,
        speed: 150,
        infinite: false
    }

    React.useEffect(() => {
        if (previewsRef.current) {
            previewsRef.current.scrollTo({ left: activeSlideIndex * 110, top: 0, behavior: "smooth" })
        }
    }, [activeSlideIndex])

    React.useEffect(() => {
        containerRef.current.focus()
    }, [])

    React.useEffect(() => {
        if (props.initialSlide) {
            sliderRef.current.slickGoTo(props.initialSlide)
            setActiveSlideIndex(props.initialSlide)
        }
    }, []) //eslint-disable-line

    props.isOpenCurrently ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset")
    return (
        <>
            <MediaSliderContainer tabIndex={-1} onKeyDown={handleSlide} ref={containerRef}>
                <MediaWrapper onMouseDown={() => props.setShowMediaSlider(false)}>
                    <CenteredDiv onMouseDown={(e) => e.stopPropagation()}>
                        <MediaInfo
                            isVideo={!isPhoto(props.media[activeSlideIndex].contentType)}
                            isInfoEmpty={!props.media[activeSlideIndex].title && !props.media[activeSlideIndex].description}
                        >
                            {props.media[activeSlideIndex].title && (
                                <MediaInfoTitle>{props.media[activeSlideIndex].title}</MediaInfoTitle>
                            )}
                            <DescAndDownloadWrapper
                                doesTitleExist={props.media[activeSlideIndex].title ? true : false}
                                doesDescExist={props.media[activeSlideIndex].description ? true : false}
                            >
                                {props.media[activeSlideIndex].description && (
                                    <MediaInfoDescription>{props.media[activeSlideIndex].description}</MediaInfoDescription>
                                )}
                                {branding.organizationDetailPageContent.videoDownloadEnabled && (
                                    <DownloadIconWrapper
                                        onClick={() => {
                                            trackTakeAction(
                                                `${capitalizeFirstLetter(props.entityType)} Details`,
                                                "Download",
                                                `${capitalizeFirstLetter(props.entityType)}Media`
                                            )
                                            startDownloadProcess(
                                                filteredMedia[activeSlideIndex],
                                                loggedInUser!,
                                                props.organization
                                            )
                                        }}
                                    >
                                        {IconDownload({
                                            fill: "#fff"
                                        })}
                                    </DownloadIconWrapper>
                                )}
                            </DescAndDownloadWrapper>
                        </MediaInfo>
                        <ImageAndDescriptionWrapper>
                            <SliderWrapper>
                                <GallerySlider
                                    {...sliderSettings}
                                    ref={sliderRef}
                                    afterChange={(newIndex) => {
                                        setActiveSlideIndex(newIndex)
                                    }}
                                    initialSlide={activeSlideIndex}
                                >
                                    {filteredMedia.map((media) => {
                                        if (isPhoto(media.contentType))
                                            return (
                                                <div style={{ position: "relative" }}>
                                                    <SliderImage src={media.url}></SliderImage>
                                                </div>
                                            )
                                        else
                                            return (
                                                <div style={{ position: "relative" }}>
                                                    <VideoPlayerReal
                                                        src={media.url}
                                                        controls={true}
                                                        live={false}
                                                        sourceIsStatic={true}
                                                        disablePlayerStateIsSavedAndRead={true}
                                                        remoteStreamLayout={false}
                                                        title={media.title}
                                                    />
                                                </div>
                                            )
                                    })}
                                </GallerySlider>
                                <LeftArrowWrapper
                                    isEnabled={activeSlideIndex !== 0}
                                    onClick={() => {
                                        sliderRef?.current?.slickPrev()
                                    }}
                                >
                                    {IconGalleryArrowLeft({ width: "44", height: "44", fill: "#fff", stroke: "#fff" })}
                                </LeftArrowWrapper>

                                <RightArrowWrapper
                                    isEnabled={activeSlideIndex + 1 !== filteredMedia.length}
                                    onClick={() => {
                                        sliderRef?.current?.slickNext()
                                    }}
                                >
                                    {IconGalleryArrowRight({
                                        width: "44",
                                        height: "44",
                                        fill: "#fff",
                                        stroke: "#fff"
                                    })}
                                </RightArrowWrapper>
                                <CloseIconDiv onClick={() => props.setShowMediaSlider(false)}>
                                    {IconClose({ fill: "#fff", width: "15", height: "15" })}
                                </CloseIconDiv>
                            </SliderWrapper>
                        </ImageAndDescriptionWrapper>
                        <ImagePreviewsContainerWrapper>
                            <ImagePreviewsContainer ref={previewsRef}>
                                {filteredMedia.map((media, index) => {
                                    if (isPhoto(media.contentType)) {
                                        return (
                                            <ImagePreviewWrapper
                                                id="gallerySlider"
                                                key={index}
                                                onClick={() => {
                                                    sliderRef?.current?.slickGoTo(index)
                                                }}
                                            >
                                                <ImagePreview
                                                    src={media.url}
                                                    isActivePreview={activeSlideIndex === index}
                                                ></ImagePreview>
                                            </ImagePreviewWrapper>
                                        )
                                    } else {
                                        return (
                                            <VideoPreviewWrapper key={index} onClick={() => sliderRef?.current?.slickGoTo(index)}>
                                                <VideoPreview
                                                    src={media.url}
                                                    isActivePreview={activeSlideIndex === index}
                                                ></VideoPreview>
                                                <PlayButtonWrapper>{GalleryPlay({})}</PlayButtonWrapper>
                                            </VideoPreviewWrapper>
                                        )
                                    }
                                })}
                            </ImagePreviewsContainer>
                        </ImagePreviewsContainerWrapper>
                    </CenteredDiv>
                </MediaWrapper>
            </MediaSliderContainer>
        </>
    )
}

const DescAndDownloadWrapper = styled.div<{ doesTitleExist?: boolean; doesDescExist?: boolean }>`
    display: flex;
    width: ${(props) => (props.doesTitleExist && props.doesDescExist ? "auto" : "100%")};
    justify-content: ${(props) => (props.doesTitleExist && props.doesDescExist ? "space-between" : "flex-end")};
`

const DownloadIconWrapper = styled.div`
    cursor: pointer;
    justify-self: flex-end !important;
`

const MediaInfoDescription = styled.p`
    margin-bottom: 0 !important;
    margin-right: 1rem;
    display: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 400px;

    @media ${device.laptop} {
        display: block;
    }

    @media ${device.laptopL} {
        max-width: 600px;
    }

    @media ${device.desktop} {
        max-width: 900px;
    }
`

const MediaInfo = styled.div<{ isVideo: boolean; isInfoEmpty: boolean }>`
    margin-bottom: 5px !important;
    z-index: 9999999;
    color: white;
    width: calc(100vw * 0.7);
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.28757440476190477) 78%);
    background: black;
`

const MediaInfoTitle = styled.p`
    color: white;
    margin-bottom: 0 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const PlayButtonWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const LeftArrowWrapper = styled.div<{ isEnabled: boolean }>`
    position: absolute;
    top: 50%;
    left: -5%;
    transform: translate(-50%, -50%);
    cursor: ${(props) => (props.isEnabled ? "pointer" : "auto")};
    opacity: ${(props) => (props.isEnabled ? "1" : ".5")};
`

const RightArrowWrapper = styled.div<{ isEnabled: boolean }>`
    position: absolute;
    top: 50%;
    left: 105%;
    transform: translate(-50%, -50%);
    cursor: ${(props) => (props.isEnabled ? "pointer" : "auto")};
    opacity: ${(props) => (props.isEnabled ? "1" : ".5")};
`

const GallerySlider = styled(Slider)`
    & .slick-track {
        display: flex;
        align-items: center;
    }

    & .slick-arrow,
    .slick-next {
        display: none;
    }

    .slick-slide div {
        outline: none;
    }
`

const SliderImage = styled.img`
    width: 100%;

    object-fit: contain;
    background-color: black;
    justify-self: center;
    position: relative;
    z-index: 9999999;

    @media ${device.laptop} {
        max-height: 65vh;
    }
`

export const SliderWrapper = styled.div`
    max-width: 100%;
    width: 100%;
    padding: 10px;
`

export const VideoPreviewWrapper = styled.div`
    cursor: pointer;
    margin: 0 5px 0 5px;
    position: relative;
`

export const VideoPreview = styled.video<{ isActivePreview: boolean }>`
    min-width: 100px;
    min-height: 70px;
    max-width: 100px;
    max-height: 70px;
    object-fit: cover;
    outline: ${(props) => (props.isActivePreview ? "4px solid #6DD400" : null)};
    opacity: ${(props) => (props.isActivePreview ? "1" : ".5")};
`

export const ImagePreviewsContainerWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`

export const ImagePreviewsContainer = styled.div`
    display: none;
    overflow: scroll;
    justify-self: center;
    padding: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media ${device.laptop} {
        display: flex;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`

export const ImagePreview = styled.img<{ isActivePreview: boolean }>`
    min-width: 100px;
    min-height: 70px;
    max-width: 100px;
    max-height: 70px;
    object-fit: cover;
    outline: ${(props) =>
        props.isActivePreview ? `4px solid ${branding.mediaGallerySlider.selectedImagePreviewOutlineColor ?? "#6DD400"}` : null};
    opacity: ${(props) => (props.isActivePreview ? "1" : ".5")};
`

export const ImagePreviewWrapper = styled.div<{ isActivePreview?: boolean }>`
    cursor: pointer;
    margin: 0 5px 0 5px;
`

export const ImageAndDescriptionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 9999999;
    border-top: 0.1px solid rgba(255, 255, 255, 0.5);
`

export const MediaWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    height: 100%;
`

/* #endregion */

function startDownloadProcess(attachment: Attachment, loggedInUser: User, organization?: SimpleOrganization) {
    if (attachment) {
        const fileName = attachment.title
        FileSaver.saveAs(attachment.url, fileName)
        if (organization?.id) trackVisit(loggedInUser.profileId, organization?.id, "MEDIA#DOWNLOAD", attachment.id)
    }
}
