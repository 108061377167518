import { createState, State, useState } from "@hookstate/core"
import { Suggestion } from "../backendServices/Types"
import { useSearchContext } from "../contentArea/useSearchContext"
import { Persistence } from "@hookstate/persistence"
import { globalSearchResultPageRoute } from "./RoutePaths"

const localStorageKey = "suggest-state"

interface SuggestContext {
    addExternalSuggestion: (suggestion: Suggestion) => void
    addSuggestion: (suggestion: Suggestion, showMoreEntities?: boolean, external?: boolean) => void
    clearSuggestions: () => void
    removeSuggestion: (suggestion: Suggestion) => void
    suggestions: Suggestion[]
    setVisible: (visible: boolean) => void
    isVisible: boolean
    setOverlayVisible: (visible: boolean) => void
    isOverlayVisible: boolean
}

interface StateValues {
    visible: boolean
    overlayVisible: boolean
    suggestions: Suggestion[]
}

const useWrapState = (suggestState: State<StateValues>) => {
    const search = useSearchContext()
    state.attach(Persistence(localStorageKey))

    const isGlobalSearchPage = () => {
        return window.location.href.indexOf(globalSearchResultPageRoute) !== -1
    }

    const addSuggestionHelper = (suggestion: Suggestion, showMoreEntities: boolean, external: boolean) => {
        // Don't want empty suggestions
        if (suggestion.value === "" && !suggestion.type) return

        suggestState.set((prev) => {
            if (external) {
                prev.suggestions = [suggestion]
            } else {
                for (const suggestionElem of prev.suggestions) {
                    // Don't want multiple suggestions with the same value or empty suggestions
                    if (suggestion.value === suggestionElem.value) return prev
                }
                prev.suggestions.push(suggestion)
            }
            return prev
        })

        search.searchFunctions.setSuggestions(suggestState.suggestions.value, showMoreEntities, external)
    }

    const addSuggestion = (suggestion: Suggestion, showMoreEntities?: boolean) => {
        addSuggestionHelper(suggestion, showMoreEntities ?? false, false)
    }

    const addExternalSuggestion = (suggestion: Suggestion) => {
        addSuggestionHelper(suggestion, false, true)
    }

    const clearSuggestions = () => {
        suggestState.set({ visible: false, suggestions: [], overlayVisible: false })
        search.searchFunctions.setSuggestions(suggestState.suggestions.value)
    }

    const removeSuggestion = (suggestion: Suggestion) => {
        suggestState.set((prev) => {
            for (let i = 0; i < prev.suggestions.length; i++) {
                const suggestionElem = prev.suggestions[i]
                if (suggestion.value === suggestionElem.value) {
                    prev.suggestions.splice(i, 1)
                    break
                }
            }
            return prev
        })
        search.searchFunctions.setSuggestions(suggestState.suggestions.value)
    }

    const setVisible = (visible: boolean) => {
        suggestState.set((prev) => {
            prev.visible = visible
            prev.suggestions = visible && !isGlobalSearchPage() ? [] : prev.suggestions
            return prev
        })
    }

    const setOverlayVisible = (visible: boolean) => {
        suggestState.set((prev) => {
            prev.overlayVisible = visible
            return prev
        })
    }

    return {
        addExternalSuggestion,
        addSuggestion,
        removeSuggestion,
        clearSuggestions,
        setVisible,
        setOverlayVisible,
        suggestions: suggestState.value.suggestions,
        isVisible: suggestState.value.visible || (suggestState.value.suggestions.length > 0 && isGlobalSearchPage()),
        isOverlayVisible: suggestState.value.overlayVisible
    }
}
const state = createState<StateValues>({ visible: false, suggestions: [], overlayVisible: false })
export const useSuggestContext = (): SuggestContext => useWrapState(useState(state))
