import { darkTheme } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import branding from "../../../branding/branding"
import { useDeviceController } from "../../context/DeviceController"

import { DeviceSelect, SettingsLabel } from "../Settings/Settings.styled"

function SpeakerSelection() {
    const { getCurrentAudioOutputDevice, getAudioOutputDevices, setCurrentAudioOutputDevice } = useDeviceController()
    return (
        <>
            <SettingsLabel>{branding.audioVideoSettings.audioOutputLabel}</SettingsLabel>
            <DeviceSelect
                options={getAudioOutputDevices()}
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                theme={darkTheme}
                value={getCurrentAudioOutputDevice()?.deviceId}
                placeholder={getCurrentAudioOutputDevice()?.label}
                onChange={async (device: DeviceType) => {
                    setCurrentAudioOutputDevice(device.deviceId, device.label)
                }}
            />
        </>
    )
}

export default SpeakerSelection
