import { Fragment, useState, useEffect } from "react"
import * as React from "react"
import { IconMeetings, IconLiveStream, IconBusinessArea, IconProgram, IconCollections, IconOrganizerNews } from "../../ui/Icons"
import { useLanguageState } from "../../globalStates/LanguageState"
import {
    conferencePageRoute,
    programPageRoute,
    businessAreaPageRoute,
    collectionOverviewRoute
} from "../../navigationArea/RoutePaths"
import { Collection, Exhibitor, News, Person } from "../../backendServices/Types"
import LiveEvent from "../../ui/LiveEvent"
import styled from "styled-components"
import { loadPersonsData } from "../../backendServices/PersonServices"
import branding, { NewsType } from "../../branding/branding"
import MeetingRoomGroup from "../lounges/MeetingRoomGroup"
import { isLivePhase, isSoftOpeningPhase } from "../../utils/EventPhaseChecker"
import { getIamPartOf } from "../../globalStates/IAmPartOf"
import queryString from "query-string"
import { NavLink } from "react-router-dom"
import TileRow from "../../ui/TileRow"
import DynamicBanner from "./DynamicBanner"
import { orderBy } from "lodash"
import { MeetingRoomGroupType } from "../../conference/AudioVideoBranding"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import { Tile } from "../collection/CollectionOverview"
import { BasisPremiumType } from "../../branding/BasisPremiumBranding"
import { LobbyComponent, LobbyExhibitorsBasisPremiumType } from "./ReceptionPageContentBranding"
import { EntityCarousel } from "../../ui/carousels/EntityCarousel"
import { loadExhibitorsData } from "../../backendServices/ExhibitorServices"
import { loadNewsListData } from "../../backendServices/NewsServices"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { ChannelResponse, getChannels } from "../../backendServices/MeetingServices"
import InView from "react-intersection-observer"
import { TopSection } from "../news/NewsPageSections"
import { DropdownConfig, dropdownConfigCleanup } from "../ExhibitorsPageContent"
import { loadSuggestions } from "../../backendServices/SuggestionServices"
import { CollectionListResponse, loadCollectionListData } from "../../backendServices/CollectionServices"
import { getMinutesBeforeSwitchingToTheNextEvent } from "../../utils/DateUtils"
import { trackSelectContent } from "../../utils/GTMTracking"

const IconContainer = styled.div`
    margin-top: 15px;
    margin-left: 85px;
    color: white;
    display: flex;
    justify-content: center;

    span {
        margin-left: 2px;
        margin-top: 0px;
    }
`

export const MobileShowfloorTile = styled(NavLink)<{ $background?: string }>`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 115px;
    width: 205px;
    color: white !important;
    ${(props) =>
        props.$background
            ? `background: url("${props.$background}");`
            : "background: url('/branding/applicationMedia/no-profile.png');"}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; /* left top */
    padding: 25px;
    padding-left: 8px;
    border-radius: 5px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};

    ${IconContainer} {
        margin-top: -5px;
        //margin-top: 4px;
        margin-left: 0;
        margin-right: 20px;
    }

    .rs-row {
        position: initial;

        div {
            margin-top: 5px;
            width: 50px;
            height: 50px;
            display: none;
            img {
                width: 40px;
                height: 40px;
                margin-top: 3px;
            }
        }

        div:first-child {
            display: initial;
        }
    }

    &:active,
    &:link {
        text-decoration: none;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.receptionPage.lobbypageShowfloorTilesDarkenOverlay ?? "rgba(0,0,0, .5)"};
        border-radius: 5px;
    }

    & > * {
        z-index: 2;
    }
`

export function getPackageNumber(packageName: BasisPremiumType): number {
    if (packageName === BasisPremiumType.ULTIMATE) {
        return 3
    } else if (packageName === BasisPremiumType.PREMIUM) {
        return 2
    } else if (packageName === BasisPremiumType.STANDARD) {
        return 1
    } else if (packageName === BasisPremiumType.BASIC) {
        return 0
    } else {
        return -1
    }
}

export function getNextPackage(packageName: BasisPremiumType): BasisPremiumType {
    if (packageName === BasisPremiumType.ULTIMATE) {
        return BasisPremiumType.PREMIUM
    } else if (packageName === BasisPremiumType.PREMIUM) {
        return BasisPremiumType.STANDARD
    } else if (packageName === BasisPremiumType.STANDARD) {
        return BasisPremiumType.BASIC
    } else {
        return BasisPremiumType.NONE
    }
}
interface LobbyPageProps {
    eventKey: string
    profileId: string
    isMobile: boolean
}

const LobbyPageContent: React.FunctionComponent<LobbyPageProps> = (props) => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()

    const [collections, setCollections] = useState<Collection[]>([])

    const [topSponsors, setTopSponsors] = useState<Exhibitor[]>()

    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])
    const [livestreams, setLiveStreams] = useState<ChannelResponse[]>([])
    const [topSpeakers, setTopSpeakers] = useState<Person[]>([])
    const [defaultSpeakers, setDefaultSpeakers] = useState<Person[]>([])

    const [topNews, setTopNews] = useState<News[]>([])
    const [news, setNews] = useState<News[]>([])
    const [organizerNews, setOrganizerNews] = useState<News[]>([])
    const [newsSourceTags, setNewsSourceTags] = useState<any>([])

    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const [nextPackage, setNextPackage] = useState<BasisPremiumType>(BasisPremiumType.NONE)

    const [meetingRoomGroups, setMeetingRoomGroups] = useState<MeetingRoomGroupType[]>([])
    const queryParams: any = queryString.parse(window.location.search)
    const componentOrder: LobbyComponent[] = branding.receptionPage.lobbyComponentOrder ?? [
        "SHOWFLOOR",
        "LOUNGES",
        "LIVE_SESSIONS",
        "SPEAKERS",
        "BANNER"
    ]

    const userAccessState = useUserRestrictedAreaAccess()

    const basisPremiumList = branding.receptionPage.lobbyExhibitorsBasisPremiumTypesList

    const loadPremiumOrStandardExhibitors = (basisPremium: BasisPremiumType, numResultRows?: number) => {
        const basisPremiumPackage: LobbyExhibitorsBasisPremiumType | undefined = basisPremiumList.find(
            (e: LobbyExhibitorsBasisPremiumType) => e.packageName === basisPremium
        )

        if (basisPremiumPackage as LobbyExhibitorsBasisPremiumType) {
            const params: any = {
                filterlist: "entity_orga",
                basispremium: getPackageNumber(basisPremium),
                startresultrow: 0,
                numresultrows: basisPremiumPackage?.numberOfExhibitors,
                order: "random",
                lang: lang
            }

            loadExhibitorsData(params, abortSignal, true).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                const exhibitorsTemp = exhibitors.filter((it) => !ex.some((e) => e.id === it.id))

                const respData = branding.receptionPage.lobbyExhibitorsShowTopSponsors
                    ? ex.filter((it) => !branding.receptionPage.lobbyTopSponsors.some((e) => e.id === it.id))
                    : ex

                setExhibitors(exhibitorsTemp.length === 0 ? respData : exhibitorsTemp.concat(respData))
                setNextPackage(getNextPackage(basisPremium))
            })
        } else {
            setNextPackage(getNextPackage(basisPremium))
            return
        }
    }

    useEffect(() => {
        window.sessionStorage.setItem("trackViewSearchResult", "")

        return function cleanUp() {
            window.sessionStorage.removeItem("trackViewSearchResult")
        }
    }, [])

    useEffect(() => {
        if (componentOrder.includes(LobbyComponent.SHOWFLOOR)) {
            const sponsoredExhibitorsMyPageList = branding.receptionPage.lobbyTopSponsors

            if (branding.receptionPage.lobbyExhibitorsShowTopSponsors && sponsoredExhibitorsMyPageList) {
                let sponsoredExhibitorsFilterList: string[] = []

                sponsoredExhibitorsMyPageList.forEach((sponsor) => {
                    sponsoredExhibitorsFilterList.push("id_orga_" + sponsor.id)
                })

                let sponsorSlotParams: any = {
                    filterlist: "entity_orga, " + sponsoredExhibitorsFilterList.join(", "),
                    startresultrow: 0,
                    order: "lexic",
                    lang: lang
                }

                loadExhibitorsData(sponsorSlotParams, abortSignal).then((resp) => {
                    const ex = resp.entities as Exhibitor[]
                    if (ex) {
                        ex.forEach((exhibitor: Exhibitor) => {
                            exhibitor.order = sponsoredExhibitorsMyPageList.find((x) => x.id === exhibitor.id)?.order || -1
                        })

                        setTopSponsors((e) => orderBy(ex, ["order"], ["asc"]))
                    }

                    //The following code should be executed when a user changes the app language and the randomized results for exhibitor packages are already loaded
                    //If we simply send the same requests again on language change, the results will be randomized again, the only difference would be the language
                    //It is a hack, but bear with me...
                    if (isLoaded && exhibitors.length > 0) {
                        let simplifiedExhibitorsList: { id: string; order: number }[] = []
                        let orderNumber = 0

                        //Get the existing results and preserve the current order of exhibitors by packages, because of the randomization
                        exhibitors.forEach((exhibitor) => {
                            simplifiedExhibitorsList.push({ id: exhibitor.id, order: orderNumber })
                            orderNumber += 1
                        })

                        //Send the list of IDs of the current exhibitors in the list as parameters in backend request
                        let exhibitorsParams: any = {
                            filterlist: "entity_orga, " + simplifiedExhibitorsList.map((ex) => "id_orga_" + ex.id).join(", "),
                            startresultrow: 0,
                            order: "lexic",
                            lang: lang
                        }

                        loadExhibitorsData(exhibitorsParams, abortSignal).then((resp) => {
                            const results = resp.entities as Exhibitor[]
                            if (results) {
                                //Connect exhibitors with the previously preserved order positions
                                results.forEach((exhibitor: Exhibitor) => {
                                    exhibitor.order = simplifiedExhibitorsList.find((x) => x.id === exhibitor.id)?.order || -1
                                })

                                //Order the reloaded exhibitors list by the assigned order positions
                                setExhibitors((e) => orderBy(results, ["order"], ["asc"]))
                            }
                        })
                    }
                })
            }

            setIsLoaded(true)
        }

        if (componentOrder.includes(LobbyComponent.LOUNGES)) {
            userAccessState.fetchUserAccessForAllVirtualCafes()
            setMeetingRoomGroups(branding.meetingRoomGroups)
        }

        //eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        setNextPackage(BasisPremiumType.ULTIMATE)
    }, [isLoaded])

    useEffect(() => {
        loadPremiumOrStandardExhibitors(nextPackage)

        //eslint-disable-next-line
    }, [nextPackage])

    useEffect(() => {
        const speakerPersonFunctions = branding.configuration.speakerPersonFunctions.map((func) => `personfunc_${func}`)
        const eventDateParticipation = branding.configuration.eventDateParticipation ? "evtdpartcp_" : ""

        if (componentOrder.includes(LobbyComponent.TOP_SPEAKERS)) {
            const topSpeakerParams: any = {
                filterlist: ["featured_,entity_pers", speakerPersonFunctions, eventDateParticipation],
                startresultrow: 0,
                numresultrows: 50,
                order: "totl"
            }

            loadPersonsData(topSpeakerParams, abortSignal).then((response) => {
                setTopSpeakers(response.persons)
            })
        }

        if (componentOrder.includes(LobbyComponent.SPEAKERS)) {
            const defaultSpeakerParams: any = {
                filterlist: ["entity_pers", speakerPersonFunctions, eventDateParticipation],
                startresultrow: 0,
                numresultrows: 15,
                order: branding.programSpeakers.orderType
            }

            loadPersonsData(defaultSpeakerParams, abortSignal).then((response) => {
                setDefaultSpeakers(response.persons)
            })
        }

        if (componentOrder.includes(LobbyComponent.COLLECTIONS)) {
            if (!branding.collectionBranding.useBrandingData) {
                loadCollectionListData({ pageNumber: 0, pageSize: 100 }).then((resp: CollectionListResponse | null) => {
                    if (resp) {
                        setCollections(resp.results)
                    }
                })
            }
        }

        if (componentOrder.includes(LobbyComponent.NEWS)) {
            if (branding.receptionPage.topNewsList.length > 0) {
                let topNewsFilterList: string[] = []

                branding.receptionPage.topNewsList.forEach((news) => {
                    topNewsFilterList.push("id_news_" + news.id)
                })

                let topNewsParams: any = {
                    filterlist: "entity_news,newskind_orga," + topNewsFilterList.join(", "),
                    startresultrow: 0,
                    order: "lexic",
                    lang: lang
                }

                loadNewsListData(topNewsParams, abortSignal).then((resp) => {
                    setTopNews(resp.newsList)

                    const list = resp.newsList as News[]
                    if (list) {
                        list.forEach((news: any) => {
                            news.order = branding.receptionPage.topNewsList.find((x) => x.id === news.id)?.order || -1
                        })

                        setTopNews((e) => orderBy(list, ["order"], ["asc"]))
                    }
                })
            }

            const defaultNewsParams: any = {
                filterlist: ["entity_news,newskind_orga"],
                startresultrow: 0,
                numresultrows: 10,
                order: "chrono",
                lang: lang,
                desc: true
            }

            loadNewsListData(defaultNewsParams, abortSignal).then((resp) => {
                const list = resp.newsList as News[]

                const respData = list.filter((it) => !branding.receptionPage.topNewsList.some((n) => n.id === it.id))

                setNews(respData)
            })
        }

        if (componentOrder.includes(LobbyComponent.ORGANIZER_NEWS)) {
            const newsTypes: string[] = []

            branding.receptionPage.newsTypes.forEach((type: NewsType) => {
                switch (type) {
                    case NewsType.EVENTORGANIZER: {
                        newsTypes.push("newstype_eventorga")
                        break
                    }
                    case NewsType.ORGANIZATION: {
                        newsTypes.push("newstype_orga")
                        break
                    }
                    case NewsType.STORY: {
                        newsTypes.push("newstype_story")
                        break
                    }
                }
            })

            const organizerNewsParams: any = {
                filterlist: ["featured_,entity_news," + newsTypes.toString()],
                startresultrow: 0,
                numresultrows: 27,
                order: "totl",
                lang: lang
            }

            loadNewsListData(organizerNewsParams, abortSignal).then((resp) => {
                const list = resp.newsList as News[]

                setOrganizerNews(list)
            })

            if (branding.newsPageContent.sourceTagConfiguration.visible) {
                let dropdownConfigClean = dropdownConfigCleanup(
                    branding.newsPageContent.sourceTagConfiguration.categorySearchConfig as DropdownConfig
                )

                loadSuggestions("", [dropdownConfigClean])
                    .then((res) => {
                        let sourceTagsTemp: any[] = []

                        if (res) {
                            res.suggestGroups[0].suggestions.forEach((suggestion) => {
                                sourceTagsTemp.push({
                                    alias: suggestion.alias,
                                    name: suggestion.title
                                })
                            })
                        }
                        setNewsSourceTags(sourceTagsTemp)
                    })
                    .catch((err) => {
                        console.log("Error loading data for source tags")
                    })
            }
        }

        if (componentOrder.includes(LobbyComponent.LIVE_SESSIONS)) {
            getChannels(getMinutesBeforeSwitchingToTheNextEvent() * 60 * 1000).then((resp) => {
                if ((resp as BackendServiceError).httpStatus) {
                    // TODO ERROR
                } else {
                    setLiveStreams(resp as ChannelResponse[])
                }
            })
        }
        return () => {
            abortController.abort() // Cancel all loads on unmount // TODO verify
        }
        // eslint-disable-next-line
    }, [props.eventKey, lang])

    useEffect(() => {
        userAccessState.fetchUserAccessForAllEventDates()
    }, []) //eslint-disable-line

    return (
        <Fragment>
            {componentOrder.map((component: LobbyComponent, key: number) => {
                switch (component) {
                    case LobbyComponent.SHOWFLOOR:
                        const allOrgas: Exhibitor[] = []
                        if (branding.receptionPage.lobbyExhibitorsShowTopSponsors && topSponsors) allOrgas.push(...topSponsors)
                        if (exhibitors) allOrgas.push(...exhibitors)

                        return (
                            <React.Fragment key={key}>
                                {allOrgas.length > 0 && (
                                    <EntityCarousel
                                        type="organization"
                                        entities={allOrgas}
                                        src="LOBBY"
                                        onBeforeLinkClick={() =>
                                            trackSelectContent(
                                                branding.sideIconBar.showfloorMenuText +
                                                    " - " +
                                                    branding.navigationArea.companiesItemTitle,
                                                "List",
                                                "Organization"
                                            )
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )

                    case LobbyComponent.LOUNGES:
                        return (
                            /* Virtual cafe */
                            <React.Fragment key={key}>
                                {meetingRoomGroups.length > 0 && (
                                    <TileRow
                                        icon={IconMeetings({
                                            fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                        })}
                                        iconVisible={true}
                                        title={branding.receptionPage.featuredCompanies}
                                        titleVisible={true}
                                        navLink={conferencePageRoute}
                                        navLinkTextVisible={true}
                                        hideShadowsAndScrollButtons={props.isMobile}
                                        navLinkText={branding.receptionPage.virtualCaffeNavLinkText}
                                        scrollComponent={branding.meetingRoomGroups && branding.meetingRoomGroups.length > 0}
                                        childWidth={props.isMobile ? 205 + 4 : 500 + 4} /* Basis + Grid Gap */
                                        childCount={meetingRoomGroups.length}
                                        onBeforeLinkClick={() =>
                                            trackSelectContent(branding.receptionPage.featuredCompanies, "List", "Café")
                                        }
                                    >
                                        {meetingRoomGroups.map((meetingRoomGroup, index) => (
                                            <InView key={index} threshold={0} initialInView>
                                                {({ inView, ref }) => (
                                                    <div ref={ref}>
                                                        {inView ? (
                                                            <div style={{ marginRight: "0px" }}>
                                                                <MeetingRoomGroup
                                                                    key={
                                                                        meetingRoomGroup.id +
                                                                        userAccessState.isUnlocked(meetingRoomGroup) +
                                                                        userAccessState.isLoaded(meetingRoomGroup)
                                                                    }
                                                                    margin="0 20px 20px 0"
                                                                    source="LOBBY"
                                                                    meetingRoomGroupConfig={meetingRoomGroup}
                                                                    roomType="virtualCafe"
                                                                    isMobile={props.isMobile}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: props.isMobile ? 205 : 500,
                                                                    height: props.isMobile ? 95 : 280
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                )}
                                            </InView>
                                        ))}
                                    </TileRow>
                                )}
                            </React.Fragment>
                        )

                    case LobbyComponent.LIVE_SESSIONS:
                        return (
                            (isLivePhase || (isSoftOpeningPhase && getIamPartOf(queryParams) === "streamonboarding")) && (
                                /* IsLiveNow section */
                                <React.Fragment key={key}>
                                    {livestreams?.length! > 0 && (
                                        <TileRow
                                            icon={IconLiveStream({
                                                fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor,
                                                width: "22",
                                                height: "22"
                                            })}
                                            iconVisible={true}
                                            title={branding.receptionPage.liveSessions}
                                            titleVisible={true}
                                            navLink={programPageRoute}
                                            navLinkTextVisible={true}
                                            hideShadowsAndScrollButtons={props.isMobile}
                                            navLinkText={branding.receptionPage.liveSessionsNavLinkText}
                                            scrollComponent={livestreams && livestreams.length > 0}
                                            childWidth={props.isMobile ? 135 : 480}
                                            childCount={livestreams?.length ?? 0}
                                        >
                                            {livestreams &&
                                                livestreams.map((livestream, index) => (
                                                    <InView key={index} threshold={0} initialInView>
                                                        {({ inView, ref }) => (
                                                            <div ref={ref}>
                                                                {inView ? (
                                                                    <LiveEvent
                                                                        id={index.toString()}
                                                                        title={livestream.name ?? ""}
                                                                        thumbnailUrl={livestream.thumbnailUrl}
                                                                        isMobile={props.isMobile}
                                                                        channelId={livestream.channelId}
                                                                        channelIcon={IconLiveStream({ fill: "#fff" })}
                                                                        eventDate={livestream.currentEventDate}
                                                                        rerenderOnUnlockedChanged={userAccessState.isUnlocked(
                                                                            livestream.currentEventDate
                                                                        )}
                                                                        src="LOBBY"
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            width: props.isMobile ? 135 : 480,
                                                                            height: props.isMobile ? 120 : 280
                                                                        }}
                                                                    ></div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </InView>
                                                ))}
                                        </TileRow>
                                    )}
                                </React.Fragment>
                            )
                        )

                    case LobbyComponent.TOP_SPEAKERS:
                        /* Speakers */
                        return (
                            <React.Fragment key={key}>
                                {topSpeakers.length > 0 && (
                                    <EntityCarousel
                                        type="person"
                                        entities={topSpeakers}
                                        title={branding.receptionPage.topSpeakersTitle}
                                        linkText={branding.receptionPage.visitTopSpeakersText}
                                        link={branding.receptionPage.visitTopSpeakersUrl}
                                        onBeforeLinkClick={() =>
                                            trackSelectContent(
                                                branding.programPageContent.programPageTitle +
                                                    " - " +
                                                    branding.programPageContent.speakersTabTitle,
                                                "List",
                                                "Speaker"
                                            )
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )

                    case LobbyComponent.SPEAKERS:
                        /* Speakers */
                        return (
                            <React.Fragment key={key}>
                                {defaultSpeakers.length > 0 && (
                                    <EntityCarousel
                                        type="person"
                                        entities={defaultSpeakers}
                                        onBeforeLinkClick={() =>
                                            trackSelectContent(
                                                branding.programPageContent.programPageTitle +
                                                    " - " +
                                                    branding.programPageContent.speakersTabTitle,
                                                "List",
                                                "Speaker"
                                            )
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )

                    case LobbyComponent.NEWS:
                        const allNews: News[] = []
                        if (branding.receptionPage.topNewsList.length > 0 && topNews) allNews.push(...topNews)
                        if (news) allNews.push(...news)
                        return (
                            <React.Fragment key={key}>
                                {allNews.length > 0 && (
                                    <EntityCarousel
                                        type="news"
                                        entities={allNews}
                                        lobby
                                        onBeforeLinkClick={() =>
                                            trackSelectContent(
                                                branding.sideIconBar.showfloorMenuText +
                                                    " - " +
                                                    branding.navigationArea.newsItemTitle,
                                                "List",
                                                "News"
                                            )
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )

                    case LobbyComponent.ORGANIZER_NEWS:
                        return (
                            <React.Fragment key={key}>
                                {organizerNews.length > 0 && (
                                    <TileRow
                                        key={key}
                                        icon={IconOrganizerNews({
                                            fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                        })}
                                        iconVisible={branding.receptionPage.tileRowIconVisible}
                                        title={branding.receptionPage.organizerNewsTitle}
                                        titleVisible={branding.receptionPage.tileRowTitleVisible}
                                        navLink={branding.receptionPage.visitOrganizerNewsUrl}
                                        navLinkTextVisible={branding.receptionPage.tileRowNavLinkVisible}
                                        hideShadowsAndScrollButtons={props.isMobile}
                                        navLinkText={branding.receptionPage.visitOrganizerNewsText}
                                        childCount={1}
                                        childWidth={0}
                                        scrollComponent={false}
                                        allowOverflow
                                        onBeforeLinkClick={() =>
                                            trackSelectContent(branding.navigationArea.newsItemTitle, "List", "News")
                                        }
                                    >
                                        <TopSection newsList={organizerNews} className="lobby" sourceTags={newsSourceTags} />
                                    </TileRow>
                                )}
                            </React.Fragment>
                        )

                    case LobbyComponent.LEADFINDER_BANNER:
                        return (
                            <TileRow
                                key={key}
                                icon={IconBusinessArea({
                                    fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                })}
                                iconVisible={branding.receptionPage.tileRowLeadFinderIconVisible}
                                title={branding.receptionPage.leadFinderBannerTitle}
                                titleVisible={branding.receptionPage.tileRowLeadFinderTitleVisible}
                                navLink={businessAreaPageRoute}
                                navLinkTextVisible={branding.receptionPage.tileRowLeadFinderNavLinkVisible}
                                hideShadowsAndScrollButtons={props.isMobile}
                                navLinkText={branding.receptionPage.leadfinderNavLinkText}
                                childCount={1}
                                childWidth={0}
                                scrollComponent={false}
                                onBeforeLinkClick={() =>
                                    trackSelectContent(branding.receptionPage.leadFinderBannerTitle, "List", "Business", "Banner")
                                }
                            >
                                <DynamicBanner
                                    isLeadFinderBanner={true}
                                    navLink={businessAreaPageRoute}
                                    buttonText={branding.receptionPage.leadFinderButton}
                                    text1={branding.receptionPage.leadFinderText1}
                                    text2={branding.receptionPage.leadFinderText2}
                                />
                            </TileRow>
                        )

                    case LobbyComponent.PROGRAM_BANNER:
                        return (
                            <TileRow
                                key={key}
                                icon={IconProgram({
                                    fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor,
                                    stroke: branding.sideIconBar.sideIconColorLight
                                })}
                                iconVisible={branding.receptionPage.tileRowProgramIconVisible}
                                title={branding.receptionPage.programBannerTitle}
                                titleVisible={branding.receptionPage.tileRowProgramTitleVisible}
                                navLink={programPageRoute}
                                navLinkTextVisible={branding.receptionPage.tileRowProgramNavLinkVisible}
                                hideShadowsAndScrollButtons={props.isMobile}
                                navLinkText={branding.receptionPage.programNavLinkText}
                                childCount={1}
                                childWidth={0}
                                scrollComponent={false}
                                onBeforeLinkClick={() =>
                                    trackSelectContent(
                                        branding.programPageContent.programPageTitle +
                                            " - " +
                                            branding.programPageContent.scheduleTabTitle,
                                        "List",
                                        "Program",
                                        "Banner"
                                    )
                                }
                            >
                                <DynamicBanner
                                    isProgramBanner={true}
                                    navLink={programPageRoute}
                                    buttonText={branding.receptionPage.programButton}
                                    text1={branding.receptionPage.programText1}
                                    text2={branding.receptionPage.programText2}
                                />
                            </TileRow>
                        )

                    case LobbyComponent.COLLECTIONS:
                        const collectionListToUse = branding.collectionBranding.useBrandingData
                            ? branding.collectionBranding.collections
                            : collections
                        return (
                            <React.Fragment key={key}>
                                {collectionListToUse.length > 0 && (
                                    <TileRow
                                        icon={IconCollections({
                                            fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                        })}
                                        iconVisible={true}
                                        title={branding.receptionPage.collectionsBannerTitle}
                                        titleVisible={true}
                                        navLink={collectionOverviewRoute}
                                        navLinkTextVisible={branding.receptionPage.tileRowCollectionsNavLinkVisible}
                                        hideShadowsAndScrollButtons={props.isMobile}
                                        navLinkText={branding.receptionPage.collectionsNavLinkText}
                                        scrollComponent={collectionListToUse.length > 0}
                                        childWidth={props.isMobile ? 205 + 4 : 500 + 4}
                                        childCount={collectionListToUse.length}
                                        onBeforeLinkClick={() =>
                                            trackSelectContent(
                                                branding.sideIconBar.collectionsMenuText,
                                                "Overallview",
                                                "Collections"
                                            )
                                        }
                                    >
                                        {collectionListToUse.map((collection, index) => (
                                            <Tile key={index} isLobbyComponent={true} collection={collection} />
                                        ))}
                                    </TileRow>
                                )}
                            </React.Fragment>
                        )

                    default:
                        return null
                }
            })}
        </Fragment>
    )
}

export default LobbyPageContent
