import { detect } from "detect-browser"
import { SyntheticEvent, useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import {
    IconBottomLeft,
    IconBottomRight,
    IconCameraOff,
    IconTopLeft,
    IconTopRight,
    IconTrash,
    IconUpload
} from "../../../ui/Icons"
import {
    DevicePermissionMessage,
    DevicePermissionMessageContainer,
    DeviceSettingsRoot,
    SettingsGroup
} from "../AudioSettings/AudioSettings.styled"
import {
    CustomBackgroundActionsOverlay,
    IconContainer,
    NoContentShift,
    TrashIconContainer
} from "../BackgroundSettings/BackgroundSettings.styled"
import { SettingsSectionTitle } from "../Settings/Settings.styled"
import { PreviewVideoContainer, PreviewVideoOverlay } from "../VideoSettings/VideoSettings.styled"
import { LogoGalleryContainer, LogoGalleryItem, LogoPositionButton, LogoPositionButtonsContainer } from "./LogoSettings.styled"
import PreviewVideo from "../PreviewVideo/PreviewVideo"

import { LogoPositions } from "../../enums/LogoPosition"
import branding from "../../../branding/branding"
import { useVideoContext } from "../../context/VideoContext"
import { usePreMeetingSettingContext } from "../../context/PreMeetingSettingsContext"

function LogoSettings() {
    const {
        setSelectedLogo,
        uploadLogo,
        getLogoGalleryItems,
        getSelectedLogo,
        removeLogo,
        setLogoPosition,
        getLogoPosition,
        getIsLogoProcessorActive,
        getCustomUploadedLogo,
        deleteCustomUploadedLogo,
        updateVideoInput
    } = useVideoContext()
    const browserInfo = detect()
    const [isLoading] = useState<boolean>(false)
    const logoInputRef = useRef<HTMLInputElement | null>(null)
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const [hasCameraPermission, setHasCameraPersmission] = useState(true)

    useEffect(() => {
        preMeetingSettingsContext.applyStoredVideoSettings()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(function (stream) {
                setHasCameraPersmission(true)
            })
            .catch(function (err) {
                console.log(err, " Camera usage is permitted")
                setHasCameraPersmission(false)
            })
        // eslint-disable-next-line
    }, [navigator.mediaDevices])

    return (
        <>
            {hasCameraPermission ? (
                <DeviceSettingsRoot>
                    <SettingsGroup>
                        <PreviewVideoContainer>
                            <NoContentShift browser={browserInfo?.name}>
                                {!isLoading && <PreviewVideo />}
                                <PreviewVideoOverlay>
                                    <span>{branding.audioVideoSettings.preview}</span>
                                </PreviewVideoOverlay>
                            </NoContentShift>
                        </PreviewVideoContainer>
                        <LogoPositionButtonsContainer>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.TOP_RIGHT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.TOP_RIGHT)}
                            >
                                {IconTopRight({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionTopRight}
                            </LogoPositionButton>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.TOP_LEFT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.TOP_LEFT)}
                            >
                                {IconTopLeft({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionTopLeft}
                            </LogoPositionButton>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.BOTTOM_RIGHT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.BOTTOM_RIGHT)}
                            >
                                {IconBottomLeft({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionBottomRight}
                            </LogoPositionButton>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.BOTTOM_LEFT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.BOTTOM_LEFT)}
                            >
                                {IconBottomRight({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionBottomLeft}
                            </LogoPositionButton>
                        </LogoPositionButtonsContainer>
                    </SettingsGroup>
                    <SettingsGroup>
                        <Container className="p-0">
                            <Row>
                                <Col>
                                    <SettingsSectionTitle>
                                        {branding.audioVideoSettings.logoGalleryTitleLabel}
                                    </SettingsSectionTitle>
                                </Col>
                                <Col>
                                    <IconContainer
                                        onClick={() => {
                                            if (logoInputRef.current) {
                                                logoInputRef.current.value = ""
                                            }
                                            logoInputRef.current?.click()
                                        }}
                                    >
                                        {IconUpload({ fill: "#fff", width: "15px", height: "15px" })}
                                    </IconContainer>
                                    <input
                                        ref={logoInputRef}
                                        onChange={(e: SyntheticEvent) => {
                                            uploadLogo(e)
                                        }}
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        style={{ display: "none" }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <LogoGalleryContainer>
                                        <LogoGalleryItem
                                            isActive={!getSelectedLogo() || getSelectedLogo() === null}
                                            onClick={() => removeLogo()}
                                            logo={""}
                                        >
                                            {branding.audioVideoSettings.radioNoLogo}
                                        </LogoGalleryItem>

                                        {getLogoGalleryItems()?.length &&
                                            getLogoGalleryItems().map((url: any) => {
                                                return (
                                                    <LogoGalleryItem isActive={getSelectedLogo() === url} logo={url}>
                                                        <CustomBackgroundActionsOverlay
                                                            onClick={() => {
                                                                setSelectedLogo(url).then(() => {
                                                                    updateVideoInput()
                                                                })
                                                            }}
                                                        ></CustomBackgroundActionsOverlay>

                                                        {url === getCustomUploadedLogo() && (
                                                            <>
                                                                <div className="actions">
                                                                    <TrashIconContainer
                                                                        onClick={() => deleteCustomUploadedLogo()}
                                                                    >
                                                                        {IconTrash({
                                                                            width: "12px",
                                                                            height: "12x",
                                                                            fill: "#fff"
                                                                        })}
                                                                    </TrashIconContainer>
                                                                </div>
                                                            </>
                                                        )}
                                                    </LogoGalleryItem>
                                                )
                                            })}
                                    </LogoGalleryContainer>
                                </Col>
                            </Row>
                        </Container>
                    </SettingsGroup>
                </DeviceSettingsRoot>
            ) : (
                <DeviceSettingsRoot>
                    <DevicePermissionMessageContainer>
                        <DevicePermissionMessage>
                            <IconCameraOff fill="#fff" width="25px" height="25px" />
                        </DevicePermissionMessage>
                        <DevicePermissionMessage>
                            {branding.communicationAreaAudioVideoSettingsVideoPermissionMessage}
                        </DevicePermissionMessage>
                    </DevicePermissionMessageContainer>
                </DeviceSettingsRoot>
            )}
        </>
    )
}

export default LogoSettings
