/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const emptyQuery = /* GraphQL */ `
  query EmptyQuery {
    _emptyQuery
  }
`;
export const getCalendarEntry = /* GraphQL */ `
  query GetCalendarEntry($id: ID!) {
    getCalendarEntry(id: $id) {
      id
      title
      description
      start
      end
      userId
      participants {
        items {
          id
          calendarEntryId
          userId
          status
          start
          userIdStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      organizationId
      status
      organizationIdStatus
      topicName
      userPoolName
      locationName
      locationActionType
      locationActionParam
      isVirtual
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const calendarEntryByOrganizationAndStatusSorted = /* GraphQL */ `
  query CalendarEntryByOrganizationAndStatusSorted(
    $filter: ModelCalendarEntryFilterInput
    $limit: Int
    $nextToken: String
    $organizationIdStatus: String
    $sortDirection: ModelSortDirection
    $start: ModelStringKeyConditionInput
  ) {
    calendarEntryByOrganizationAndStatusSorted(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      organizationIdStatus: $organizationIdStatus
      sortDirection: $sortDirection
      start: $start
    ) {
      items {
        id
        title
        description
        start
        end
        userId
        participants {
          nextToken
        }
        organizationId
        status
        organizationIdStatus
        topicName
        userPoolName
        locationName
        locationActionType
        locationActionParam
        isVirtual
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const calendarEntryParticipationByUserAndStatusSorted = /* GraphQL */ `
  query CalendarEntryParticipationByUserAndStatusSorted(
    $filter: ModelCalendarEntryParticipationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $start: ModelStringKeyConditionInput
    $userIdStatus: String!
  ) {
    calendarEntryParticipationByUserAndStatusSorted(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      start: $start
      userIdStatus: $userIdStatus
    ) {
      items {
        id
        calendarEntryId
        calendarEntry {
          id
          title
          description
          start
          end
          userId
          organizationId
          status
          organizationIdStatus
          topicName
          userPoolName
          locationName
          locationActionType
          locationActionParam
          isVirtual
          createdAt
          updatedAt
        }
        userId
        status
        start
        userIdStatus
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userBusyTimes = /* GraphQL */ `
  query UserBusyTimes(
    $userId: ID!
    $start: AWSDateTime!
    $end: AWSDateTime!
    $limit: Int
    $nextToken: String
  ) {
    userBusyTimes(
      userId: $userId
      start: $start
      end: $end
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        start
        end
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      type
      name
      description
      userId
      mostRecentMessage
      mostRecentMessageCreatedAt
      memberIdsHash
      updatedAt
      createdAt
      messages {
        items {
          id
          authorId
          content
          conversationId
          isSent
          createdAt
          sotName
          members
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      members {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getConversationsByMembers = /* GraphQL */ `
  query GetConversationsByMembers(
    $memberId1: ID!
    $memberId2: ID!
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getConversationsByMembers(
      memberId1: $memberId1
      memberId2: $memberId2
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      authorId
      content
      conversationId
      isSent
      createdAt
      sotName
      members
      _version
      _deleted
      _lastChangedAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      author {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        authorId
        content
        conversationId
        isSent
        createdAt
        sotName
        members
        _version
        _deleted
        _lastChangedAt
        updatedAt
        conversation {
          id
          type
          name
          description
          userId
          mostRecentMessage
          mostRecentMessageCreatedAt
          memberIdsHash
          updatedAt
          createdAt
        }
        author {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $conversationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      lastSync: $lastSync
    ) {
      items {
        id
        authorId
        content
        conversationId
        isSent
        createdAt
        sotName
        members
        _version
        _deleted
        _lastChangedAt
        updatedAt
        conversation {
          id
          type
          name
          description
          userId
          mostRecentMessage
          mostRecentMessageCreatedAt
          memberIdsHash
          updatedAt
          createdAt
        }
        author {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const batchGetUserPresence = /* GraphQL */ `
  query BatchGetUserPresence($listIds: [String]) {
    batchGetUserPresence(listIds: $listIds) {
      id
      presenceStatus
      lastConnected
      name
      pictureUrl
      outgoingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          invitationType
          meetingTitle
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          invitationType
          meetingTitle
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      conversations {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getUserConversation = /* GraphQL */ `
  query GetUserConversation($id: ID!) {
    getUserConversation(id: $id) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const userConversationsByUser = /* GraphQL */ `
  query UserConversationsByUser(
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $mostRecentMessageCreatedAt: ModelStringKeyConditionInput
    $lastReadMessageCreatedAt: ModelStringKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userId: ID!
  ) {
    userConversationsByUser(
      filter: $filter
      limit: $limit
      mostRecentMessageCreatedAt: $mostRecentMessageCreatedAt
      lastReadMessageCreatedAt: $lastReadMessageCreatedAt
      nextToken: $nextToken
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        id
        userId
        conversationId
        isMuted
        isChatOpen
        mostRecentMessageCreatedAt
        lastReadMessageCreatedAt
        createdAt
        updatedAt
        conversation {
          id
          type
          name
          description
          userId
          mostRecentMessage
          mostRecentMessageCreatedAt
          memberIdsHash
          updatedAt
          createdAt
        }
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userConversationsByUserAndConversation = /* GraphQL */ `
  query UserConversationsByUserAndConversation(
    $conversationId: ModelIDKeyConditionInput
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userId: ID!
  ) {
    userConversationsByUserAndConversation(
      conversationId: $conversationId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        id
        userId
        conversationId
        isMuted
        isChatOpen
        mostRecentMessageCreatedAt
        lastReadMessageCreatedAt
        createdAt
        updatedAt
        conversation {
          id
          type
          name
          description
          userId
          mostRecentMessage
          mostRecentMessageCreatedAt
          memberIdsHash
          updatedAt
          createdAt
        }
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCurrentLocationCounts = /* GraphQL */ `
  query GetCurrentLocationCounts($id: ID!) {
    getCurrentLocationCounts(id: $id) {
      id
      cnt
      createdAt
      updatedAt
    }
  }
`;
export const listCurrentLocationCountss = /* GraphQL */ `
  query ListCurrentLocationCountss(
    $filter: ModelCurrentLocationCountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrentLocationCountss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cnt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      participants {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          invitationType
          meetingTitle
          createdAt
          updatedAt
        }
        nextToken
      }
      start
      end
      createdAt
      updatedAt
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        participants {
          nextToken
        }
        start
        end
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRoomAttendance = /* GraphQL */ `
  query GetMeetingRoomAttendance($id: ID!) {
    getMeetingRoomAttendance(id: $id) {
      id
      chimeMeetingId
      createdAt
      updatedAt
    }
  }
`;
export const listMeetingRoomAttendances = /* GraphQL */ `
  query ListMeetingRoomAttendances(
    $filter: ModelMeetingRoomAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomAttendances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chimeMeetingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRaisedHand = /* GraphQL */ `
  query GetRaisedHand($id: ID!) {
    getRaisedHand(id: $id) {
      id
      chimeMeetingId
      createdAt
      updatedAt
    }
  }
`;
export const listRaisedHands = /* GraphQL */ `
  query ListRaisedHands(
    $filter: ModelRaisedHandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRaisedHands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chimeMeetingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAction = /* GraphQL */ `
  query GetUserAction($id: ID!) {
    getUserAction(id: $id) {
      id
      userId
      sotName
      actionType
      param
      startTime
      endTime
      duration
      source
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const listUserActions = /* GraphQL */ `
  query ListUserActions(
    $filter: ModelUserActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        sotName
        actionType
        param
        startTime
        endTime
        duration
        source
        topic
        userType
        countryCode
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserSession = /* GraphQL */ `
  query GetUserSession($id: ID!) {
    getUserSession(id: $id) {
      id
      userId
      sotName
      location
      locationLevel1
      locationLevel2
      locationLevel3
      ttl
      time
      source
      queryHelper
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const listUserSessions = /* GraphQL */ `
  query ListUserSessions(
    $filter: ModelUserSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        sotName
        location
        locationLevel1
        locationLevel2
        locationLevel3
        ttl
        time
        source
        queryHelper
        topic
        userType
        countryCode
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userActionsByRoom = /* GraphQL */ `
  query UserActionsByRoom(
    $param: String
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userActionsByRoom(
      param: $param
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sotName
        actionType
        param
        startTime
        endTime
        duration
        source
        topic
        userType
        countryCode
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const byActionType = /* GraphQL */ `
  query ByActionType(
    $actionType: UserActionType
    $duration: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byActionType(
      actionType: $actionType
      duration: $duration
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sotName
        actionType
        param
        startTime
        endTime
        duration
        source
        topic
        userType
        countryCode
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userSessionsByLocation = /* GraphQL */ `
  query UserSessionsByLocation(
    $sotName: String
    $locationLevel2: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSessionsByLocation(
      sotName: $sotName
      locationLevel2: $locationLevel2
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sotName
        location
        locationLevel1
        locationLevel2
        locationLevel3
        ttl
        time
        source
        queryHelper
        topic
        userType
        countryCode
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      type
      displayGroup
      content
      userIdDisplayGroup
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        displayGroup
        content
        userIdDisplayGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByUserId = /* GraphQL */ `
  query NotificationsByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        displayGroup
        content
        userIdDisplayGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByUserIdAndDisplayGroupSorted = /* GraphQL */ `
  query NotificationsByUserIdAndDisplayGroupSorted(
    $userIdDisplayGroup: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserIdAndDisplayGroupSorted(
      userIdDisplayGroup: $userIdDisplayGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        displayGroup
        content
        userIdDisplayGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnreadCounter = /* GraphQL */ `
  query GetUnreadCounter($id: ID!) {
    getUnreadCounter(id: $id) {
      id
      requests
      contacts
      conversations
      schedules
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      presenceStatus
      lastConnected
      name
      pictureUrl
      outgoingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          invitationType
          meetingTitle
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          invitationType
          meetingTitle
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      conversations {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
