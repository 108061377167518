import React, { Dispatch, SetStateAction, useState } from "react"
import branding from "../../../branding/branding"
import { IconValidInput } from "../../Icons"
import { CalendarWrapper, InputIcons, ValidationDiv } from "./CalendarEntryModal.styled"
import { CalendarEntryModalViewMode, loadTime, renderIcon } from "./ModalFunctions"
import moment from "moment"
import { isBeforeToday, isToday, momentWithoutTimezoneFromTimezonedMoment } from "../../../utils/DateUtils"
import { useAppState } from "../../../globalStates/AppState"
import { MeetingSlotsDay } from "../../MeetingSlots"
import { useLanguageState } from "../../../globalStates/LanguageState"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"

type DateCalendarPickerProps = {
    className: string | undefined
    placeholder: string
    showIconInInput?: boolean
    icon?: string
    predefinedStartTime?: Date
    meetingSlotsTable?: MeetingSlotsDay[]
    time?: string
    viewMode?: CalendarEntryModalViewMode
    date?: Date | null
    setDate?: Dispatch<SetStateAction<Date | null>>
    startTime?: Date | null
    setStartTime?: Dispatch<SetStateAction<Date | null>>
    endTime?: Date | null
    setEndTime?: Dispatch<SetStateAction<Date | null>>
    setShowErrorModal?: Dispatch<SetStateAction<boolean>>
    calendarEntry?: CalendarEntry
    setErrorModalMessage?: Dispatch<SetStateAction<string>>
}

const DateCalendarPicker = (props: DateCalendarPickerProps) => {
    const [activeCalendarIcon, setActiveCalendarIcon] = useState(false)
    const dayFormat = branding.eventTiming.eventDaysFormatPatternShort
    const appState = useAppState()
    const nowDate = new Date()
    const minStartTime = momentWithoutTimezoneFromTimezonedMoment(moment(), appState.timezone)
    const minEndTime =
        props.startTime != null
            ? momentWithoutTimezoneFromTimezonedMoment(moment(props.startTime).add(10, "minutes"), appState.timezone)
            : isToday(moment(props.date))
            ? momentWithoutTimezoneFromTimezonedMoment(moment(minStartTime).add(10, "minutes"), appState.timezone)
            : undefined
    const maxTime = moment({ hours: 23, minutes: 45 })
    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    //move in branding
    const hoursFormat = "HH:mm"
    const [showErrorBorderInput, setShowErrorBorderInput] = useState<boolean>(false)

    const readOnly = props.time === "end" && props.startTime?.getHours()! === 23 && props.startTime?.getMinutes()! === 45

    let newStartDate // eslint-disable-line
    let newEndDate // eslint-disable-line

    function onDaySelected(date: Date) {
        if (isBeforeToday(moment(date))) {
            props.setShowErrorModal!(true)
            props.setErrorModalMessage!(branding.calendarEntryModalPageContent.pastDateErrorMessage)
            return
        }

        // Case if we schedule a time in the future and come back with start and end time smaller than current to set a date on the next available time
        if (props.startTime && date.getDate() === nowDate.getDate()) {
            if (props.startTime.getHours() <= nowDate.getHours()) {
                if (nowDate.getMinutes() > 0 && nowDate.getMinutes() <= 15) {
                    newStartDate =
                        props.startTime.setMinutes(15) &&
                        props.startTime.setHours(nowDate.getHours()) &&
                        props.startTime.setDate(nowDate.getDate())
                    newEndDate =
                        props.endTime?.setMinutes(30) &&
                        props.endTime.setHours(nowDate.getHours()) &&
                        props.endTime.setDate(nowDate.getDate())
                } else if (nowDate.getMinutes() > 15 && nowDate.getMinutes() <= 30) {
                    newStartDate =
                        props.startTime.setMinutes(30) &&
                        props.startTime.setHours(nowDate.getHours()) &&
                        props.startTime.setDate(nowDate.getDate())
                    newEndDate =
                        props.endTime?.setMinutes(45) &&
                        props.endTime.setHours(nowDate.getHours()) &&
                        props.endTime.setDate(nowDate.getDate())
                } else if (nowDate.getMinutes() > 30 && nowDate.getMinutes() <= 45) {
                    newStartDate =
                        props.startTime.setMinutes(45) &&
                        props.startTime.setHours(nowDate.getHours()) &&
                        props.startTime.setDate(nowDate.getDate())
                    newEndDate =
                        props.endTime?.setMinutes(0) &&
                        props.endTime.setHours(nowDate.getHours() + 1) &&
                        props.endTime.setDate(nowDate.getDate())
                } else if (nowDate.getMinutes() > 45 && nowDate.getMinutes() <= 59) {
                    newStartDate =
                        props.startTime.setMinutes(0) &&
                        props.startTime.setHours(nowDate.getHours() + 1) &&
                        props.startTime.setDate(nowDate.getDate())
                    newEndDate =
                        props.endTime?.setMinutes(15) &&
                        props.endTime.setHours(nowDate.getHours() + 1) &&
                        props.endTime.setDate(nowDate.getDate())
                }
            }
        }

        props.setDate!(date)
    }

    function onStartSelected(time: Date) {
        if (time) {
            const startTemp = moment(props.date ?? undefined)
                .set("hours", time.getHours())
                .set("minutes", time.getMinutes())
            setShowErrorBorderInput(false)

            //  Can not write time that has already passed
            if (startTemp.get("dates") === nowDate.getDate() && startTemp.get("hours") < nowDate.getHours()) {
                setShowErrorBorderInput(true)
                return
            } else if (
                startTemp.get("dates") === nowDate.getDate() &&
                startTemp.get("hours") === nowDate.getHours() &&
                startTemp.get("minutes") <= nowDate.getMinutes()
            ) {
                setShowErrorBorderInput(true)
                return
            }

            // If start time is divisible with 15
            if (startTemp.get("minutes") % 15 === 0) {
                props.setStartTime!(startTemp.toDate())
                props.setEndTime!(startTemp.add(15, "m").toDate())
            } else {
                if (startTemp.minutes() > 0 && startTemp.minutes() < 15) {
                    props.setStartTime?.(startTemp.set("minutes", 15).toDate())
                    props.setEndTime?.(startTemp.set("minutes", 30).toDate())
                } else if (startTemp.minutes() > 15 && startTemp.minutes() < 30) {
                    props.setStartTime?.(startTemp.set("minutes", 30).toDate())
                    props.setEndTime?.(startTemp.set("minutes", 45).toDate())
                } else if (startTemp.minutes() > 30 && startTemp.minutes() < 45) {
                    props.setStartTime?.(startTemp.set("minutes", 45).toDate())
                    props.setEndTime?.(startTemp.set({ hours: startTemp.hours() + 1, minutes: 0 }).toDate())
                } else if (startTemp.minutes() > 45 && startTemp.minutes() <= 59) {
                    props.setStartTime?.(startTemp.set({ hours: startTemp.hours() + 1, minutes: 0 }).toDate())
                    props.setEndTime?.(startTemp.set({ hours: startTemp.hours(), minutes: 15 }).toDate())
                }
            }
        } else {
            props.setStartTime!(null)
            setShowErrorBorderInput(false)
        }
    }

    function onEndSelected(time: Date) {
        if (time) {
            const endTemp = moment(props.date ?? undefined)
                .set("hours", time.getHours())
                .set("minutes", time.getMinutes())
                .toDate()
            const newDate = endTemp

            if (props.startTime && endTemp.getTime() <= props.startTime?.getTime()!) {
                setShowErrorBorderInput(true)
                return
            }

            if (endTemp.getMinutes() % 15 === 0) {
                props.setEndTime!(endTemp)
            } else {
                if (endTemp.getMinutes() > 0 && endTemp.getMinutes() < 15) {
                    newDate.setMinutes(15)
                    props.setEndTime?.(newDate)
                    setShowErrorBorderInput(false)
                } else if (endTemp.getMinutes() > 15 && endTemp.getMinutes() < 30) {
                    newDate.setMinutes(30)
                    props.setEndTime?.(newDate)
                    setShowErrorBorderInput(false)
                } else if (endTemp.getMinutes() > 30 && endTemp.getMinutes() < 45) {
                    newDate.setMinutes(45)
                    props.setEndTime?.(newDate)
                    setShowErrorBorderInput(false)
                } else if (endTemp.getMinutes() > 45 && endTemp.getMinutes() <= 59) {
                    newDate.setMinutes(0)
                    newDate.setHours(newDate.getHours() + 1)
                    props.setEndTime?.(newDate)
                    setShowErrorBorderInput(false)
                }
            }
        } else {
            props.setEndTime?.(null)
            setShowErrorBorderInput(false)
        }
    }

    function onDayOrTimeSelected(dateTime: string, date: Date) {
        switch (dateTime) {
            case "day":
                return onDaySelected(date as Date)
            case "start":
                return onStartSelected(date as Date)
            case "end":
                return onEndSelected(date as Date)
        }
    }

    function setMinTime(time: string) {
        switch (time) {
            case "start":
                return isToday(moment(props.date)) ? minStartTime.toDate() : undefined
            case "end":
                return minEndTime?.toDate()
            default:
                return undefined
        }
    }

    function setMaxTime(time: string) {
        switch (time) {
            case "start":
                return isToday(moment(props.date)) ? maxTime.toDate() : undefined
            case "end":
                return minEndTime ? maxTime.toDate() : undefined
            default:
                return undefined
        }
    }

    const handleBlur = () => {
        if (props.startTime!.getTime() > nowDate.getTime() || props.startTime === null || props.startTime === undefined)
            setShowErrorBorderInput(false)
    }

    return (
        <InputIcons className={props.className}>
            {props.showIconInInput && renderIcon(props.icon, activeCalendarIcon)}
            <CalendarWrapper
                className={readOnly ? "readOnly" : ""}
                onChange={(date: Date) => onDayOrTimeSelected(props.time!, date as Date)}
                readOnly={readOnly}
                dateFormat={props.className === "select-time" ? hoursFormat : dayFormat}
                showTimeSelect={props.className === "select-time" ? true : false}
                showTimeSelectOnly={props.className === "select-time" ? true : false}
                disabled={props.viewMode === CalendarEntryModalViewMode.VIEW}
                showIconInInput={props.showIconInInput}
                placeholderText={props.placeholder}
                selected={loadTime(props.time!, props.date!, props.startTime!, props.endTime!)}
                timeIntervals={15}
                timeFormat="HH:mm"
                locale={language === "de" ? de : en}
                minTime={setMinTime(props.time!)}
                maxTime={setMaxTime(props.time!)}
                onCalendarClose={() => setActiveCalendarIcon(false)}
                onCalendarOpen={() => setActiveCalendarIcon(true)}
                showErrorBorder={props.className === "select-time" ? showErrorBorderInput : undefined}
                onBlur={handleBlur}
            />

            {((props.startTime !== null && props.time === "start" && !showErrorBorderInput) ||
                (props.endTime !== null && props.time === "end" && !showErrorBorderInput) ||
                (props.date !== null && props.time === "day" && !showErrorBorderInput)) && (
                <ValidationDiv>
                    <IconValidInput
                        width="14px"
                        height="14px"
                        fill={branding.calendarEntryModalPageContent.validationIconColor}
                    />
                </ValidationDiv>
            )}
        </InputIcons>
    )
}

export default DateCalendarPicker
