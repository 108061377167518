import styled from "styled-components"
import branding from "../../../branding/branding"
import { MeetingStatusMessageSize } from "./MeetingStatusMessage"

export const MeetingStatusContainer = styled.div<{ size?: MeetingStatusMessageSize }>`
    font-family: ${branding.font1};
    background-color: #202428;
    height: ${(props) => (props.size === MeetingStatusMessageSize.SMALL ? "100%" : "100vh")};
    width: 100%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
`

export const Title = styled.div<{ size?: MeetingStatusMessageSize }>`
    font-size: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "18px" : "16px")};
    line-height: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "18px" : "16px")};
    color: #d9d9d9;
    font-weight: 400;
    display: block;
    transition: opacity 1s;
    margin-bottom: 10px;
`

export const Message = styled.div<{ size?: MeetingStatusMessageSize }>`
    font-size: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "16px" : "14px")};
    line-height: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "16px" : "14px")};
    color: #fff;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
`

export const CustomMessage = styled.div<{ maxHeight?: string; size?: MeetingStatusMessageSize }>`
    font-size: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "14px" : "12px")};
    line-height: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "14px" : "12px")};
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "none")};
    overflow: ${(props) => (props.maxHeight ? "scroll" : "visible")};
`

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
`
