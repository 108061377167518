import * as React from "react"
import { Button, SafeAnchor } from "react-bootstrap"
import styled from "styled-components"
import { DataPrivacyDoc } from "../../backendServices/AuthenticationServices"
import branding from "../../branding/branding"
import { device, deviceSize } from "../../utils/Device"
import { trackPageView, trackTakeAction } from "../../utils/GTMTracking"

export const ErrorMessagesContainer = styled.div`
    margin-top: 2rem;
    font-size: inherit;
    order: 20;

    & .invalid-email-link {
        color: ${branding.loginRegistrationSite.invalidEmailPanelWithLink.textColor};
    }
`

const CheckboxParent = styled.div`
    display: flex;
    align-items: center;
    font: ${branding.font1};

    & a {
        color: ${branding.loginRegistrationSite.loginInputMailTextColor};
        text-decoration: underline;
    }

    @media ${device.mobile} {
        color: ${branding.loginRegistrationSite.mobileLoginTextColor};

        & a {
            color: ${branding.loginRegistrationSite.mobileLoginTextColor};
            text-decoration: underline;
        }
    }
`

const CheckboxInput = styled.input`
    min-width: 15px;
    min-height: 15px;
    background: #fff;
    border: 1px solid #000;
`

const CheckboxLabel = styled.label`
    text-align: left;
    margin-bottom: 0;

    @media ${device.tablet} {
        margin-bottom: 0;
    }
`

const Checkbox = (props: any) => <CheckboxInput type="checkbox" {...props} />
interface PrivacyCheckboxProps {
    accepted: boolean
    setAccepted: (value: boolean) => void
    acceptedSecond: boolean
    setAcceptedSecond: (value: boolean) => void
    dataPrivacyDoc: DataPrivacyDoc
}

export const PrivacyCheckbox: React.FunctionComponent<PrivacyCheckboxProps> = (props: PrivacyCheckboxProps) => {
    const privacyCheckboxVisible = branding.loginRegistrationSite.privacyDocsCheckbox.visible
    const privacySecondCheckboxVisible = branding.loginRegistrationSite.privacyDocsSecondCheckbox.visible

    React.useEffect(() => {
        trackPageView(branding.sideIconBar.dataPrivacyPolicyItem.title, "Registration", "PrivacyPolicy")
    }, [])

    return (
        <>
            {privacyCheckboxVisible && (
                <CheckboxParent className="mt-3" style={{ order: 10 }}>
                    <Checkbox
                        checked={props.accepted}
                        id="privacyCheckbox"
                        onChange={() => {
                            props.setAccepted(!props.accepted)

                            trackTakeAction("Accept Privacy Policy", "PrivacyPolicy", "Registration")
                        }}
                    />
                    <CheckboxLabel htmlFor="privacyCheckbox" className="ml-3">
                        {branding.loginRegistrationSite.privacyDocsCheckbox.labelBeforeLink}
                        <a href={props.dataPrivacyDoc?.url} target="_blank" rel="noopener noreferrer">
                            {branding.loginRegistrationSite.privacyDocsCheckbox.linkTitle}
                        </a>
                        {branding.loginRegistrationSite.privacyDocsCheckbox.labelAfterLink}
                    </CheckboxLabel>
                </CheckboxParent>
            )}

            {privacySecondCheckboxVisible && (
                <CheckboxParent className="mt-3" style={{ order: 11 }}>
                    <Checkbox
                        checked={props.acceptedSecond}
                        id="privacySecondCheckbox"
                        onChange={() => {
                            props.setAcceptedSecond(!props.acceptedSecond)

                            trackTakeAction("Accept Terms of Use", "TermsOfUse", "Registration")
                        }}
                    />
                    <CheckboxLabel htmlFor="privacySecondCheckbox" className="ml-3">
                        {branding.loginRegistrationSite.privacyDocsSecondCheckbox.labelBeforeLink}
                        <a
                            href={branding.loginRegistrationSite.privacyDocsSecondCheckbox.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {branding.loginRegistrationSite.privacyDocsSecondCheckbox.linkTitle}
                        </a>
                        {branding.loginRegistrationSite.privacyDocsSecondCheckbox.labelAfterLink}
                    </CheckboxLabel>
                </CheckboxParent>
            )}
        </>
    )
}

export const CenterLoginStyleTicketBtn = styled(Button)`
    font-size: 16px;
    height: 45px;
    min-width: 250px;
    width: auto;
    font-family: ${branding.font1};
    border-radius: 5px;
    border: ${branding.loginRegistrationSite.centerLoginStyleTicketBorderBtn};
    background-color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnBgColor};
    color: ${branding.loginRegistrationSite.centerLoginStyleTicketTextColorBtn};
    padding: 0.5rem 2rem;

    &:hover,
    &:focus,
    &:active {
        background-color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverBgColor} !important;
        border: ${branding.loginRegistrationSite.centerLoginStyleTicketBorderBtn} !important;
        outline: none !important;
        box-shadow: none !important;
        color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverTextColor} !important;
    }
`

export const CenterLoginStyleTicketButton = () => {
    return (
        <CenterLoginStyleTicketBtn
            onClick={() => {
                trackTakeAction("Go to ticket shop", "ExternalLink", "Ticketshop")
                window.open(`${branding.ticketSale.ticketsUrl}`, "_blank")
            }}
        >
            {branding.loginRegistrationSite.buyTicketButton}
        </CenterLoginStyleTicketBtn>
    )
}

export const CenterLoginTextFieldContainer = styled.div`
    margin: 10px 0;
`

export const StyledSafeAnchor = styled(SafeAnchor)`
    display: flex;
    outline: none;
    min-width: 200px;
    height: 45px;
    margin: 0;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    font-family: ${branding.font1};
    font-size: 16px;
    border: none;
    background-color: ${branding.loginRegistrationSite.loginButtonBackgroundColor}!important;
    color: ${branding.loginRegistrationSite.loginButtonTextColor}!important;
    text-decoration: none !important;

    @media ${device.tablet} {
        width: 250px;
        margin: 2rem auto 0;
    }

    @media ${device.mobile} {
        width: 200px;
    }
`

export const StyledButtonToLogin = styled(Button)`
    display: flex;
    outline: none;
    min-width: 200px;
    height: 45px;
    margin: 0;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    font-family: ${branding.font1};
    font-size: 16px;
    border: none;
    background-color: ${branding.loginRegistrationSite.loginButtonBackgroundColor}!important;
    color: ${branding.loginRegistrationSite.loginButtonTextColor}!important;
    text-decoration: none !important;

    @media ${device.tablet} {
        width: 250px;
        margin: 2rem auto 0;
    }

    @media ${device.mobile} {
        width: 200px;
    }
`

export const CenterLoginStyleHeader = styled.div`
    font-family: ${branding.font2};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: ${branding.secondaryTextColor ?? "#fff"};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media ${device.tablet} {
    }
`

export const CenterLoginStyleHeaderTitle = styled.h1`
    font-size: 24px;
    white-space: pre-line;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor ?? "#000"};
    font-weight: 700;

    @media ${device.tablet} {
        text-align: center;
        font-size: 2.3vw;
    }

    @media ${device.tablet} {
        text-align: center;
        font-weight: 500;
        font-size: ${branding.loginRegistrationSite.loginRegistrationTitleFontSize ?? "3vw"};
        color: ${branding.loginRegistrationSite.loginTitleTextColor ?? "#000"};
    }
`

export const MobileCenterLoginStyleHeaderTitle = styled(CenterLoginStyleHeaderTitle)`
    text-align: center;
    margin-bottom: 40px;
`

export const CenterLoginStyleHeaderSubitle = styled.p`
    font-size: 10px;
    white-space: pre;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor ?? "#fff"};

    @media ${device.tablet} {
        text-align: center;
        font-size: ${branding.loginRegistrationSite.loginRegistrationSubtitleFontSize ?? "1.5vw"};
        color: ${branding.loginRegistrationSite.loginSubTitleTextColor ?? "#fff"};
        font-weight: ${branding.loginRegistrationSite.loginSubTitleFontWeight ?? "normal"};
    }
`

export const CenterLoginStyleErrorMessage = styled.div`
    color: ${branding.loginRegistrationSite.centerLoginStyleErrorMessageColor};
    font-weight: ${branding.loginRegistrationSite.centerLoginStyleErrorMessageFontWeight};
`

export const CenterLoginStyleInputContainer = styled.div<{ windowHeight?: number; sso?: boolean }>`
    width: 100%;
    min-height: 350px;
    padding: ${(props) => (props.sso ? "2rem" : "1rem")};
    border-radius: 5px;
    position: absolute;
    top: ${(props) =>
        props.windowHeight && props.windowHeight < 1200 ? "55%" : branding.loginRegistrationSite.loginInputTopMargin ?? "50%"};
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${branding.loginRegistrationSite.mobileLoginTextColor ?? "#fff"};
    font-family: ${branding.font1};
    font-size: 14px;
    display: flex;
    flex-flow: column;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${branding.loginRegistrationSite.loginBoxOverlayColor ?? "rgba(0,0,0,.5)"};
        backdrop-filter: blur(3px);
        z-index: -1;
        border-radius: 5px;

        @media ${device.mobile} {
            background-color: #fff;
        }
    }
    &.loading::before {
        z-index: 2;
    }

    @media ${device.tablet} {
        width: 600px;
        border: 1px solid ${branding.loginRegistrationSite.loginModalTextColor ?? "#fff"};
        color: ${branding.loginRegistrationSite.loginModalTextColor ?? "#fff"};
        font-size: inherit;
        padding: ${(props) => (props.windowHeight && props.windowHeight < 1200 ? "2rem" : "2.5rem")};
        top: ${(props) =>
            props.windowHeight && props.windowHeight < 1200
                ? "55%"
                : branding.loginRegistrationSite.loginInputTopMargin ?? "50%"};
    }

    @media (min-width: 768px) and (max-height: 800px) {
        top: 60%;
    }

    @media ${device.mobile} {
        top: 45%;
    }
`

export const CenterLoginStyleForgotPassword = styled.div`
    text-align: right;
    font-size: 0.9rem;
    padding: 0;
    margin: -5px 0 10px;
    color: ${branding.loginRegistrationSite.changeEmailAddressLinkColor ?? "#fff"};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }

    &:focus {
        outline: none;
        text-decoration: underline;
    }

    @media ${device.mobile} {
        color: ${branding.loginRegistrationSite.mobileLoginTextColor};
    }
`

export const CenterLoginStyleSubmitButton = styled(Button)`
    display: block;
    outline: none;
    width: 100%;
    height: 45px;
    margin: 0;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    border: none;
    background-color: ${branding.loginRegistrationSite.loginButtonBackgroundColor}!important;
    color: ${branding.loginRegistrationSite.loginButtonTextColor}!important;
    order: 9;

    &.btn:disabled {
        background-color: ${branding.loginRegistrationSite.loginButtonDisabledBackgroundColor}!important;
        color: ${branding.loginRegistrationSite.loginButtonTextColorDisabled}!important;
        opacity: 1;
        border: none;
    }

    @media ${device.tablet} {
        width: 250px;
        margin: 2rem auto 0;
        order: 40;
    }

    @media ${device.mobile} {
        background-color: ${branding.loginRegistrationSite.mobileLoginButtonColor} !important;
        color: #fff !important;
        transition: 0.5s;

        &.btn:disabled {
            background-color: ${branding.loginRegistrationSite.mobileLoginButtonColor} !important;
            opacity: 0.5;
            transition-property: none;
        }
    }
`

export const CenterLoginStyleLoaderAttributes: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 3
}

export const CenterLoginStyleContactUsLabel = styled.div`
    font-family: ${branding.font1};
    width: 100%;
    margin-top: 3px;
    margin-bottom: 10px;
    text-align: center;
    color: ${branding.loginRegistrationSite.contactUsLabelColor ?? "#000"};
`

export const MobileLoginGetYourTicketSectionRoot = styled.div`
    border-top: 1px solid ${branding.loginRegistrationSite.getYourTicketSectionTopBorderColor};
    margin-top: 40px;
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 16.41px;
`

export const MobileLoginFormSimpleButton = styled.div`
    display: inline-block;
    color: ${branding.loginRegistrationSite.mobileLoginButtonColor};
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        text-decoration: underline;
    }
`

export const MobileLoginGetYourTicketSection: React.FunctionComponent = () => {
    const onBuyTicketButtonClick = () => {
        trackTakeAction("Go to ticket shop", "ExternalLink", "Ticketshop")
        window.open(`${branding.ticketSale.ticketsUrl}`, "_blank")
    }

    return (
        <>
            {branding.loginRegistrationSite.mobileLoginTicketButtonVisible && (
                <MobileLoginGetYourTicketSectionRoot>
                    <div style={{ display: "inline-block", marginRight: "5px" }}>
                        {branding.loginRegistrationSite.centerLoginStyleSubtitleShort}
                    </div>
                    <MobileLoginFormSimpleButton style={{ marginTop: "20px" }}>
                        <p onClick={onBuyTicketButtonClick}>{branding.ticketSale.getYourTicketButton}</p>
                    </MobileLoginFormSimpleButton>
                </MobileLoginGetYourTicketSectionRoot>
            )}
        </>
    )
}

export function getTextFieldBorderColor(windowSize: any): string {
    return windowSize.width <= deviceSize.tablet
        ? branding.loginRegistrationSite.mobileLoginTextColor
        : branding.loginRegistrationSite.loginInputMailTextColor
}

export function getTextFieldBackgroundColor(windowSize: any): string {
    return windowSize.width <= deviceSize.tablet
        ? branding.loginRegistrationSite.mobileLoginTextFieldBackgroundColor
        : branding.loginRegistrationSite.loginInputMailBackgroundColor
}

export function getTextFieldTextColor(windowSize: any): string {
    return windowSize.width <= deviceSize.tablet
        ? branding.loginRegistrationSite.mobileLoginTextColor
        : branding.loginRegistrationSite.loginInputMailTextColor
}
