import { format } from "date-fns"
import moment from "moment"
import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { useLanguageState } from "../globalStates/LanguageState"
import { device, DesktopVersionContainer } from "../utils/Device"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import { trackSelectContent } from "../utils/GTMTracking"

const EventLayoutRoot = styled.div`
    color: ${branding.mainInfoColor};
    padding: 25px 0;
    position: relative;

    @media ${device.tablet} {
        border-bottom: ${"1px solid " + branding.listDividerColor};
        border-top: ${"1px solid " + branding.listDividerColor};
    }
`

const EventLayoutTitle = styled.span`
    font-weight: bold;
    font-size: 18px;

    @media ${device.mobile} {
        font-size: 16px;
        margin-left: 15px;
    }
`

export const EventLogoDiv = styled(DetailNavLink)`
    width: 95px;
    height: 95px;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    @media (max-width: 1200px) {
        width: 80px;
        height: 80px;
    }

    @media ${device.mobile} {
        width: 95px;
        height: 95px;
        padding: 10px;
        margin-left: 15px;
    }
`

export const EventLogo = styled.img`
    object-fit: contain;
    margin: 0 20;
    height: 100%;
    width: 100%;
`

const EventDateRootArea = styled.div`
    display: flex;
    flex: 1;

    &.center-inside * {
        display: flex;
        justify-content: center;
    }
`
const DescriptionRoot = styled(DetailNavLink)`
    font-family: ${branding.font1};
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;

    & .list-view {
        width: 60%;
    }
`
export const CompanyLogoImage = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
    margin: 0 auto;
`
export const EventNameDiv = styled(DetailNavLink)`
    font-size: 16px !important;
    font-weight: bold;
    color: ${branding.programSchedule.listViewTitleColor};

    @media ${device.mobile} {
        font-size: 14px !important;
    }
`

interface CompanyDetailsSectionProps {
    event: any
}

const InfoContainer = styled.div`
    display: flex;
    width: 100%;
    padding-right: 10px;
    margin-left: 25px;

    @media ${device.mobile} {
        margin-top: -4px;
    }
`

const DescriptionContainer = styled.div`
    font-size: 12px;
    margin-top: 10px;

    @media ${device.mobile} {
        margin-top: 6px;
    }
`

const EventLayout: React.FunctionComponent<CompanyDetailsSectionProps> = React.memo((props) => {
    const lang = useLanguageState().getLanguage()

    const getFormattedDate = (date: string) => {
        if (date)
            return format(moment(date).toDate(), branding.eventTiming.eventDaysFormatPatternEvtDetailPage, {
                locale: lang === "de" ? de : en
            })
    }
    return (
        <>
            <EventLayoutRoot>
                <div style={{ marginBottom: "20px" }}>
                    <EventLayoutTitle>{branding.eventDateDetailPageContent.eventLayoutTitle}</EventLayoutTitle>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "100px" }}>
                        <EventLogoDiv
                            type="event"
                            name={props.event.name}
                            id={props.event.id}
                            onClick={() => {
                                trackSelectContent(props.event.name, "Details", "Event")
                            }}
                        >
                            <EventLogo src={props.event.logo || "/branding/applicationMedia/rsz_defaultgrey.png"} />
                        </EventLogoDiv>
                    </div>
                    <InfoContainer>
                        <EventDateRootArea>
                            <div>
                                <DesktopVersionContainer>
                                    {props.event.start && props.event.end && (
                                        <div style={{ fontSize: "12px", fontWeight: 400, marginBottom: "10px" }}>
                                            {getFormattedDate(props.event.start)} - {getFormattedDate(props.event.end)}
                                        </div>
                                    )}
                                </DesktopVersionContainer>
                                <EventNameDiv
                                    type="event"
                                    name={props.event.name}
                                    id={props.event.id}
                                    onClick={() => {
                                        trackSelectContent(props.event.name, "Details", "Event")
                                    }}
                                >
                                    {lang === "de" ? props.event.nameDe : props.event.name}{" "}
                                </EventNameDiv>
                                <DescriptionRoot
                                    type="event"
                                    name={props.event.name}
                                    id={props.event.id}
                                    onClick={() => {
                                        trackSelectContent(props.event.name, "Details", "Event")
                                    }}
                                >
                                    <DescriptionContainer>
                                        {" "}
                                        {lang === "de" ? props.event.descriptionShortDe : props.event.descriptionShort}{" "}
                                    </DescriptionContainer>
                                </DescriptionRoot>
                            </div>
                        </EventDateRootArea>
                    </InfoContainer>
                </div>
            </EventLayoutRoot>
        </>
    )
})

export default EventLayout
