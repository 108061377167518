import styled from "styled-components"

export const LocalVideoRoot = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    color: #fff;
    video {
        object-fit: contain !important;
    }
`

export const LocalTileVisibilityToggleButtonRoot = styled.div<{ isLocalTileVisible: boolean }>`
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 2px;
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    z-index: 20;
    text-align: center;
    cursor: pointer;
    bottom: ${(props) => (props.isLocalTileVisible ? "230px" : "105px")};
    right: 20px;
    opacity: 0.5;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    :hover {
        opacity: 1;
    }
`
