import { ActionType, Severity, useNotificationDispatch, useRosterState } from "amazon-chime-sdk-component-library-react"
import { useEffect, useState } from "react"
import { AttendeeData } from "../../../../backendServices/MeetingServices"
import branding from "../../../../branding/branding"
import { IconMicrophoneOff } from "../../../../ui/Icons"
import MuteAllModal from "../../../../ui/modals/MuteAllModal"
import { useModContext } from "../../../context/ModContext"
import { MuteAllButtonRoot, MuteAllButtonText } from "./MuteAllButton.styled"

function MuteAllButton() {
    const [modalVisible, setModalVisible] = useState(false)
    const modContext = useModContext()
    const { roster } = useRosterState()
    // eslint-disable-next-line
    const [attendees, setAttendees] = useState(Object.values(roster))
    const [participants, setParticipants] = useState<AttendeeData[]>([])
    const dispatch = useNotificationDispatch()

    useEffect(() => {
        setAttendees(Object.values(roster))
    }, [roster])

    useEffect(() => {
        setParticipants(attendees.filter((participant: AttendeeData) => participant.role === "member"))
    }, [attendees])

    function muteAllParticipants(participants: any) {
        participants.forEach((participant: any) => {
            if (participant.externalUserId) {
                modContext.modMute(participant.externalUserId)
            }
        })

        dispatch({
            type: ActionType.ADD,
            payload: {
                severity: Severity.WARNING,
                message: branding.conferenceTexts.bulkMuteInfoForMod.replace("{numberofusers}", participants.length.toString()),
                autoCloseDelay: 3000
            }
        })

        setModalVisible(false)
    }

    return (
        <MuteAllButtonRoot
            disabled={participants.length === 0}
            onClick={() => {
                if (participants.length === 0) return
                setModalVisible(true)
            }}
        >
            <IconMicrophoneOff fill="#fff" width="14px" height="14px" />
            <MuteAllButtonText>{branding.roster.muteAllButtonText}</MuteAllButtonText>

            {modalVisible && (
                <MuteAllModal
                    muteAllParticipants={() => muteAllParticipants(participants)}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </MuteAllButtonRoot>
    )
}

export default MuteAllButton
