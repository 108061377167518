import { createState, State, useState } from "@hookstate/core"
import {
    useContentShareControls,
    useContentShareState,
    useLocalAudioOutput,
    useLocalVideo,
    useRosterState,
    useToggleLocalMute
} from "amazon-chime-sdk-component-library-react"

import { getRaisedHandsByChimeMeetingId, insertRaisedHand, removeRaisedHand } from "../../backendServices/GraphQLServices"
import { useMeetingController } from "./MeetingController"
import { useAppState } from "../../globalStates/AppState"
import { ChatConversationParam } from "../../communicationArea/ChatPage"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { ConversationType } from "../../API"
import { ChatType } from "../enums/ChatType"
import { getChatType } from "../utils/getChatType"
import { AttendeeData } from "../../backendServices/MeetingServices"
import { usePreMeetingSettingContext } from "./PreMeetingSettingsContext"

interface StateValues {}

const getStartValues = (): StateValues => {
    return {}
}

export interface ControlBarContext {
    toggleMute: () => void
    isMuted: () => boolean
    isAudioOn: () => boolean
    toggleAudio: () => void
    isVideoEnabled: () => boolean
    toggleVideo: () => void
    toggleContentShare: () => void
    isContentShareEnabled: () => boolean
    toggleSettings: () => void
    leave: () => void
    raiseHand: () => void
    showChat: () => void
}
const state = createState<StateValues>(getStartValues())

const useStateWrapper = (state: State<StateValues>): ControlBarContext => {
    const { isVideoEnabled, toggleVideo } = useLocalVideo()
    const { muted, toggleMute } = useToggleLocalMute()
    const { isAudioOn, toggleAudio } = useLocalAudioOutput()
    const { isAudioVideoSettingsV2Open, setIsAudioVideoSettingsV2Open } = useAppState()
    const { toggleContentShare } = useContentShareControls()
    const meetingController = useMeetingController()
    const { setIsRosterOpen, setIsMeetingChatShown, setShowChatsTab } = useAppState()
    const { roster } = useRosterState()
    const profileId = useLoggedInState().user()?.profileId
    const { isLocalUserSharing } = useContentShareState()
    const preMeetingSettingsContext = usePreMeetingSettingContext()

    return {
        toggleMute() {
            toggleMute()
        },
        isMuted() {
            return muted
        },
        isAudioOn: () => {
            return isAudioOn
        },
        toggleAudio: () => {
            toggleAudio()
        },
        isVideoEnabled: () => {
            return isVideoEnabled
        },
        toggleVideo: async () => {
            document.body.style.cursor = "progress" // because the camera takes sometimes a lot of time to turn on
            preMeetingSettingsContext.applyStoredVideoSettings()
            await toggleVideo()
            document.body.style.cursor = "default"
        },
        toggleContentShare: () => {
            toggleContentShare()
        },
        isContentShareEnabled: () => {
            return isLocalUserSharing
        },
        toggleSettings: () => {
            setIsAudioVideoSettingsV2Open(!isAudioVideoSettingsV2Open)
        },
        leave: async () => {
            meetingController.leaveMeeting()
        },
        raiseHand: async () => {
            const raisedHandsResponse = await getRaisedHandsByChimeMeetingId(meetingController.getExternalMeetingId() as string)
            let isMyHandRaised = false
            if (raisedHandsResponse) {
                isMyHandRaised =
                    raisedHandsResponse.data.listRaisedHands.items.filter(
                        (raisedHand: any) => raisedHand.id === meetingController.getExternalUserId()
                    ).length > 0
            }
            if (!isMyHandRaised) {
                insertRaisedHand(
                    meetingController.getExternalMeetingId() as string,
                    meetingController.getExternalUserId() as string
                )
            } else {
                removeRaisedHand(meetingController.getExternalUserId() as string)
            }
        },
        showChat: async () => {
            switch (meetingController.getMeetingKind()) {
                case "call":
                    let conversationId = meetingController.getExternalMeetingId()?.slice(3) || ""
                    let chatType = await getChatType(meetingController.getMeetingKind(), roster, conversationId)
                    let interlocutorId = Object.values(roster).filter((attendee: AttendeeData) => {
                        return attendee.id !== profileId
                    })[0]?.externalUserId

                    if (interlocutorId && chatType !== ChatType.CALL_GROUP) {
                        setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(interlocutorId))
                    } else {
                        ChatConversationParam.conversationByConversationId(ConversationType.GROUP, conversationId)
                    }
                    break
                // logic here
                case "virtualCafe":
                case "showroom":
                case "breakout":
                case "conferenceroom":
                case "greenroom":
                case "roundtable":
                    setIsRosterOpen(true)
                    setIsMeetingChatShown(true)
                    break
                // logic here
                default:
                    break
            }
        }
    }
}

export const useControlBarContext = (): ControlBarContext => useStateWrapper(useState(state))
