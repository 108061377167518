import { ActionType, Severity, useNotificationDispatch } from "amazon-chime-sdk-component-library-react"
import { useCallback, useEffect, useState } from "react"
import { BackendServiceError } from "../../../backendServices/BackendServicesUtils"
import { restartRecorder, RestartRecorderResponse } from "../../../backendServices/MeetingServices"
import branding from "../../../branding/branding"
import { AvatarWithDefault } from "../../../ui/AvatarWithDefault"
import { IconRecorderIndicator, IconReplay } from "../../../ui/Icons"
import { useMeetingController } from "../../context/MeetingController"
import {
    IconWrapper,
    RecorderAvatar,
    RecorderIndicator,
    RecorderItem,
    RecorderRestartBtn,
    RecorderRoot,
    RecorderTitle
} from "./Recorder.styled"

interface RecorderProps {
    recorderAvailable: boolean
}

function Recorder(props: RecorderProps) {
    const meetingController = useMeetingController()
    const [recorderButtonEnabled, setRecorderButtonEnabled] = useState(false)
    const minRecorderRestartDelay = 1000 * 120
    const [isRecorderLoading, setIsRecorderLoading] = useState(false)
    const [enableRecorderButtonHandle, setEnableRecorderButtonHandle] = useState<number | undefined>()
    const [reloadIconClasses, setReloadIconClasses] = useState("")
    const [indicatorIconColor, setIndicatorIconColor] = useState("grey")
    const dispatch = useNotificationDispatch()

    const showNotifications = useCallback(() => {
        if (props.recorderAvailable) {
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.SUCCESS,
                    message: branding.conferenceTexts.recorderJoinedTitle,
                    autoCloseDelay: 3000
                }
            })
        } else {
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.INFO,
                    message: branding.conferenceTexts.recorderNotAvailableTitle,
                    autoCloseDelay: 3000
                }
            })
        }
    }, [props.recorderAvailable, dispatch])

    const generateStyles = useCallback(() => {
        if (props.recorderAvailable) {
            setIndicatorIconColor("#e30613")
        } else {
            setIndicatorIconColor("grey")
        }

        if (isRecorderLoading) {
            setReloadIconClasses("disabledRestart spin")
        } else if (!isRecorderLoading && !props.recorderAvailable) {
            setReloadIconClasses("")
        }

        if (props.recorderAvailable || !meetingController.getIsMod()) {
            setReloadIconClasses("disabledRestart")
        }
        //eslint-disable-next-line
    }, [props.recorderAvailable, isRecorderLoading])

    const handleReloadButtonStates = useCallback(() => {
        if (props.recorderAvailable) {
            if (enableRecorderButtonHandle) {
                clearTimeout(enableRecorderButtonHandle)
                setEnableRecorderButtonHandle(undefined)
            }
            setRecorderButtonEnabled(false)
        } else {
            setRecorderButtonEnabled(enableRecorderButtonHandle === undefined)
        }
        // eslint-disable-next-line
    }, [props.recorderAvailable])

    useEffect(() => {
        showNotifications()
        handleReloadButtonStates()
        // eslint-disable-next-line
    }, [props.recorderAvailable])

    useEffect(() => {
        generateStyles()
        // eslint-disable-next-line
    }, [props.recorderAvailable, isRecorderLoading])

    const onRestartRecorderButtonClicked = async () => {
        setIsRecorderLoading(true)
        const externalMeetingId = meetingController.getExternalMeetingId()?.slice(3)
        if (externalMeetingId) {
            setRecorderButtonEnabled(false)
            setEnableRecorderButtonHandle(3)
            const response = await restartRecorder(externalMeetingId)
            if ((response as BackendServiceError).httpStatus) {
                setRecorderButtonEnabled(true)
                setIsRecorderLoading(false)
                setEnableRecorderButtonHandle(undefined)
                dispatch({
                    type: ActionType.ADD,
                    payload: {
                        severity: Severity.ERROR,
                        message: branding.conferenceTexts.recorderErrorMessage,
                        autoCloseDelay: 3000
                    }
                })
            } else if ((response as RestartRecorderResponse).success) {
                const handle = window.setTimeout(() => {
                    if (props.recorderAvailable) {
                        // we got success from the backend and the recorder joined
                        setRecorderButtonEnabled(true)
                        setEnableRecorderButtonHandle(handle)
                    } else {
                        // we got success from the backend but the recorder didn't join
                        setRecorderButtonEnabled(true)
                        setEnableRecorderButtonHandle(undefined)
                        dispatch({
                            type: ActionType.ADD,
                            payload: {
                                severity: Severity.ERROR,
                                message: branding.conferenceTexts.recorderErrorMessage2,
                                autoCloseDelay: 3000
                            }
                        })
                    }
                    setIsRecorderLoading(false)
                }, minRecorderRestartDelay)
            }
        }
    }

    return (
        <RecorderRoot>
            <RecorderItem>
                <RecorderAvatar>
                    <AvatarWithDefault size={55} alt={"Recorder"} />
                </RecorderAvatar>
                <RecorderTitle isRecorderAvailable={props.recorderAvailable}>Recorder</RecorderTitle>
                <RecorderIndicator>
                    <IconWrapper>{IconRecorderIndicator({ fill: indicatorIconColor, width: "18", height: "18" })}</IconWrapper>
                </RecorderIndicator>
                <RecorderRestartBtn
                    onClick={() => {
                        if (!recorderButtonEnabled || isRecorderLoading || !meetingController.getIsMod()) return
                        onRestartRecorderButtonClicked()
                    }}
                >
                    <IconWrapper className={reloadIconClasses}>
                        {IconReplay({ fill: "#fff", width: "18", height: "18" })}
                    </IconWrapper>
                </RecorderRestartBtn>
            </RecorderItem>
        </RecorderRoot>
    )
}

export default Recorder
