import { Branding } from "../../../branding/branding"

export interface InputProps {
    label: string
    inputPlaceholder: string
    inputType: InputType
    inputValue: string
    required: boolean
    inputWidth: string
    showParticipants?: boolean
    showRadioInput?: boolean
    className?: string
    showIconInInput?: boolean
    icon?: string
    time?: string
    customPlaceholder?: string
    showToggleSwitcher?: boolean
}

export enum InputType {
    TextArea = "textarea",
    Select = "select",
    TextWithDropdown = "text-with-dropdown",
    Date = "date",
    Search = "search",
    Counter = "counter",
    Text = "text",
    Toggle = "toggle"
}

export interface RadioButtonProps {
    type: string
    id: string
    text: string
}

export const data = (branding: Branding) => [
    {
        label: branding.calendarEntryModalPageContent.name,
        className: "text-input",
        inputPlaceholder: branding.calendarEntryModalPageContent.namePlaceholder,
        inputType: InputType.Text,
        inputValue: "",
        required: true,
        inputWidth: "100%",
        showIconInInput: false
    },
    {
        label: branding.calendarEntryModalPageContent.day,
        inputPlaceholder: branding.calendarEntryModalPageContent.dayPlaceholder,
        inputType: InputType.Date,
        inputValue: "",
        required: true,
        inputWidth: "100%",
        className: "input-with-icon",
        showIconInInput: true,
        icon: "calendar",
        time: "day"
    },
    {
        label: branding.calendarEntryModalPageContent.start,
        inputPlaceholder: branding.calendarEntryModalPageContent.startDatePlaceholder,
        inputType: InputType.Date,
        inputValue: "",
        required: true,
        inputWidth: "41%",
        showIconInInput: true,
        icon: "clock",
        showDropdownIcon: true,
        className: "select-time",
        time: "start"
    },
    {
        label: branding.calendarEntryModalPageContent.end,
        inputPlaceholder: branding.calendarEntryModalPageContent.endDatePlaceholder,
        inputType: InputType.Date,
        inputValue: "",
        required: true,
        inputWidth: "41%",
        showIconInInput: true,
        icon: "clock",
        showDropdownIcon: true,
        className: "select-time",
        time: "end"
    },
    {
        label: "",
        inputPlaceholder: "",
        inputType: InputType.Counter,
        inputValue: "",
        required: false,
        inputWidth: "12%",
        showIconInInput: false,
        icon: "",
        showDropdownIcon: false,
        className: "counter",
        time: ""
    },
    {
        label: branding.calendarEntryModalPageContent.timeZone,
        inputPlaceholder: "(GMT+01:00) CET - Berlin",
        inputType: InputType.Select,
        inputValue: "",
        required: false,
        inputWidth: "100%",
        showIconInInput: false,
        showDropdownIcon: true
    },
    {
        label: branding.calendarEntryModalPageContent.locationPickerTitle,
        inputPlaceholder: branding.calendarEntryModalPageContent.locationPickerPlaceholderCustom,
        customPlaceholder: branding.calendarEntryModalPageContent.locationPickerPlaceholderStand,
        inputType: InputType.TextWithDropdown,
        inputValue: "",
        required: false,
        inputWidth: "100%",
        showRadioInput: true,
        showToggleSwitcher: true
    },
    {
        label: branding.calendarEntryModalPageContent.searchPerson,
        inputPlaceholder: branding.calendarEntryModalPageContent.searchPersonPlaceholder,
        inputType: InputType.Search,
        inputValue: "",
        required: false,
        inputWidth: "100%",
        showParticipants: true,
        showIconInInput: true,
        icon: "search"
    },
    {
        label: branding.calendarEntryModalPageContent.notes,
        inputPlaceholder: branding.calendarEntryModalPageContent.notesPlaceholder,
        inputType: InputType.TextArea,
        inputValue: "",
        required: false,
        inputWidth: "100%"
    }
]

export const radioButtons = (branding: Branding) => [
    {
        type: "radio",
        id: "stand",
        text: branding.calendarEntryModalPageContent.locationPickerStandRadioButtonLabel
    },
    {
        type: "radio",
        id: "custom",
        text: branding.calendarEntryModalPageContent.locationPickerCustomRadioButtonLabel
    }
]
