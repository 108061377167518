import { detect } from "detect-browser"
import { Fragment, useEffect, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import { Button } from "../conference/components/Button"
import NetworkingTabErrorBoundary from "../contentArea/errorPages/NetworkingTabErrorBoundary"
import { defaultLogger as logger, useAppState } from "../globalStates/AppState"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import CenteredLoader from "../ui/CenteredLoader"
import CrsMultiSwitch from "../ui/CrsMultiSwitch"
import { IconDownload } from "../ui/Icons"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import SearchBar from "../ui/SearchBar"
import {
    MultiSwitchRoot,
    NetworkingListType,
    NextPageLoader,
    SearchBarWidth,
    SubTabRoot,
    TabEmpty,
    TabRoot
} from "./CommunicationArea"
import NetworkingToggle from "./components/NetworkingToggle"
import { ContactEntry, ContactEntryType } from "./ContactEntry"
import { useContactState } from "./ContactState"
import OrganizationEntry from "./OrganizationEntry"
import EmptyTile from "../contentArea/reception/EmptyTile"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"
import {
    ContactListResponse,
    ContactItem,
    loadRelevantProfilesListData,
    loadContactRequestListData,
    loadContactListData,
    exportContactsAsCsv,
    exportContactsAsVCard,
    loadContactBlockedtListData
} from "../backendServices/SeriesOfTopicsUserServices"
import InView from "react-intersection-observer"
import ExportContactsModal from "../ui/modals/ExportContactsModal"
import _ from "lodash"
import { useFavoriteState } from "../globalStates/Favorites"
import { trackPageView, trackSelectContent } from "../utils/GTMTracking"

const SearchBarRootArea = styled.div`
    & .MuiIconButton-root:focus {
        outline: none;
    }

    & .MuiIconButton-root:hover {
        background: none;
    }

    .MuiInput-underline:after {
        border-bottom: 1px solid;
        border-color: ${branding.mainInfoColor ?? "black"};
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid;
        border-color: ${branding.mainInfoColor ?? "black"};
    }
`

const SearchBarRoot = styled.div<{ hideOnScroll?: boolean }>`
    text-align: center;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;
    .MuiInputBase-input {
        font-size: 14px;
        font-family: ${branding.font1};
    }
    #searchIcon svg {
        color: ${branding.primaryColor ?? "black"};
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: box-shadow 0.3s ease-in-out;
        box-shadow: ${(props) => (!props.hideOnScroll ? "none" : branding.primaryScrollDarkShadowBTB)};
    }
`

const DownloadIconsRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 20px 0 20px;
`

const DownloadLabel = styled.div`
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 17px;
    color: ${branding.sideIconBar.sideIconColorDark};
`

const TabContentArea = styled.div`
    margin-top: 15px;
    width: 100%;
`

const TabSectionHeader = styled.div`
    margin-left: 15px;
    margin-top: 25px;
    margin-bottom: 8px;
    margin-right: 20px;
    font-size: 14px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "black"};
    display: flex;
    justify-content: space-between;
`

export const TabActivateNetworking = styled.div`
    padding-top: 30px;
    margin-left: 7%;
    margin-right: 7%;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: ${branding.font1};
    font-size: 15px;
`

export interface badgeWrapperProps {
    xPos: number
}
const BadgeContentArea = styled.div<badgeWrapperProps>`
    position: absolute;
    top: -15px;
    left: ${(props) => props.xPos}px;
    z-index: 99;
`
const BadgeUnreadCounter = styled.span`
    position: relative;
    background-color: ${branding.communicationArea.badgeUnreadCounterBgColor ?? "#000"};
    border-radius: 50%;
    margin-left: 1px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${branding.communicationArea.badgeUnreadCounterBorderColor ?? "#fff"};
    font-size: 10px;
`
const BadgeRow = styled.div`
    display: flex;
    position: relative;
`

interface BadgeTabsProps {
    badgeParams: { x: number; count: number }[]
}
const BadgeTabs: React.FunctionComponent<BadgeTabsProps> = (props) => {
    return (
        <BadgeRow>
            {props.badgeParams.map((params, idx) => {
                return (
                    <BadgeContentArea key={idx} xPos={params.x}>
                        {params.count <= 0 ? null : (
                            <h6 style={{ fontSize: "14px" }}>
                                <BadgeUnreadCounter className="badge badge-pill badge-dark">{params.count}</BadgeUnreadCounter>
                            </h6>
                        )}
                    </BadgeContentArea>
                )
            })}
        </BadgeRow>
    )
}
interface NetworkingTabProps {
    requests: number
    contacts: number
}
const NetworkingTab: React.FunctionComponent<NetworkingTabProps> = (props) => {
    const appState = useAppState()
    const itemList = [
        { label: branding.communicationArea.allPeopleTabTitle, value: NetworkingListType.RELEVANT },
        { label: branding.communicationArea.requestsTabTitle, value: NetworkingListType.REQUESTS },
        { label: branding.communicationArea.contactsTabTitle, value: NetworkingListType.CONTACTS }
    ]
    const selectedItem = appState.communicationCenterDisplayParam ?? NetworkingListType.RELEVANT

    useEffect(() => {
        trackPageView(branding.communicationArea.peopleTabTitle, "Overallview", "Networking")
    }, [])

    const errorMessage =
        selectedItem === NetworkingListType.RELEVANT
            ? branding.communicationArea.relevantListErrorMessage
            : selectedItem === NetworkingListType.REQUESTS
            ? branding.communicationArea.requestListErrorMessage
            : selectedItem === NetworkingListType.CONTACTS
            ? branding.communicationArea.contactListErrorMessage
            : ""

    const reloadButtonText =
        selectedItem === NetworkingListType.RELEVANT
            ? branding.communicationArea.reloadButtonText
            : selectedItem === NetworkingListType.REQUESTS
            ? branding.communicationArea.reloadButtonText
            : selectedItem === NetworkingListType.CONTACTS
            ? branding.communicationArea.reloadButtonText
            : ""

    return (
        <TabRoot>
            <MultiSwitchRoot>
                <BadgeTabs
                    badgeParams={[
                        { x: 130, count: props.requests },
                        { x: 222, count: props.contacts }
                    ]}
                />
                <CrsMultiSwitch
                    items={itemList}
                    activeItem={selectedItem}
                    onItemClick={appState.setShowPeopleTab}
                    style={{
                        mainWidth: branding.crsMultiSwitcher.crsMultiSwitcherMainWidth,
                        msRootItem: { padding: "1px", border: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }
                    }}
                />
            </MultiSwitchRoot>
            <TabContentArea className="rs-nav-content">
                <NetworkingTabErrorBoundary errorMessage={errorMessage} reloadButtonText={reloadButtonText}>
                    <NetworkingTabSwitch eventKey={selectedItem} contacts={props.contacts} requests={props.requests} />
                </NetworkingTabErrorBoundary>
            </TabContentArea>
        </TabRoot>
    )
}

export default NetworkingTab

interface NetworkingTabSwitchProps {
    eventKey: NetworkingListType
    contacts: number
    requests: number
}

export function NetworkingTabSwitch(props: NetworkingTabSwitchProps) {
    switch (parseInt(props.eventKey.toString())) {
        case NetworkingListType.RELEVANT:
            return <RelevantTab />
        case NetworkingListType.REQUESTS:
            return <RequestsTab requests={props.requests} />
        case NetworkingListType.CONTACTS:
            return <ContactsTab contacts={props.contacts} />
    }
    return <Fragment />
}

export interface GenericTabProps {
    emptyList: string
    hasDownload?: (groupNumber: number) => boolean
    isRequest?: (groupNumber: number) => boolean
    doDownloadCsv?: () => void
    doDownloadVCard?: () => void
    setReload?: () => void
    unreadCounter: number
    isReload?: boolean
    getSectionHeader: (groupNaumber: number) => string
    fetchData: (profileId: string, searchString: string, page: number) => Promise<ContactListResponse | BackendServiceError>
    tabContainsNetworkingRestriction?: boolean
    tabContainsFavorites?: boolean
    tabType: ContactEntryType
}

const GenericTab: React.FunctionComponent<GenericTabProps> = (props) => {
    const [contacts, setContacts] = useState<ContactItem[]>([])
    const [hasNext, setHasNext] = useState<boolean>()
    const [searchString, setSearchString] = useState("")
    const [page, setPage] = useState(0)
    const userState = useLoggedInState()
    const languageState = useLanguageState()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const contactState = useContactState()
    const browser = detect()
    const favoriteState = useFavoriteState()
    const [hideOnScroll, setHideOnScroll] = useState(false)
    const exportContactsAsCsv = branding.communicationArea.enableExportContactsAsCsv
    const exportContactsAsVCard = branding.communicationArea.enableExportContactsAsVCard

    const [showExportModal, setShowExportModal] = useState<boolean>(false)

    const [networkingEnabled, setNetworkingEnabled] = useState<boolean>(userState.isMatchActive() || false)

    useEffect(() => {
        if (userState.user() && networkingEnabled && props.tabContainsNetworkingRestriction && refresh) {
            setTimeout(() => {
                loadData(searchString, page)
            }, 1000)

            setIsLoaded(true)
        }
        // eslint-disable-next-line
    }, [refresh])

    useEffect(() => {
        if (props.tabContainsNetworkingRestriction && !userState.isMatchActive()) {
            setIsLoaded(true)
            return
        }

        const timeout = setTimeout(() => {
            loadData(searchString, page)
        }, 600)

        return () => clearTimeout(timeout)
    }, [searchString, page, props.unreadCounter, languageState.getLanguage()]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.tabType !== ContactEntryType.RELEVANT) {
            return
        }

        const timeout = setTimeout(() => {
            loadData(searchString, page)
        }, 600)

        return () => clearTimeout(timeout)
    }, [favoriteState.readOnlyRefreshFavorites]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userState.isMatchActive()) {
            enableNetworking()
        } else {
            disableNetworking()
        }
        // eslint-disable-next-line
    }, [userState.isMatchActive()])

    function enableNetworking() {
        setNetworkingEnabled(true)
        setRefresh(true)
    }
    function disableNetworking() {
        setNetworkingEnabled(false)
        setIsLoaded(true)
    }

    function checkforInvalidOrganizationRequests() {
        let numberOfInvalid = 0

        contacts.forEach((contact: ContactItem) => {
            if (contact.orgaConnectionRequest && contact.orgaConnectionRequest?.sotUserId !== userState.user()?.profileId) {
                numberOfInvalid += 1
            }
        })

        return numberOfInvalid === contacts.length
    }

    function shouldCompleteProfile(): boolean {
        if (
            !userState.user()?.firstName ||
            !userState.user()?.lastName ||
            !userState.user()?.position ||
            !userState.user()?.company
        ) {
            return true
        }

        return false
    }

    let content: JSX.Element = <div />

    if (!isLoaded) {
        content = <CenteredLoader />
    } else {
        let groupNumber: number = -2

        const noSearchResultsContent: JSX.Element = <TabEmpty>{branding.communicationArea.noSearchResultsTitle}</TabEmpty>

        const noContactsContent: JSX.Element = <TabEmpty>{props.emptyList}</TabEmpty>

        const handleScroll = (scrollValues: any) => {
            if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
                setHideOnScroll(scrollValues.scrollTop > 0)
            }
        }

        const contactsLoadedContent = (
            <ContentScrollContainer scrollBarAlwaysVisible={false} adjustForHeaderWith="250px" handleScroll={handleScroll}>
                {contacts.map((contact, index) => {
                    let addSectionHeader = false
                    if (contact.group > groupNumber) {
                        addSectionHeader = true
                        groupNumber = contact.group
                    }
                    return contact.sotUser ? (
                        <div key={index}>
                            {addSectionHeader && (
                                <TabSectionHeader>
                                    <div>{props.getSectionHeader(groupNumber)}</div>
                                </TabSectionHeader>
                            )}
                            <ContactEntry
                                contact={contact.sotUser}
                                key={contact.sotUser.id}
                                setReload={props.isReload ? () => loadData(searchString, 0) : props.setReload}
                                isIncomingRequest={props.isRequest && props.isRequest(groupNumber)}
                                entryType={props.tabType}
                            />
                        </div>
                    ) : contact.orgaConnectionRequest &&
                      contact.orgaConnectionRequest.sotUserId === userState.user()?.profileId ? (
                        <div key={index}>
                            {addSectionHeader && (
                                <TabSectionHeader>
                                    <div>{props.getSectionHeader(groupNumber)}</div>
                                </TabSectionHeader>
                            )}
                            <OrganizationEntry organizationId={contact.orgaConnectionRequest.orgaId} />
                        </div>
                    ) : null
                })}
                {hasNext && (
                    <InView
                        threshold={0.1}
                        onChange={(inView) => {
                            if (inView) setPage(page + 1)
                        }}
                    >
                        <NextPageLoader />
                    </InView>
                )}
            </ContentScrollContainer>
        )
        // <ContactEntry isIncomingRequest={contact.group === 0 || contact.group === 1} />

        const getContactsContent = () => {
            return checkforInvalidOrganizationRequests() ? noContactsContent : contactsLoadedContent
        }

        if (!userState.user()?.matchActive && props.tabContainsNetworkingRestriction) {
            content = (
                <div>
                    <TabActivateNetworking>{branding.communicationArea.activateNetworkingText}</TabActivateNetworking>
                    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                        <NetworkingToggle
                            customEnabled={networkingEnabled}
                            customFunction={() => enableNetworking()}
                            alwaysShowToggle={true}
                        />
                    </div>
                    {networkingEnabled && <NextPageLoader />}
                </div>
            )
        } else {
            content =
                contacts.length > 0 ? getContactsContent() : searchString.length > 0 ? noSearchResultsContent : noContactsContent
        }
    }

    const handleSearch = (value: string) => {
        setPage(0)
        setSearchString(value)
    }

    return (
        <SearchBarRootArea>
            <SearchBarRoot hideOnScroll={hideOnScroll}>
                <SearchBar
                    setSearchParam={handleSearch}
                    searchValue={searchString}
                    placeholder={branding.communicationArea.searchBarTextPerson ?? branding.sideIconBar.searchBarPlaceholder}
                    width={SearchBarWidth}
                    height="35px"
                    bottom={"8px"}
                    bottomEnd={"8px"}
                />
                {props.tabType === ContactEntryType.CONTACT &&
                    contacts &&
                    contacts.length > 0 &&
                    (exportContactsAsCsv || exportContactsAsVCard) && (
                        <DownloadIconsRoot>
                            <DownloadLabel>{branding.communicationArea.exportContacts}</DownloadLabel>
                            {exportContactsAsVCard && exportContactsAsCsv && (
                                <Button
                                    margin={3}
                                    size={browser && browser.name === "safari" ? 30 : 15}
                                    icon={IconDownload({
                                        fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                        width: "16",
                                        height: "16"
                                    })}
                                    onClick={() => {
                                        setShowExportModal(true)
                                    }}
                                    backgroundColor="#ffffff"
                                />
                            )}

                            {/* No need to show the modal when only one option is available, left for compatibility purposes */}
                            {exportContactsAsVCard && !exportContactsAsCsv && props.doDownloadCsv && (
                                <Button
                                    margin={3}
                                    size={browser && browser.name === "safari" ? 30 : 15}
                                    icon={IconDownload({
                                        fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                        width: "16",
                                        height: "16"
                                    })}
                                    tooltip={branding.communicationArea.exportVCards}
                                    onClick={(e) => {
                                        trackSelectContent(
                                            branding.communicationArea.exportContacts,
                                            "Download",
                                            "Networking",
                                            undefined,
                                            undefined,
                                            "csv",
                                            "contacts.csv"
                                        )
                                        props.doDownloadVCard!()
                                    }}
                                    backgroundColor="#ffffff"
                                />
                            )}
                            {exportContactsAsCsv && !exportContactsAsVCard && props.doDownloadVCard && (
                                <Button
                                    className="ml-3"
                                    margin={3}
                                    size={browser && browser.name === "safari" ? 30 : 15}
                                    icon={IconDownload({
                                        fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                        width: "16",
                                        height: "16"
                                    })}
                                    tooltip={branding.communicationArea.exportCsv}
                                    onClick={(e) => {
                                        trackSelectContent(
                                            branding.communicationArea.exportContacts,
                                            "Download",
                                            "Networking",
                                            "vcf",
                                            "contacts.vcf"
                                        )
                                        props.doDownloadCsv!()
                                    }}
                                    backgroundColor="#ffffff"
                                />
                            )}
                        </DownloadIconsRoot>
                    )}
            </SearchBarRoot>
            <SubTabRoot id="scrollcontainer" tabType={props.tabType}>
                {shouldCompleteProfile() ? (
                    <EmptyTile
                        padding={"15px"}
                        headerFontWeight={500}
                        header={branding.communicationArea.completeProfileNetworkingText}
                        headerClickable={true}
                        headerClickableWord={branding.communicationArea.completeProfileNetworkingClickableWord}
                        headerLink={"/myprofile?mode=EDIT"}
                        hideButton={true}
                        bgColor="transparent"
                    />
                ) : (
                    content
                )}
            </SubTabRoot>
            {showExportModal && (
                <ExportContactsModal
                    doDownloadCsv={props.doDownloadCsv}
                    doDownloadVCard={props.doDownloadVCard}
                    onClose={() => setShowExportModal(false)}
                />
            )}
        </SearchBarRootArea>
    )

    function loadData(searchString: string, page: number) {
        const profileId: string | undefined = userState.user()?.profileId
        if (profileId === undefined) {
            return
        }
        page === 0 ? setIsLoaded(false) : setIsLoaded(true)

        props
            .fetchData(profileId, searchString, page)
            .then((data) => {
                if (!data) {
                    setIsLoaded(true)
                    return
                }

                if ((data as BackendServiceError).httpStatus) {
                    // TODO ERROR
                } else {
                    const clr: ContactListResponse = data as ContactListResponse
                    contactState.setAll(
                        clr.contacts
                            .filter((c) => c.sotUser)
                            .map((c) => {
                                return {
                                    id: c.sotUser.id,
                                    connectionStatus: c.sotUser.myConnectionStatus,
                                    userType: c.sotUser.type
                                }
                            })
                    )

                    if (props.tabContainsFavorites) {
                        setContacts(
                            _.uniqBy(page === 0 ? clr.contacts : contacts.concat(clr.contacts), function (u) {
                                return u.sotUser.id
                            })
                        )
                    } else {
                        setContacts(page === 0 ? clr.contacts : contacts.concat(clr.contacts))
                    }

                    setHasNext(clr.hasNextPage)
                }
                setIsLoaded(true)
            })
            .catch((err) => {
                logger.error({ message: "failed loading networking data", errorMessage: err.message, errorStack: err.stack })
                setIsLoaded(true)
            })
    }
}

const RelevantTab: React.FunctionComponent = () => {
    useEffect(() => {
        trackPageView(branding.communicationArea.allPeopleTabTitle, "List", "RelevantUser")
    }, [])

    const getSectionHeader = (groupNumber: number) => {
        switch (groupNumber) {
            case 0: {
                return branding.communicationArea.relevantTabRelevantSectionTitle
            }
            case -1: {
                return branding.communicationArea.contactsTabFavoritesSectionTitle
            }
            default: {
                return branding.communicationArea.relevantTabAllSectionTitle
            }
        }
    }

    const fetchDdata = (profileId: string, searchString: string, page: number) => {
        return loadRelevantProfilesListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page,
            hideConnections: true
        })
    }

    return (
        <GenericTab
            tabContainsNetworkingRestriction
            tabContainsFavorites
            getSectionHeader={getSectionHeader}
            fetchData={fetchDdata}
            emptyList={""}
            unreadCounter={0}
            tabType={ContactEntryType.RELEVANT}
            isReload
        />
    )
}

interface RequestsTabProps {
    requests: number
}

const RequestsTab: React.FunctionComponent<RequestsTabProps> = (props) => {
    useEffect(() => {
        trackPageView(branding.communicationArea.requestsTabTitle, "List", "ContactRequest")
    }, [])

    function getSectionHeader(groupNumber: number) {
        let titleString: string = ""

        switch (groupNumber) {
            case 0: {
                titleString = branding.communicationArea.requestsTabAwaitingReplySectionTitle
                break
            }
            case 1: {
                titleString = branding.communicationArea.requestsTabMyOrganizationRequestsSectionTitle
                break
            }
            case 2: {
                titleString = branding.communicationArea.requestsTabMyPendingRequestsSectionTitle
                break
            }
            case 3: {
                titleString = branding.communicationArea.requestsTabMyExhibitorRequestsSectionTitle
                break
            }
        }

        return titleString
    }

    function fetchDdata(profileId: string, searchString: string, page: number) {
        return loadContactRequestListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page,
            ddbCounter: props.requests
        })
    }

    return (
        <GenericTab
            getSectionHeader={getSectionHeader}
            fetchData={fetchDdata}
            emptyList={branding.communicationArea.requestsTabEmpty}
            tabType={ContactEntryType.REQUEST}
            isReload
            isRequest={(groupNumber: number) => groupNumber === 0 || groupNumber === 1}
            unreadCounter={props.requests}
        />
    )
}

interface ContactsTabProps {
    contacts: number
}

const ContactsTab: React.FunctionComponent<ContactsTabProps> = (props) => {
    useEffect(() => {
        trackPageView(branding.communicationArea.contactsTabTitle, "List", "Contact")
    }, [])

    const loggedInUserId = useLoggedInState().user()?.profileId || ""

    function getSectionHeader(groupNumber: number) {
        let titleString: string = ""

        switch (groupNumber) {
            case -1: {
                titleString = branding.communicationArea.contactsTabFavoritesSectionTitle
                break
            }
            case 0: {
                titleString = branding.communicationArea.contactsTabMyPersonalContactsSectionTitle
                break
            }
            case 1: {
                titleString = branding.communicationArea.contactsTabMyOrganizationsContactsSectionTitle
                break
            }
        }
        return titleString
    }

    function fetchDdata(profileId: string, searchString: string, page: number) {
        return loadContactListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page,
            ddbCounter: props.contacts
        })
    }
    return (
        <GenericTab
            tabContainsFavorites
            getSectionHeader={getSectionHeader}
            fetchData={fetchDdata}
            emptyList={branding.communicationArea.contactsTabEmpty}
            unreadCounter={props.contacts}
            tabType={ContactEntryType.CONTACT}
            hasDownload={(groupNumber: number) => true}
            doDownloadCsv={() => exportContactsAsCsv(loggedInUserId, { forceCollaborationFeatureActive: false })}
            doDownloadVCard={() => exportContactsAsVCard(loggedInUserId, { forceCollaborationFeatureActive: false })}
            isReload
        />
    )
}

export const BlockedTab: React.FunctionComponent = () => {
    function getSectionHeader(groupNumber: number) {
        let titleString: string = ""

        switch (groupNumber) {
            case 0: {
                titleString = branding.communicationArea.blockingSectionTitle
                break
            }
            case 1: {
                titleString = branding.communicationArea.blockedSectionTitle
                break
            }
        }

        return titleString
    }

    function fetchDdata(profileId: string, searchString: string, page: number) {
        return loadContactBlockedtListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page
        })
    }

    return (
        <GenericTab
            getSectionHeader={getSectionHeader}
            fetchData={fetchDdata}
            emptyList={branding.communicationArea.blockedTabEmpty}
            unreadCounter={0}
            tabType={ContactEntryType.BLOCKED}
        />
    )
}
