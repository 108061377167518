import { PointItem, PointRoot } from "./Point.styled"

function Point() {
    return (
        <PointRoot>
            <PointItem className={"animatePoint"} />
        </PointRoot>
    )
}

export default Point
