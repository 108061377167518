import { useEffect, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { NextPageLoader } from "../../communicationArea/CommunicationArea"
import BackendError from "../../ui/BackendError"
import CenteredLoader from "../../ui/CenteredLoader"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import SpeakersTilesLayout from "../../ui/SpeakersTilesLayout"
import { Category, ModalType } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import EmptyTile from "../reception/EmptyTile"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { ContactItem, loadRelevantProfilesListData, ContactListResponse } from "../../backendServices/SeriesOfTopicsUserServices"
import InView from "react-intersection-observer"
import { Staff, StaffEntry, StaffType } from "../../ui/StaffEntry"
import { ViewMode } from "../../ui/CrsTabs"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../../globalStates/TileViewConfig"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { editMyProfilePageRoute } from "../../navigationArea/RoutePaths"

const RelevantTilesRoot = styled.div<{ reservedSpaceHeight: number }>`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: relative;

    & .absolute-center {
        left: 0;
        top: 0;
        position: absolute;
        z-index: 10;
    }

    & .ScrollbarsCustom-Content {
        position: relative;
    }
`

const InViewParent = styled.div`
    position: absolute;
    width: 100%;
    bottom: 20px;
`

const ScrollContainerRoot = styled.div`
    width: 100%;
`

const SpeakersListLayout = styled.div``

interface RelevantTilesAndListViewProps {
    relevantUsers: ContactItem[]
    setRelevantUsers?: React.Dispatch<React.SetStateAction<ContactItem[]>>
    loggedInUserId: string | undefined
    isLoader: boolean
    setIsLoader: (value: React.SetStateAction<boolean>) => void
    page: number
    setPage: (value: React.SetStateAction<number>) => void
    searchString: string
    topicFilter: number | null
    subContentHeight: number
    isMatchActive?: boolean

    setSelectedUser?: (value: any) => void
    callback?: (param: { modalType?: ModalType }) => void
    viewMode: ViewMode
    showOnlyBookmarks: boolean
}

const RelevantTilesAndListView = (props: RelevantTilesAndListViewProps) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const windowSize = useWindowDimensions()
    const pageSize = 35
    const [hasMoreData, setHasMoreData] = useState<boolean>(false)
    const [isError, setIsError] = useState("")

    let userState = useLoggedInState()
    function shouldCompleteProfile(): boolean {
        if (
            !userState.user()?.firstName ||
            !userState.user()?.lastName ||
            !userState.user()?.position ||
            !userState.user()?.company
        ) {
            return true
        }

        return false
    }

    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            props.setPage(props.page + 1)
        }
    }
    const fetchData = () => {
        if (props.loggedInUserId) {
            props.setIsLoader(true)

            let params = {
                searchString: props.searchString,
                itemsPerPage: pageSize,
                page: props.page,
                topicFilter: props.topicFilter as any,
                filterlist: props.showOnlyBookmarks ? "-1" : "0"
            }

            if (!params.topicFilter) delete params.topicFilter

            loadRelevantProfilesListData(props.loggedInUserId || "", params)
                .then((res) => {
                    const response = res as ContactListResponse
                    let contactsTemp = response.contacts?.filter(function (contact) {
                        return contact.sotUser.id !== props.loggedInUserId
                    })

                    if (props.setRelevantUsers) {
                        props.setRelevantUsers(props.page === 0 ? contactsTemp : props.relevantUsers.concat(contactsTemp))
                    }
                    setHasMoreData(response.hasNextPage)
                })
                .catch((error) => {
                    setIsError(error)
                })
                .finally(() => {
                    props.setIsLoader(false)
                })
        }
    }

    useEffect(() => {
        props.setPage(0)
    }, [props.showOnlyBookmarks]) // eslint-disable-line

    const refreshKey = [props.page, props.searchString, props.topicFilter, lang].join(", ")

    useEffect(() => {
        if (props.isMatchActive) fetchData()
        // eslint-disable-next-line
    }, [refreshKey, props.isMatchActive, props.showOnlyBookmarks])

    useEffect(() => {
        if (props.showOnlyBookmarks) props.setPage(0)
        // eslint-disable-next-line
    }, [])

    if (props.relevantUsers?.length! === 0)
        return (
            <div style={{ marginTop: "15%" }}>
                <EmptyTile header={branding.businessAreaPageContent.emptyResultText} bgColor="transparent" hideButton />
            </div>
        )

    return (
        <RelevantTilesRoot reservedSpaceHeight={props.subContentHeight}>
            {isError ? (
                <div style={{ marginTop: "25%" }}>
                    <BackendError />
                </div>
            ) : (
                <>
                    {!shouldCompleteProfile() ? (
                        <ScrollContainerRoot>
                            <div style={{ marginTop: "0" }}>
                                <ContentScrollContainer adjustForHeaderWith={`${props.subContentHeight}px`}>
                                    {props.isLoader && props.page === 0 ? (
                                        <CenteredLoader className={"absolute-center"} />
                                    ) : props.isMatchActive ? (
                                        <div
                                            style={{
                                                padding: "10px 25px",
                                                backgroundColor: branding.contentBgColorForEachPage ?? "#fff"
                                            }}
                                        >
                                            {props.viewMode === ViewMode.TILES &&
                                            windowSize.width >= getTileViewMinReqWidth(PagesUsingTileView.BUSSINES_AREA) ? (
                                                <SpeakersTilesLayout
                                                    speakersList={props.relevantUsers}
                                                    componentType="contact-item"
                                                    showContextMenuOnHover={true}
                                                    setSelectedUser={props.setSelectedUser}
                                                    callback={props.callback}
                                                />
                                            ) : (
                                                <SpeakersListLayout>
                                                    {props.relevantUsers.map((user, index) => (
                                                        <StaffEntry
                                                            key={index}
                                                            staff={
                                                                {
                                                                    firstName: user.sotUser.firstName,
                                                                    lastName: user.sotUser.lastName,
                                                                    id: user.sotUser.id,
                                                                    type: StaffType.USER,
                                                                    logoUrl: user.sotUser.logoUrl,
                                                                    position: user.sotUser.position,
                                                                    company: user.sotUser.company,
                                                                    sotUserId: user.sotUser.id,
                                                                    visible: true,
                                                                    showroomStandby: false,
                                                                    title: "",
                                                                    categories: [] as Category[],
                                                                    userType: user.sotUser.type
                                                                } as unknown as Staff
                                                            }
                                                            organizationId={""}
                                                            backOffice={false}
                                                            setModalType={undefined}
                                                            setSelectedUser={undefined}
                                                        />
                                                    ))}
                                                </SpeakersListLayout>
                                            )}

                                            {hasMoreData && (
                                                <InViewParent>
                                                    <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                                                        <NextPageLoader />
                                                    </InView>
                                                </InViewParent>
                                            )}
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: "15.4%" }}>
                                            <EmptyTile
                                                header={branding.communicationArea.activateNetworkingText}
                                                bgColor="transparent"
                                                hideButton={true}
                                            />
                                        </div>
                                    )}
                                </ContentScrollContainer>
                            </div>
                        </ScrollContainerRoot>
                    ) : (
                        <div style={{ marginTop: "15.4%" }}>
                            <EmptyTile
                                headerClickable={true}
                                headerClickableWord={branding.communicationArea.completeProfileNetworkingClickableWord}
                                header={branding.communicationArea.completeProfileNetworkingText}
                                headerLink={editMyProfilePageRoute}
                                hideButton
                                bgColor="transparent"
                            />
                        </div>
                    )}
                </>
            )}
        </RelevantTilesRoot>
    )
}

export default RelevantTilesAndListView
