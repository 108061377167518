import { SyntheticEvent, useState } from "react"
import useWindowDimensions from "../../ui/WindowDimensionsHook"

const CustomLogoImage = () => {
    const logo = process.env.PUBLIC_URL + "/branding/applicationMedia/logo.png"

    const [height, setHeight] = useState(200)
    const [width, setWidth] = useState(300)
    const { isMobile } = useWindowDimensions()

    /**
     * calculates proper dimensions for a given logo image, so it is not too big or too small for the video player and preserves original image ratio
     * @param target image element whose size is to be calculated
     * @param isPortraitFormat is the element wider than tall -> landscape format, is it taller than wide -> portrait format
     */
    function setDimensions(target: HTMLImageElement, isPortraitFormat: boolean) {
        // example corussoft logo dimensions: 600 x 116px (width x height)
        const defaultParentWidth = 375
        const defaultParentHeight = 166
        const parentWidth = target.parentElement?.clientWidth ? target.parentElement?.clientWidth : defaultParentWidth
        const parentHeight = target.parentElement?.clientHeight ? target.parentElement?.clientHeight : defaultParentHeight
        const widthSmushifier = 0.8
        const heightSmushifier = 0.6
        const mobileSmushifier = isMobile ? 0.4 : 1
        if (isPortraitFormat) {
            // portrait format logo
            const ratio = target.naturalWidth / target.naturalHeight // 600 / 116
            const customHeight = parentHeight * heightSmushifier
            const newHeight = customHeight * mobileSmushifier
            const newWidth = customHeight * ratio * mobileSmushifier
            if (newWidth > parentWidth) {
                setDimensions(target, false)
            } else {
                setWidth(newWidth)
                setHeight(newHeight)
            }
        } else {
            // landscape format logo
            const ratio = target.naturalHeight / target.naturalWidth // 116 / 600
            const customWidth = parentWidth * widthSmushifier
            const newWidth = customWidth * mobileSmushifier
            const newHeight = customWidth * ratio * mobileSmushifier
            if (newHeight > parentHeight) {
                setDimensions(target, true)
            } else {
                setWidth(newWidth)
                setHeight(newHeight)
            }
        }
    }

    const onImgLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        const targetImage = event.target as HTMLImageElement
        setDimensions(targetImage, targetImage.naturalHeight > targetImage.naturalWidth)
    }

    return (
        <img
            src={logo}
            onLoad={onImgLoad}
            className="vjs-button vjs-logo"
            alt="Logo"
            style={{
                width: width,
                height: height
            }}
        ></img>
    )
}

export default CustomLogoImage
