import moment from "moment"
import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { Col, ListGroup, Row, Tooltip } from "react-bootstrap"
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import branding, { TopSponsor } from "../../branding/branding"
import { buildDetailLink } from "../../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../../globalStates/AppState"
import { useLanguageState } from "../../globalStates/LanguageState"
import TrackingLink from "../../tracking/NavigationSource"
import { IconMenu, IconMyFair, IconMyFairBlackWhite } from "../../ui/Icons"
import LanguageToggler from "../../ui/LanguageToggle"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { homePageRoute, meetingPageRoute } from "../RoutePaths"
import useComponentVisible from "../useComponentVisible"
import { checkActiveItem, SideBarConfig, SideIconBarItemIds, SideIconBarItemKeys } from "./SIdeIconBarConfig"
import { Exhibitor } from "../../backendServices/Types"
import { orderBy } from "lodash"
import { CustomOverlayTrigger } from "../../ui/CustomOverlayTrigger"
import { loadExhibitorsData } from "../../backendServices/ExhibitorServices"
import { trackSelectContent } from "../../utils/GTMTracking"

const drawerWidth = 350

/* #region  Branding */

/* #endregion */

/* #region  SideBar global */
const SideIconBarRoot = styled.div<{ backgroundColor: string; foregroundColor: string; isMeetingPage: any | null }>`
    position: fixed;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.foregroundColor};
    border-right: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    z-index: 9999;
    height: 100%;

    & a,
    & a:hover,
    & a:link,
    & a:visited {
        color: ${(props) => (props.isMeetingPage ? branding.sideIconBar.sideIconBarMeetingTextColor : branding.mainInfoColor)};
    }

    & .ScrollbarsCustom-Track {
        z-index: 105;
    }

    & .ScrollbarsCustom-Content {
        padding: 0 !important;
    }
`
const StyledDrawer = styled.div<{ backgroundColor: string }>`
    width: ${drawerWidth}px;
    z-index: 99;
    background-color: ${(props) => props.backgroundColor};
    white-space: nowrap;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    &.drawerOpen {
        width: ${drawerWidth}px;
    }

    &.drawerClose {
        overflow-x: hidden;
        width: 80px;
    }
`

const SideBarLogo = styled.img`
    width: ${branding.sideIconBar.sideBarLogoWidth ?? "30px"};
    height: ${branding.sideIconBar.sideBarLogoHeight ?? "30px"};
    min-width: 30px;
    margin-top: 10px;
    margin-left: 25px;
    object-fit: contain;
    cursor: pointer;
`
const SideBarLogo2 = styled.img`
    min-width: 30px;
    max-width: ${branding.sideIconBar.maxWidthForSecondLogoIcon};
    margin-left: 2px;
    object-fit: contain;
    cursor: pointer;
`

const SidebarToggleIcon = styled.div`
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 100;
    color: ${branding.mainInfoColor ?? "black"};
`
/* #endregion */

/* #region  SponsorItem */
const SponsorLogo = styled.div<{ pictureUrl?: string }>`
    height: 40px;
    width: 40px;

    ${(props) =>
        props.pictureUrl
            ? `background: url("${props.pictureUrl}");`
            : `background: url('/branding/applicationMedia/rsz_navbarlogo.png');`}
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`
interface SponsorItemProps {
    sponsor: Exhibitor
    onClick: (e: any) => void
    isNavOpen: boolean
}

const SponsorGridItem: React.FunctionComponent<SponsorItemProps> = (props: SponsorItemProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)

    return (
        <div
            onClick={() => {
                trackSelectContent(props.sponsor.name, "Top", "Sponsor")
            }}
        >
            <TrackingLink
                doClick={(e: any) => props.onClick(e)}
                to={buildDetailLink(props.sponsor.id, props.sponsor.name, "organization")}
                style={{ textDecoration: "none" }}
                src="SIDEBAR"
            >
                {props.isNavOpen && (
                    <CustomOverlayTrigger
                        trigger={["focus", "click", "hover"]}
                        placement="top"
                        delay={{ show: 0, hide: 0 }}
                        overlay={
                            <Tooltip
                                id={props.sponsor.id}
                                show={!props.isNavOpen}
                                style={{ color: branding.mainInfoColor, pointerEvents: "none", fontFamily: branding.font1 }}
                            >
                                {props.sponsor.name}
                            </Tooltip>
                        }
                    >
                        <ListItemIconContainer
                            style={{
                                background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors : "#fff",
                                width: "40px",
                                height: "40px",
                                lineHeight: "40px"
                            }}
                        >
                            <SponsorLogo pictureUrl={props.sponsor.pictureURL} />
                        </ListItemIconContainer>
                    </CustomOverlayTrigger>
                )}
                {!props.isNavOpen && (
                    <ListItemIconContainer
                        style={{
                            background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors : "#fff",
                            width: "40px",
                            height: "40px",
                            lineHeight: "40px"
                        }}
                    >
                        <SponsorLogo pictureUrl={props.sponsor.pictureURL} />
                    </ListItemIconContainer>
                )}
            </TrackingLink>
        </div>
    )
}

const SponsorListItem: React.FunctionComponent<SponsorItemProps> = (props: SponsorItemProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)
    return (
        <TrackingLink
            doClick={(e: any) => {
                trackSelectContent(props.sponsor.name, "Top", "Sponsor")
                props.onClick(e)
            }}
            to={buildDetailLink(props.sponsor.id, props.sponsor.name, "organization")}
            style={{ textDecoration: "none" }}
            src="SIDEBAR"
        >
            <ListItem key="text">
                <ListItemIconContainer
                    style={{
                        background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors : "#fff",
                        width: "auto",
                        height: "auto"
                    }}
                >
                    <SponsorLogo pictureUrl={props.sponsor.pictureURL} style={{ marginTop: "12px" }} />
                </ListItemIconContainer>
                <ListItemTextContainer style={{ marginTop: "20px" }}>
                    {props.isNavOpen && <ListItemText>{props.sponsor.name}</ListItemText>}
                </ListItemTextContainer>
            </ListItem>
        </TrackingLink>
    )
}

/* #endregion */

/* #region  SponsorItemList */

const SponsorsListRoot = styled.div`
    margin-top: 30px;
    margin-left: -5px;

    @media (max-height: 900px) {
        margin-top: 2px;
    }
`

const SpecialSponsorLogoDivRoot = styled.div`
    width: 100%;
    justify-content: center;

    @media (max-height: 900px) {
        margin-top: 2px;
    }
`

const SponsorsGridRoot = styled.div<{ navigationOpen: boolean }>`
    margin-top: 30px;
    margin-left: ${(props) => (props.navigationOpen ? "22px" : "18px")};
    overflow-x: hidden;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
`

const SpecialSponsorLogoContainer = styled.div`
    width: 60px;
    height: 60px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: 15px;
`

const SpecialSponsorLogoRoot = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`

interface SpecialSponsorLogoProps {
    isNavOpen: boolean
}

const SpecialSponsorLogo: React.FunctionComponent<SpecialSponsorLogoProps> = (props: SpecialSponsorLogoProps) => {
    return (
        <>
            {props.isNavOpen && (
                <SpecialSponsorLogoDivRoot>
                    <Link to={branding.sideIconBar.specialSponsorLogoLink} onClick={(e: any) => e.stopPropagation()}>
                        <SpecialSponsorLogoContainer style={{ width: "200px", height: "200px", marginLeft: "75px" }}>
                            <SpecialSponsorLogoRoot src={branding.sideIconBar.specialSponsorLogo} alt=" " />
                        </SpecialSponsorLogoContainer>
                    </Link>
                </SpecialSponsorLogoDivRoot>
            )}
            {!props.isNavOpen && (
                <SponsorsListRoot>
                    <ListItem key="text" style={{ marginBottom: "-25px" }}></ListItem>
                    <Link to={branding.sideIconBar.specialSponsorLogoLink} onClick={(e: any) => e.stopPropagation()}>
                        <SpecialSponsorLogoContainer>
                            <SpecialSponsorLogoRoot src={branding.sideIconBar.specialSponsorLogo} alt=" " />
                        </SpecialSponsorLogoContainer>
                    </Link>
                </SponsorsListRoot>
            )}
        </>
    )
}

interface SponsorsListProps {
    items: TopSponsor[]
    title: string
    onClick: (e: any) => void
    isNavOpen: boolean
}

const SponsorsList: React.FunctionComponent<SponsorsListProps> = (props: SponsorsListProps) => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal

    const [sponsors, setSponsors] = useState<Exhibitor[]>()
    const lang = useLanguageState().getLanguage()

    useEffect(() => {
        let idList: string[] = []

        props.items.forEach((item) => {
            idList.push("id_orga_" + item.id)
        })

        let sponsorSlotParams: any = {
            filterlist: "entity_orga, " + idList.join(", "),
            startresultrow: 0,
            order: "lexic",
            lang: lang
        }

        loadExhibitorsData(sponsorSlotParams, abortSignal).then((resp) => {
            const ex = resp.entities as Exhibitor[]
            if (ex) {
                ex.forEach((exhibitor: any) => {
                    exhibitor.order = props.items.find((x) => x.id === exhibitor.id)?.order || -1
                })

                setSponsors((e) => orderBy(ex, ["order"], ["asc"]))
            }
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {sponsors && sponsors?.length! <= 3 && (
                <SponsorsListRoot>
                    <br />
                    <ListItem key="text" style={{ marginBottom: "-25px", paddingLeft: "30px" }}>
                        <div
                            style={{
                                fontFamily: branding.font1,
                                fontSize: "1rem",
                                color: "grey",
                                visibility: props.isNavOpen ? "visible" : "hidden"
                            }}
                        >
                            {props.title}
                        </div>
                    </ListItem>
                    {sponsors?.map((sponsor, index) => (
                        <SponsorListItem
                            key={index}
                            sponsor={sponsor}
                            isNavOpen={props.isNavOpen}
                            onClick={(e: any) => props.onClick(e)}
                        />
                    ))}
                </SponsorsListRoot>
            )}
            {sponsors && sponsors?.length! > 3 && (
                <SponsorsListRoot>
                    <br />
                    <ListItem key="text" style={{ marginBottom: "-25px" }}>
                        <div
                            style={{
                                fontFamily: branding.font1,
                                fontSize: "1rem",
                                color: "grey",
                                visibility: props.isNavOpen ? "visible" : "hidden",
                                marginLeft: "6px"
                            }}
                        >
                            {props.title}
                        </div>
                    </ListItem>
                    <SponsorsGridRoot navigationOpen={props.isNavOpen} style={{ backgroundColor: "transparent" }}>
                        {sponsors?.map((sponsor, index) => (
                            <SponsorGridItem
                                key={index}
                                sponsor={sponsor}
                                isNavOpen={props.isNavOpen}
                                onClick={(e: any) => props.onClick(e)}
                            />
                        ))}
                    </SponsorsGridRoot>
                </SponsorsListRoot>
            )}
        </>
    )
}
/* #endregion */

/* #region  ListItemCustom */
const ListItem = styled(ListGroup.Item)`
    display: flex;
    border: none;
    position: relative;
    min-width: 350px;
    max-width: 350px;
    max-height: 70px;
    z-index: 100;
    background-color: transparent;
    /* padding: .75rem 1.7rem !important; */

    &.drawerButtonSelected {
        //background-color: rgba(0, 0, 0, 0.1);
        background-color: ${(props) =>
            useAppState().isNavBarOpen === false
                ? branding.sideIconBar.drawerClosedButtonSelectBackgroundColor
                : branding.sideIconBar.drawerButtonSelectBackgroundColor};
    }

    &.hovered:hover {
        /* background-color: rgba(0, 0, 0, .04); */
    }
`

const ListItemText = styled.div`
    font-size: ${branding.sideIconBar.sideBarListItemFontSize ?? "16px"};
    line-height: 25px;
    max-width: 350px;
    white-space: pre-line;
    font-family: ${branding.font1};
    font-weight: ${branding.sideIconBar.listItemTextFontWeight ?? "normal"};
`

const ListItemIconContainer = styled.div<{ selected?: boolean }>`
    margin-left: 7px;
    width: 25px;
    height: 25px;
    margin-top: 0px;
    /* line-height: 25px; */
`

const ListItemTextContainer = styled.div`
    margin-left: 20px;
`
interface ListItemProps {
    onClick: (e: any) => void
    id: SideIconBarItemIds
    selected: boolean
    icon: any
    title: string
    shortName: string
    path: string
    textVisible: boolean
    isExternalLink?: boolean
    customUrl?: string
    isNavOpen?: boolean
}

const ListItemCustom: React.FunctionComponent<ListItemProps> = (props: ListItemProps) => {
    const className: string[] = ["hovered"]

    if (props.selected) className.push("drawerButtonSelected")

    let isMeetingPage = useRouteMatch(meetingPageRoute)

    const color: string = props.selected
        ? isMeetingPage
            ? branding.sideIconBar.sideIconColorLight
            : branding.sideIconBar.sideIconColorDark
        : "gray"

    const content: JSX.Element = (
        <ListItem className={className.join(" ")} key="text">
            {!props.textVisible && (
                <CustomOverlayTrigger
                    placement="right"
                    trigger={["focus", "click", "hover"]}
                    delay={{ show: 0, hide: 0 }}
                    overlay={
                        <Tooltip
                            id={props.title}
                            show={!props.textVisible}
                            style={{ color: branding.mainInfoColor, pointerEvents: "none", fontFamily: branding.font1 }}
                        >
                            {props.title}
                        </Tooltip>
                    }
                >
                    <div
                        style={{
                            display: "block",
                            flexFlow: "column",
                            maxWidth: "40px",
                            textAlign: "center",
                            float: "left",
                            fontSize: "9px",
                            color:
                                !props.isNavOpen && props.selected
                                    ? branding.navBarActiveIconColor
                                    : branding.sideIconBar.inactiveItemColor,
                            lineHeight: "10px",
                            width: "100px"
                        }}
                    >
                        <ListItemIconContainer selected={props.selected}>{props.icon}</ListItemIconContainer>
                        <div
                            style={{
                                width: "65px",
                                display: branding.sideIconBar.descriptionTextUnderIconVisible ? "inline-block" : "none",
                                marginLeft: props.id === SideIconBarItemIds.MY_SCHEDULE ? "-12px" : "-13px"
                            }}
                        >
                            {/* {" "} */}
                            <span style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all", fontFamily: branding.font1 }}>
                                {props.shortName}
                            </span>
                        </div>
                    </div>
                </CustomOverlayTrigger>
            )}
            {props.textVisible && <ListItemIconContainer selected={props.selected}>{props.icon}</ListItemIconContainer>}
            <ListItemTextContainer>
                {props.textVisible && <ListItemText style={{ color: color }}>{props.title}</ListItemText>}
            </ListItemTextContainer>
        </ListItem>
    )

    return (
        <>
            {props.isExternalLink ? (
                <a href={props.customUrl ?? ""} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    {content}
                </a>
            ) : (
                <Link to={props.path} onClick={(e: any) => props.onClick(e)} style={{ textDecoration: "none" }}>
                    {content}
                </Link>
            )}
        </>
    )
}
/* #endregion */

/* #region  BottomMenu */
const BottomMenuContainer = styled.div<{ showShadow?: boolean }>`
    position: fixed;
    padding-top: 15px;
    padding-left: 10px;
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    z-index: 100;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowBTT : "initial")};
    background-color: ${(props) => (window.location.href.includes("/meeting/") ? branding.mainInfoColor : "#fff")};

    > .open-link {
        display: flex;
        text-align: center;
        padding-bottom: 5px;
        transition: display 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    > .closed-link {
        display: none;
        padding-bottom: 5px;
        transition: display 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    > .open-language {
        width: 30px;
        text-align: center;
    }

    & #ot-sdk-btn.ot-sdk-show-settings,
    #ot-sdk-btn.optanon-show-settings {
        font-family: ${branding.font1};
        background: transparent !important;
        border: none !important;
        color: inherit !important;
        padding: 0;
        font-size: inherit;
        line-height: 18px;
    }

    & #ot-sdk-btn.ot-sdk-show-settings:hover,
    #ot-sdk-btn.optanon-show-settings:hover {
        background: transparent !important;
        color: inherit !important;
        text-decoration: underline;
    }
`

interface BottomMenuItemProps {
    isExternalLink: boolean
    link: string
    title: string
    index: number
    onClick: () => void
}

const BottomMenuItem: React.FunctionComponent<BottomMenuItemProps> = (props: BottomMenuItemProps) => {
    const hideTheDot: boolean = props.index === branding.sideIconBar.sideIconBarFooterItemsOrder.length - 1

    return (
        <div onClick={() => props.onClick()}>
            {props.isExternalLink ? (
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    {props.title}
                    <p style={{ display: hideTheDot ? "none" : "inline-block", marginLeft: "5px", marginRight: "5px" }}>•</p>
                </a>
            ) : (
                <Link to={props.link}>
                    {props.title}
                    <p style={{ display: hideTheDot ? "none" : "inline-block", marginLeft: "5px", marginRight: "5px" }}>•</p>
                </Link>
            )}
        </div>
    )
}

interface BottomMenuProps {
    isNavOpen: boolean
    foregroundColor: string
    contentAreaScrollable: boolean
    hideOnScrollEnd: boolean
}

const BottomMenu: React.FunctionComponent<BottomMenuProps> = (props: BottomMenuProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)

    useEffect(() => {
        var closeBtn = document.getElementById("close-pc-btn-handler")
        closeBtn?.addEventListener("click", () => {
            var darkOverlay = document.querySelector(".onetrust-pc-dark-filter")
            if (darkOverlay) (darkOverlay as HTMLElement).style.display = "none"
        })
    }, [document.getElementById("onetrust-pc-sdk")]) // eslint-disable-line

    return (
        <div style={{ backgroundColor: isMeetingPage ? branding.mainInfoColor : "#fff", width: "345px" }}>
            <BottomMenuContainer
                showShadow={props.contentAreaScrollable && !props.hideOnScrollEnd}
                style={{
                    display: props.isNavOpen ? "flex" : "none",
                    fontFamily: branding.font1,
                    background: isMeetingPage ? branding.mainInfoColor : "#fff",
                    width: "350px",
                    zIndex: 100
                }}
            >
                <div
                    style={{
                        position: "relative",
                        fontSize: "12px",
                        lineHeight: "18px",
                        marginLeft: "10px",
                        paddingBottom: "5px"
                    }}
                >
                    <Row>
                        <Col xs={24}>
                            <LanguageToggler
                                fullToggler={true}
                                color={isMeetingPage ? props.foregroundColor : branding.mainInfoColor}
                                fontSize="inherit"
                            />
                        </Col>
                    </Row>
                    <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px", marginLeft: "1px" }}>
                        {branding.sideIconBar.sideIconBarFooterItemsOrder.map((item, index) => {
                            switch (item) {
                                case "HELP": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.helpItem.isExternalLink}
                                            link={branding.sideIconBar.helpItem.link}
                                            title={branding.sideIconBar.helpItem.title}
                                            onClick={() =>
                                                trackSelectContent(branding.sideIconBar.helpItem.title, "Details", "Help")
                                            }
                                        />
                                    )
                                }
                                case "RULES": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.rulesItem.isExternalLink}
                                            link={branding.sideIconBar.rulesItem.link}
                                            title={branding.sideIconBar.rulesItem.title}
                                            onClick={() =>
                                                trackSelectContent(branding.sideIconBar.rulesItem.title, "Details", "Rules")
                                            }
                                        />
                                    )
                                }
                                case "TERMS_OF_USE": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.termsOfUseItem.isExternalLink}
                                            link={branding.sideIconBar.termsOfUseItem.link}
                                            title={branding.sideIconBar.termsOfUseItem.title}
                                            onClick={() =>
                                                trackSelectContent(
                                                    branding.sideIconBar.termsOfUseItem.title,
                                                    "Details",
                                                    "TermsOfUse"
                                                )
                                            }
                                        />
                                    )
                                }
                                case "DATA_PRIVACY_POLICY": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.dataPrivacyPolicyItem.isExternalLink}
                                            link={branding.sideIconBar.dataPrivacyPolicyItem.link}
                                            title={branding.sideIconBar.dataPrivacyPolicyItem.title}
                                            onClick={() =>
                                                trackSelectContent(
                                                    branding.sideIconBar.dataPrivacyPolicyItem.title,
                                                    "Details",
                                                    "PrivacyPolicy"
                                                )
                                            }
                                        />
                                    )
                                }
                                case "IMPRINT": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.imprintItem.isExternalLink}
                                            link={branding.sideIconBar.imprintItem.link}
                                            title={branding.sideIconBar.imprintItem.title}
                                            onClick={() =>
                                                trackSelectContent(branding.sideIconBar.imprintItem.title, "Details", "Imprint")
                                            }
                                        />
                                    )
                                }
                                case "COOKIE_SETTINGS": {
                                    return (
                                        branding.cookieOneTrustScript &&
                                        branding.cookieOneTrustScript !== "" && (
                                            <Row key={index}>
                                                <Col xs={12}>
                                                    <div
                                                        id="ot-sdk-btn"
                                                        className="ot-sdk-show-settings"
                                                        onClick={() =>
                                                            trackSelectContent(
                                                                branding.sideIconBar.cookieSettingsTitle,
                                                                "Details",
                                                                "CookieSettings"
                                                            )
                                                        }
                                                    >
                                                        {branding.sideIconBar.cookieSettingsTitle}
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                }
                                default:
                                    return null
                            }
                        })}
                    </div>
                </div>
            </BottomMenuContainer>
            {!props.isNavOpen && (
                <BottomMenuContainer
                    showShadow={props.contentAreaScrollable && !props.hideOnScrollEnd}
                    style={{ width: "80px", height: "60px" }}
                >
                    <div
                        style={{
                            background: isMeetingPage ? branding.mainInfoColor : "#fff",
                            position: "relative",
                            fontSize: "15px",
                            lineHeight: "20px",
                            marginTop: "75px",
                            alignItems: "center",
                            width: "60px",
                            bottom: 0,
                            paddingBottom: "10px"
                        }}
                    >
                        <LanguageToggler
                            color={isMeetingPage ? props.foregroundColor : branding.mainInfoColor}
                            fontSize="inherit"
                            margin="0 1.2rem 5px 1.2rem"
                        />
                    </div>
                </BottomMenuContainer>
            )}
        </div>
    )
}
/* #endregion */

const WrapperContainer = styled.div<{ isNavOpen: boolean }>`
    @media (max-height: 1300px) {
        padding-bottom: ${(props) => (props.isNavOpen ? "10px" : "0")};
    }
`

/* #region  MenuDrawerLayout */

export function getCurrentItem(location: string, receptionPageToggle: (value: string) => void): string {
    if (location.endsWith("sponsor")) return "sponsor"
    if (location.endsWith("news")) return "news"
    else if (location.includes("channel")) return "program"
    else if (
        location.endsWith("myprofile") ||
        location.endsWith("myspeakers") ||
        location.endsWith("myexhibitors") ||
        location.endsWith("myproducts") ||
        location.endsWith("mytrademarks") ||
        location.endsWith("mynews") ||
        ((location.includes("person") || location.includes("user")) && location.includes("meeting") === false)
    ) {
        receptionPageToggle("MY_FAIR")
        return "home"
    } else if (
        location.endsWith("showfloor") ||
        location.includes("company") ||
        location.includes("product") ||
        location.includes("trademark") ||
        location.includes("news") ||
        location.includes("joboffer")
    )
        return "organization"
    else if (location.endsWith("coupon")) return "coupon"
    else if (location.includes("meetings") || location.includes("meeting")) return "meetings"
    else if (location.includes("collections")) return "collections"
    else if (location.endsWith("press") || location.includes("mediapartner")) return "press_media"
    else if (location.endsWith("business")) return "business"
    else if (location.includes("iframetest")) return "iframe_test"
    else if (location.endsWith("help")) return "help"
    else if (location.endsWith("startup")) return "startup"
    else if (location.includes("hallplan")) return "hallplan"
    else if (location.endsWith("myschedule")) {
        return "my_schedule"
    } else if (
        location.endsWith("help") ||
        location.endsWith("rules") ||
        location.endsWith("info") ||
        location.endsWith("termsofuse")
    ) {
        receptionPageToggle("LOBBY")
        return "home"
    } else if (
        location.includes("eventdate") ||
        location.includes("event") ||
        location.endsWith("program") ||
        location.includes("channel")
    )
        return "program"
    else if (location.includes("results")) return ""
    // to deselect all side icon bar items when user is on global search page
    else return "home"
}
interface MenuDrawerLayoutProps {
    isNavOpen: boolean
    receptionPageToggle: (route: string) => void
    myToggle: string
    myIcon: any
    logo: any
    setTrigger: (trigger: number) => void
    contentAreaScrollable: boolean
    hideOnScrollEnd: boolean
}
const MenuDrawerLayout: React.FunctionComponent<MenuDrawerLayoutProps> = (props: MenuDrawerLayoutProps) => {
    let checkIsNavOpen = props.isNavOpen ? true : false
    let isMeetingPage = useRouteMatch(meetingPageRoute)

    const location = useLocation().pathname.substring(1)
    const appState = useAppState()

    useEffect(() => {
        if (
            !(
                (location.includes("person") || location.includes("user")) &&
                (appState.currentItem === "program" || appState.currentItem === "organization")
            )
        ) {
            appState.setCurrentItem(getCurrentItem(location, props.receptionPageToggle))
        }
        // eslint-disable-next-line
    }, [location])

    function updateCurrentItem(item: string, receptionPageToggle: string) {
        appState.setCurrentItem(item)

        props.receptionPageToggle(receptionPageToggle)

        if (receptionPageToggle.length > 0) {
            props.setTrigger(moment().valueOf())
        }
    }

    const foregroundColor = isMeetingPage ? "#fff" : branding.primaryColor

    const sideBarItems = SideBarConfig(appState.currentItem, isMeetingPage ? true : false, props.myToggle, checkIsNavOpen, false)

    return (
        <>
            <WrapperContainer isNavOpen={props.isNavOpen}>
                <ListGroup style={{ lineHeight: "18px" }}>
                    <br />
                    {/* sidebar navigation items */}
                    {branding.sideIconBar.sideIconBarItemsOrder.map((item) => {
                        const sideBarItem = sideBarItems.filter((x) => x.id === item)[0]
                        if (sideBarItem) {
                            const selected = checkActiveItem(
                                sideBarItem.key,
                                sideBarItem.lobbyCheck ?? false,
                                sideBarItem.checkString ?? "",
                                appState.currentItem,
                                props.myToggle,
                                false
                            )

                            const updateCurrentItemParam = sideBarItem.lobbyCheck ? sideBarItem.checkString! : ""

                            return (
                                <ListItemCustom
                                    key={sideBarItem.id}
                                    id={sideBarItem.id}
                                    path={sideBarItem.path}
                                    icon={sideBarItem.icon}
                                    textVisible={props.isNavOpen}
                                    isNavOpen={props.isNavOpen}
                                    title={sideBarItem.title}
                                    shortName={sideBarItem.shortName}
                                    selected={selected}
                                    isExternalLink={sideBarItem.isExternalLink}
                                    customUrl={sideBarItem.customUrl}
                                    onClick={(e: any) => {
                                        // reset the last visited tab
                                        if (appState) {
                                            appState.setLastVisitedTab(null)
                                        }

                                        if (!sideBarItem.isExternalLink) {
                                            updateCurrentItem(sideBarItem.key, updateCurrentItemParam)
                                        }

                                        trackSelectContent(sideBarItem.title, "Navigation", "Navigation")

                                        e.stopPropagation()
                                    }}
                                />
                            )
                        }

                        return null
                    })}

                    {/* top sponsors */}
                    {branding.sideIconBar.topSponsorsVisible && (
                        <SponsorsList
                            title={branding.sideIconBar.topSponsorsText}
                            items={branding.topSponsors}
                            isNavOpen={props.isNavOpen}
                            onClick={(e: any) => {
                                updateCurrentItem(SideIconBarItemKeys.organization, "")

                                e.stopPropagation()
                            }}
                        />
                    )}

                    {/* idea sponsors */}
                    {branding.sideIconBar.ideaSponsorsVisible && (
                        <SponsorsList
                            title={branding.sideIconBar.ideaSponsorsText}
                            items={branding.ideaSponsors}
                            isNavOpen={props.isNavOpen}
                            onClick={(e: any) => {
                                updateCurrentItem(SideIconBarItemKeys.organization, "")

                                e.stopPropagation()
                            }}
                        />
                    )}

                    {/* special sponsor's logo */}
                    {branding.sideIconBar.specialSponsorLogoVisible && <SpecialSponsorLogo isNavOpen={props.isNavOpen} />}
                </ListGroup>

                {/* bottom menu (language switcher, help, rules, imprint, terms of use ) */}
                <BottomMenu
                    isNavOpen={props.isNavOpen}
                    foregroundColor={foregroundColor}
                    contentAreaScrollable={props.contentAreaScrollable}
                    hideOnScrollEnd={props.hideOnScrollEnd}
                />
            </WrapperContainer>
        </>
    )
}
/* #endregion */

/* #region  MenuToolbarLayout  */
const MenuToolbarLayoutRoot = styled.div<{ showShadow?: boolean; hideTopShadow?: boolean; isNavOpen?: boolean }>`
    justify-content: space-between;
    display: flex;
    height: 50px;
    padding-top: 13px;
    min-height: 60px;
    padding-left: 24px;
    padding-right: 12px;
    box-shadow: ${(props) => (props.showShadow ? "0px -1px 6px 0px rgba(0, 0, 0, 0.16)" : "none")};
`

interface MenuToolbarLayoutProps {
    isNavOpen: boolean
    isMeetingPage: any
    logo: any
    handleDrawerOpen: () => void
    handleDrawerClose: () => void
    contentAreaScrollable?: boolean
    hideOnScrollEnd?: boolean
}

const MenuToolbarLayout: React.FunctionComponent<MenuToolbarLayoutProps> = (props: MenuToolbarLayoutProps) => {
    const history = useHistory()
    return (
        <>
            <MenuToolbarLayoutRoot
                showShadow={props.isNavOpen}
                style={{
                    zIndex: 100,
                    position: "relative",
                    backgroundColor: window.location.href.includes("/meeting/") ? "transparent" : "#fff"
                }}
            >
                {props.isNavOpen ? (
                    <>
                        {branding.sideIconBar.hasSecondLogoIcon ? (
                            <SideBarLogo2
                                src={props.logo}
                                onClick={(e: any) => {
                                    history.push(homePageRoute)
                                }}
                            />
                        ) : (
                            <SideBarLogo
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    props.handleDrawerClose()
                                    history.push(homePageRoute)
                                }}
                                src={props.logo}
                                style={{ margin: 0, marginLeft: 2 }}
                            />
                        )}
                    </>
                ) : (
                    <SideBarLogo
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            props.handleDrawerClose()
                            history.push(homePageRoute)
                        }}
                        src={props.logo}
                        style={{ margin: 0, marginLeft: 2 }}
                    />
                )}
                <div style={{ visibility: props.isNavOpen ? "visible" : "hidden" }} onClick={() => props.handleDrawerClose()}>
                    <CustomOverlayTrigger
                        placement="right"
                        trigger={["focus", "click", "hover"]}
                        delay={{ show: 0, hide: 0 }}
                        overlay={
                            <Tooltip id="menu-tooltip-open" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                {branding.sideIconBar.menuTooltipTextnavBarOpen}
                            </Tooltip>
                        }
                    >
                        <SidebarToggleIcon>
                            {IconMenu({
                                fill: props.isMeetingPage
                                    ? branding.sideIconBar.sideIconColorLight ?? "#fff"
                                    : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                            })}
                        </SidebarToggleIcon>
                    </CustomOverlayTrigger>
                </div>
            </MenuToolbarLayoutRoot>
            {!props.isNavOpen && (
                <MenuToolbarLayoutRoot
                    style={{ marginTop: "-1px" }}
                    showShadow={props.contentAreaScrollable && !props.hideOnScrollEnd}
                    hideTopShadow={true}
                    isNavOpen={props.isNavOpen}
                >
                    <CustomOverlayTrigger
                        placement="right"
                        trigger={["focus", "click", "hover"]}
                        delay={{ show: 0, hide: 0 }}
                        overlay={
                            <Tooltip id="menu-tooltip-closed" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                {branding.sideIconBar.menuTooltipTextnavBarClosed}
                            </Tooltip>
                        }
                    >
                        <SidebarToggleIcon onClick={props.handleDrawerOpen} style={{ margin: 0, marginLeft: "6px" }}>
                            {IconMenu({
                                fill: props.isMeetingPage
                                    ? branding.sideIconBar.sideIconColorLight ?? "#fff"
                                    : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                            })}
                        </SidebarToggleIcon>
                    </CustomOverlayTrigger>
                </MenuToolbarLayoutRoot>
            )}
        </>
    )
}
/* #endregion */

/* #region  Main content */
interface SideIconBarProps {
    navToggle: (open: boolean) => void
    isNavOpen: boolean
    receptionPageToggle: (route: string) => void
    setTrigger: (trigger: number) => void
    myToggle: string
}

const SideIconBar: React.FC<SideIconBarProps> = (props) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)
    const foregroundColor = isMeetingPage ? "#fff" : branding.primaryColor
    const backgroundColor = isMeetingPage ? branding.mainInfoColor : "#fff"
    const logo = isMeetingPage
        ? "/branding/applicationMedia/navbarLogoWhite.png"
        : "/branding/applicationMedia/rsz_navbarlogo.png"
    const logo_second = isMeetingPage
        ? "/branding/applicationMedia/navbarLogoWhite2.png"
        : "/branding/applicationMedia/rsz_second_logo.png"
    const myIcon = isMeetingPage ? IconMyFairBlackWhite({ stroke: "white" }) : IconMyFair({ fill: "#fff" })

    const [contentAreaScrollable, setContentAreaScrollable] = useState(false)
    const [hideOnScrollEnd, setHideOnScrollEnd] = useState(false)
    const scrollRef = useRef<HTMLDivElement>(null)
    const appState = useAppState()
    const onScroll = (scrollValues: any) => {
        const scrollHeight = scrollValues.contentScrollHeight
        const clientHeight = scrollValues.clientHeight
        const scrollTop = scrollValues.scrollTop

        if (scrollHeight < scrollTop) {
            setHideOnScrollEnd(scrollHeight <= clientHeight + scrollTop)
        }
    }

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    const handleDrawerOpen = () => {
        appState.setIsNavBarOpen(true)
        props.navToggle(true)
        setIsComponentVisible(true)
        if (scrollRef && scrollRef.current)
            setContentAreaScrollable(scrollRef.current.scrollHeight > scrollRef.current.clientHeight)
    }

    const handleDrawerClose = () => {
        appState.setIsNavBarOpen(false)
        props.navToggle(false)
        setIsComponentVisible(false)
    }

    useEffect(() => {
        if (isComponentVisible) {
            handleDrawerOpen()
        } else {
            handleDrawerClose()
        }

        // eslint-disable-next-line
    }, [isComponentVisible])

    return (
        <SideIconBarRoot
            ref={scrollRef}
            foregroundColor={foregroundColor}
            backgroundColor={backgroundColor}
            isMeetingPage={isMeetingPage}
        >
            <div className="d-flex">
                <StyledDrawer
                    ref={ref}
                    onClick={() => {
                        !props.isNavOpen ? handleDrawerOpen() : handleDrawerClose()
                    }}
                    backgroundColor={backgroundColor}
                    className={props.isNavOpen ? "drawerOpen" : "drawerClose"}
                >
                    <MenuToolbarLayout
                        contentAreaScrollable={contentAreaScrollable}
                        hideOnScrollEnd={hideOnScrollEnd}
                        isNavOpen={props.isNavOpen}
                        isMeetingPage={isMeetingPage}
                        logo={props.isNavOpen && branding.sideIconBar.hasSecondLogoIcon ? logo_second : logo}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                    />

                    <ContentScrollContainer
                        handleScroll={onScroll}
                        isVerticallyScrollable={setContentAreaScrollable}
                        adjustForHeaderWith={props.isNavOpen ? "135px" : "175px"}
                    >
                        <MenuDrawerLayout
                            isNavOpen={props.isNavOpen}
                            logo={logo}
                            receptionPageToggle={props.receptionPageToggle}
                            myToggle={props.myToggle}
                            myIcon={myIcon}
                            setTrigger={props.setTrigger}
                            contentAreaScrollable={contentAreaScrollable}
                            hideOnScrollEnd={hideOnScrollEnd}
                        />
                    </ContentScrollContainer>
                </StyledDrawer>
            </div>
        </SideIconBarRoot>
    )
}
/* #endregion */

export default SideIconBar
