import { useLocalAudioInputActivityPreview } from "amazon-chime-sdk-component-library-react"
import { useEffect, useRef, useState } from "react"
import branding from "../../../branding/branding"
import { IconMicrophoneOff } from "../../../ui/Icons"
import { useDeviceController } from "../../context/DeviceController"
import ActivityBar from "../ActivityBar"
import MicSelection from "../MicSelection/MicSelection"
import SecondaryButton from "../Shared/SecondaryButton"
import SpeakerSelection from "../SpeakerSelection/SpeakerSelection"
import {
    DevicePermissionMessage,
    DevicePermissionMessageContainer,
    DeviceSettingsRoot,
    SettingsGroup,
    TestSpeakerButtonContainer
} from "./AudioSettings.styled"

function AudioSettings() {
    const activityBarRef = useRef<HTMLDivElement>()
    useLocalAudioInputActivityPreview(activityBarRef)
    const { testCurrentAudioOutputDevice } = useDeviceController()
    const deviceController = useDeviceController()
    const [hasMicrophonePermission, setHasMicrophonePermission] = useState(true)
    useEffect(() => {
        deviceController.loadDevices()
        //eslint-disable-next-line
    }, [deviceController.loadDevices()])

    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(function (stream) {
                setHasMicrophonePermission(true)
            })
            .catch(function (err) {
                console.log(err, " Microphone usage is permitted")
                setHasMicrophonePermission(false)
            })
        // eslint-disable-next-line
    }, [navigator.mediaDevices])

    return (
        <>
            {hasMicrophonePermission ? (
                <DeviceSettingsRoot>
                    <SettingsGroup>
                        <MicSelection />
                        <ActivityBar ref={activityBarRef} />
                    </SettingsGroup>
                    <SettingsGroup>
                        <SpeakerSelection />
                        <TestSpeakerButtonContainer>
                            <SecondaryButton
                                onClickHandler={testCurrentAudioOutputDevice}
                                text={branding.audioVideoSettings.testSpeakerLabel}
                            />
                        </TestSpeakerButtonContainer>
                    </SettingsGroup>
                </DeviceSettingsRoot>
            ) : (
                <DeviceSettingsRoot>
                    <DevicePermissionMessageContainer>
                        <DevicePermissionMessage>
                            <IconMicrophoneOff fill="#fff" width="25px" height="25px" />
                        </DevicePermissionMessage>
                        <DevicePermissionMessage>
                            {branding.communicationAreaAudioVideoSettingsAudioPermissionMessage}
                        </DevicePermissionMessage>
                    </DevicePermissionMessageContainer>
                </DeviceSettingsRoot>
            )}
        </>
    )
}

export default AudioSettings
