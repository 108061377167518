import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { useLoggedInState, UserOrganization } from "../../../../globalStates/LoggedInUser"
import { useVideoContext } from "../../../context/VideoContext"

import branding from "../../../../branding/branding"
import { PreviewOverlay } from "../../PreviewVideo"
import { LogoUpload } from "../LogoUpload"
import { LogoCompany } from "./LogoCompany"

type LogoType = "none" | "company" | "upload"

const TypeWrapper = styled.div`
    max-height: 300px;
`

export const LogoTitle = styled.div`
    font-size: 14px;
    line-height: 12px;
    font-weight: 700;
    color: #727272;
    font-family: ${branding.font1};
    margin-bottom: 0.5em;
`

const LogoActions = styled(Row)``

const LogoActionItemRoot = styled.div<{ actionType?: LogoType }>`
    background-color: #c4c4c4;
    border-radius: 5px;
    height: 87px;
    width: 100%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30%;
    border: 1px solid #c4c4c4;
    position: relative;

    ${(props) => {
        if (props.actionType === "none") {
            return `background-image: url('/branding/applicationMedia/no-logo.png')`
        } else if (props.actionType === "upload") {
            return `background-image: url('/branding/applicationMedia/upload-logo.png')`
        }
    }}
`

interface LogoActionItemProps {
    type: LogoType
    organizations?: UserOrganization[]
    onImageChanged: (src: string | null) => void
}

export function LogoActionItem({ type, organizations, onImageChanged }: LogoActionItemProps) {
    function handleClick() {
        if (type === "none") {
            onImageChanged(null)
        }
    }

    let overlayText = ""
    switch (type) {
        case "company":
            overlayText = branding.audioVideoSettings.radioCompanyLogo
            break
        case "upload":
            overlayText = branding.audioVideoSettings.radioChooseLogo
            break
        default:
            overlayText = branding.audioVideoSettings.radioNoLogo
    }

    return (
        <>
            <LogoActionItemRoot actionType={type} onClick={handleClick}>
                {type === "company" && <LogoCompany organizations={organizations} />}
                {type === "upload" && <LogoUpload onImageChanged={onImageChanged} style={{ width: "100%", height: "100%" }} />}
                <PreviewOverlay>
                    <span>{overlayText}</span>
                </PreviewOverlay>
            </LogoActionItemRoot>
        </>
    )
}

export function LogoSettingsType() {
    const videoContext = useVideoContext()
    const loggedInUser = useLoggedInState().user()

    const organizations = loggedInUser?.organizations?.filter((orga) => !!orga.logo)
    const types: LogoType[] = []
    types.push("none")
    if (organizations && organizations!.length) types.push("company")
    types.push("upload")
    return (
        <TypeWrapper>
            <LogoTitle>{branding.audioVideoSettings.radioTitleLogoType}</LogoTitle>

            <div style={{ height: "210px", overflowY: "scroll", position: "relative" }}>
                <Container>
                    <LogoActions>
                        {types.map((type) => {
                            return (
                                <Col className={"p-1"} md={6} key={type}>
                                    <LogoActionItem
                                        organizations={organizations}
                                        onImageChanged={videoContext.setLogoSrc}
                                        type={type}
                                    />
                                </Col>
                            )
                        })}
                    </LogoActions>
                </Container>
            </div>
        </TypeWrapper>
    )
}
