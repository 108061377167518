import styled from "styled-components"
import branding from "../../../branding/branding"

export const SecondaryButtonRoot = styled.div`
    font-family: ${branding.font1};
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    padding: 8px 30px;
    transition: 0.3s;
    border: 1px solid ${branding.conferenceRoomV2Theme.secondaryButtonBorderColor};
    background-color: ${branding.conferenceRoomV2Theme.secondaryButtonBackgroundColor};
    color: ${branding.conferenceRoomV2Theme.secondaryButtonTextColor};

    :hover {
        border: 1px solid ${branding.conferenceRoomV2Theme.secondaryButtonBorderHoverColor};
        background-color: ${branding.conferenceRoomV2Theme.secondaryButtonBackgroundHoverColor};
        color: ${branding.conferenceRoomV2Theme.secondaryButtonTextHoverColor};
    }
`
