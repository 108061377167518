import { Severity } from "amazon-chime-sdk-component-library-react"
import styled from "styled-components"
import branding from "../../../branding/branding"

const NotificationColors = {
    error: "#B5321A",
    success: "#067000",
    info: "#727272",
    warning: "#FFDD55"
}

export const StageNotificationsRoot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50;
    left: 0;
    z-index: 20;
    font-family: ${branding.font1};
`

export const StageNotificationRoot = styled.div<{ severity: Severity | undefined }>`
    width: 100%;
    background-color: ${(props) => (props.severity ? NotificationColors[props.severity] : NotificationColors.warning)};
    color: ${(props) => (props.severity === "warning" ? "#000" : "#fff")};
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 16px;
`

export const StageNotificationContent = styled.div``

export const StageNotificationDismissButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 20px;
`
export const StageNotificationIconWrapper = styled.div`
    margin-right: 15px;
    display: inline;
`
