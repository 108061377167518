import { useState } from "react"
import { LiveStatus } from "../../../enums/LiveStatus"
import GoLiveButton from "./GoLiveButton/GoLiveButton"
import LockRoomButton from "./LockRoomButton/LockRoomButton"
import { ModeratorButtons, ModeratorControlsRoot } from "./ModeratorControls.styled"

function ModeratorControls() {
    const [liveStatus, setLiveStatus] = useState(LiveStatus.UNKNOWN)

    const updateLiveStatus = (status: LiveStatus) => {
        setLiveStatus(status)
    }

    return (
        <ModeratorControlsRoot>
            <ModeratorButtons>
                <LockRoomButton onLiveStatusUpdate={updateLiveStatus} />
                <GoLiveButton liveStatus={liveStatus} />
            </ModeratorButtons>
        </ModeratorControlsRoot>
    )
}

export default ModeratorControls
