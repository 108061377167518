import * as React from "react"
import styled from "styled-components"
import { BasisPremiumConfig, BasisPremiumType, TileSize } from "../../branding/BasisPremiumBranding"
import { SimpleOrganization } from "../ExhibitorsPageContent"
import branding from "../../branding/branding"
import EntityAction from "./EntityAction"
import { Entity, ShareTargetType } from "../../backendServices/Types"
import { trackVisit } from "../../backendServices/TrackingServices"
import { hasAccessToOrganization } from "../../utils/UserUtils"
import { isExplorationOrPostEventPhase } from "../../utils/EventPhaseChecker"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { buildDetailLink } from "../detailPages/DetailNavLink"
import { getActionsConfig, ActionType, LayoutType, getShareTargetType } from "./actionsUtils"
import { CalendarEntryModalViewMode } from "../../ui/modals/CalendarEntryModal"
import { useCalendarEntryModal2 } from "../../ui/modals/calendarEntry/CalendarEntryModal2"
import { useGuestModal } from "../../ui/modals/GuestModal"
import { usePrivacyPolicyModal } from "../../ui/modals/PrivacyPolicyModal"
import { useRecommendModal } from "../../ui/modals/RecommendModal"
import { useSayHelloModal } from "../../ui/modals/SayHelloModal"
import { useFavoriteState } from "../../globalStates/Favorites"
import { loadExhibitorData } from "../../backendServices/ExhibitorServices"
import { joinShowroom, joinShowRoom2 } from "../detailPages/OrganizationDetailPageContent"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { useMeetingContext } from "../../conference/context/MeetingContext"
import { useAppState } from "../../globalStates/AppState"
import { useMeetingInvitation } from "../../conferenceV2/context/MeetingInvitation"
import { usePresenceState } from "../../ui/PresenceIndicator"
import { useHistory } from "react-router-dom"

const ActionsRoot = styled.div<{ width?: string; tileSize?: TileSize; layout?: LayoutType }>`
    width: ${(props) => props.width};
    display: ${(props) => (props.layout === "lobby-layout" ? "flex" : "")};
    justify-content: ${(props) => (props.layout === "lobby-layout" ? "flex-end" : "")};

    @media (max-width: 2000px) {
        width: ${(props) => (props.tileSize === TileSize.SMALL ? "80px" : props.width)};
    }

    .tile-layout {
        display: flex;
        align-items: flex-end;
        justify-content: ${(props) => (props.tileSize === TileSize.SMALL ? "space-between" : "flex-end")};
        box-sizing: border-box;
    }

    .lobby-layout {
        display: flex;
        padding: 0 10px;
    }

    .list-layout {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        align-self: center;
    }
`

interface ActionsWrapperProps {
    layout: LayoutType
    organization: SimpleOrganization | undefined
    entity: Entity
    basisPremiumType: BasisPremiumType | undefined
    exhibitorHasContacts: boolean | undefined
    basisPremiumConfig?: BasisPremiumConfig
    disableFavorites?: boolean
    hideActions?: boolean
}

const ActionsWrapper: React.FC<ActionsWrapperProps> = (props) => {
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId
    const favorites = useFavoriteState()
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const appState = useAppState()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const { showRecommendModal, RecommendModal } = useRecommendModal()
    const { showSayHelloModal, SayHelloModal } = useSayHelloModal()
    const { showCalendarEntryModal, CalendarModal } = useCalendarEntryModal2()
    const { showGuestModal, GuestModal } = useGuestModal()

    // chime v2 hooks
    const meetingInvitation = useMeetingInvitation()
    const presenceContext = usePresenceState()
    const history = useHistory()

    const strokeColor =
        props.basisPremiumConfig && props.basisPremiumConfig.tileBackgroundImageVisible
            ? "white"
            : branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000"
    const width = props.basisPremiumConfig && props.basisPremiumConfig.tileSize === TileSize.SMALL ? "100%" : "160px"

    const isBookmarked = favorites.is(props.entity.entityType, props.entity.id)

    // getting config for actions
    const config = getActionsConfig(
        props.layout,
        props.entity.entityType,
        props.exhibitorHasContacts,
        props.disableFavorites,
        props.basisPremiumType,
        strokeColor,
        strokeColor,
        isBookmarked
    )

    const onAction = (action: ActionType, organization: SimpleOrganization | undefined) => {
        if (loggedInUserId) {
            if (organization) {
                if (!hasAccessToOrganization(userLink.user()!, organization.id)) {
                    showGuestModal() // TODO -> check if it exists
                    return
                }
                switch (action) {
                    case "EXPO":
                        if (appState.isOnExhibitorsPageContentOrGlobalSearchResultPageContent) {
                            showPrivacyPolicyModal(organization, () => {
                                loadExhibitorData({
                                    organizationid: organization.id,
                                    hideNewsdata: true
                                }).then((resp) => {
                                    if (branding.configuration.useConferenceRoomV2) {
                                        joinShowRoom2(resp.content, userLink.user()!, meetingInvitation, presenceContext, history)
                                    } else {
                                        joinShowroom(resp.content, userLink.user()!, chime, meeting)
                                    }
                                })
                            })
                        } else if (!isExplorationOrPostEventPhase) {
                            trackVisit(loggedInUserId, organization.id, "EXPO")
                            showPrivacyPolicyModal(organization)
                        }
                        break
                    case "MEETING":
                        trackVisit(loggedInUserId, organization.id, "CALENDARENTRY#CLICK")
                        showPrivacyPolicyModal(organization, () => {
                            showCalendarEntryModal({
                                organizationId: organization.id,
                                organizationName: organization.name,
                                viewMode: CalendarEntryModalViewMode.CREATE
                            })
                        })
                        break
                    case "INTEREST":
                        trackVisit(loggedInUserId, organization.id, "INTEREST#CLICK")
                        showPrivacyPolicyModal(organization, () => {
                            showSayHelloModal({
                                organizationModal: true,
                                targetId: organization.id,
                                organizationName: organization.name
                            })
                        })
                        break
                }
            }
            // recommend action for joboffer and organization
            if (action === "RECOMMEND") {
                trackVisit(loggedInUserId, props.entity.id, "RECOMMENDATION#CLICK")
                showRecommendModal(
                    getShareTargetType(props.entity.entityType) || ShareTargetType.ORGANIZATION,
                    props.entity.id,
                    props.entity.name ?? "",
                    buildDetailLink(props.entity.id, props.entity.name ?? "", props.entity.entityType)
                )
            }
        }
    }

    return (
        <>
            <ActionsRoot width={width} tileSize={props.basisPremiumConfig?.tileSize} layout={props.layout}>
                <div className={props.layout}>
                    {config.map((item, index) => {
                        return (
                            item.displayCondition && (
                                <EntityAction
                                    key={index}
                                    layout={props.layout}
                                    tooltip={item.tooltip}
                                    action={item.actionType}
                                    icon={item.icon && item.icon[props.layout]}
                                    entity={props.entity}
                                    organization={props.organization}
                                    onAction={onAction}
                                    basisPremiumConfig={props.basisPremiumConfig}
                                    strokeColor={item.strokeColor}
                                    hideAction={
                                        (props.hideActions && item.actionType !== "BOOKMARK") || item.actionType === "EXPO"
                                    }
                                />
                            )
                        )
                    })}
                </div>
            </ActionsRoot>
            <GuestModal />
            <CalendarModal />
            <PrivacyModal />
            <RecommendModal />
            <SayHelloModal />
        </>
    )
}

export default ActionsWrapper
