import { useState, useEffect } from "react"
import * as React from "react"
import styled from "styled-components"
import { Company, ShareTargetType } from "../backendServices/Types"
import { syncFavorites } from "../backendServices/FavoritesServices"
import CenteredLoader from "../ui/CenteredLoader"
import { DetailNavLink, buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import branding from "../branding/branding"
import ContextMenu, { HoverButton, MenuItemProps } from "../ui/ContextMenu"
import {
    IconCameraJoin,
    IconMeetingSchedule,
    IconShare,
    IconBookmarkToggle,
    IconBookmarkFilled,
    IconDownloadVCard
} from "../ui/Icons"
import { useLanguageState } from "../globalStates/LanguageState"
import { useFavoriteState } from "../globalStates/Favorites"
import { CalendarEntryModalViewMode } from "../ui/modals/CalendarEntryModal"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useChimeContext } from "../conference/context/ChimeContext"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { joinShowroom, joinShowRoom2 } from "../contentArea/detailPages/OrganizationDetailPageContent"
import { useRecommendModal } from "../ui/modals/RecommendModal"
import moment from "moment"
import { isExplorationOrPostEventPhase, isPostEventPhase } from "../utils/EventPhaseChecker"
import { Divider } from "./ContactEntry"
import { saveExhibitorVCard } from "./VCard"
import { hasAccessToOrganization } from "../utils/UserUtils"
import { getIamPartOf } from "../globalStates/IAmPartOf"
import queryString from "query-string"
import { loadExhibitorData } from "../backendServices/ExhibitorServices"
import { useMeetingInvitation } from "../conferenceV2/context/MeetingInvitation"
import { usePresenceState } from "../ui/PresenceIndicator"
import { useHistory } from "react-router-dom"
import CalendarEntryModal2 from "../ui/modals/calendarEntry/CalendarEntryModal2"
import { trackSelectContent, trackTakeAction } from "../utils/GTMTracking"

export interface OrganizationEntryBranding {}

const OrganizationEntryRoot = styled.div``

const HoverRoot = styled.div`
    &:hover {
        background-color: rgba(240, 240, 240, 1);
    }
`

const OrganizationEntryContainer = styled.div`
    padding-left: 15px;
    padding-right: 10px;
`

const OrganizationDataRoot = styled(DetailNavLink)`
    display: flex;
    color: ${branding.mainInfoColor ?? "black"};
`

const OrganizationName = styled.div`
    width: 100%;
    margin-top: 25px;
    margin-left: 10px;
    font-family: ${branding.font1};
    font-weight: bold;
    font-size: 0.9375rem; /** 15px */
`

const OrganizationLogoDiv = styled.div`
    width: 60px;
    height: 60px;
    background: white;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
`

const OrganizationLogo = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`

const AvatarContainer = styled.div`
    margin-top: 7px;
    margin-bottom: 8px;
`

const ContextButtonContainer = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`

const CenteredLoaderRoot = styled.div`
    height: 100px;
`

interface OrganizationEntryProps {
    organizationId: string
}
const OrganizationEntry: React.FunctionComponent<OrganizationEntryProps> = (props) => {
    const [organization, setOrganization] = useState<Company>()
    const [contextMenuCollapsed, setContextMenuCollapsed] = useState<boolean>(true)
    const language = useLanguageState().getLanguage()
    const favoriteState = useFavoriteState()
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false)
    const { showRecommendModal, RecommendModal } = useRecommendModal()
    const userState = useLoggedInState()
    const user = userState.user()
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const queryParams: any = queryString.parse(window.location.search)

    // chime v2 hooks
    const meetingInvitation = useMeetingInvitation()
    const presenceContext = usePresenceState()
    const history = useHistory()

    useEffect(() => {
        loadExhibitorData({
            organizationid: props.organizationId,
            hideNewsdata: true
        }).then((resp) => {
            setOrganization(resp.content)
        })
        // eslint-disable-next-line
    }, [props.organizationId])

    function onJoinShowroom() {
        if (organization && user) {
            if (branding.configuration.useConferenceRoomV2) {
                joinShowRoom2(organization, user, meetingInvitation, presenceContext, history)
            } else {
                joinShowroom(organization, user, chime, meeting)
            }
        }
    }

    function onRequestMeeting() {
        setShowRequestMeetingModal(true)
    }

    function onRecommendOrganization() {
        if (organization)
            showRecommendModal(
                ShareTargetType.ORGANIZATION,
                organization.id,
                organization.name,
                buildDetailLink(organization.id, organization.name, "organization")
            )
    }

    function getVCardNote() {
        var date = moment()

        var template = branding.organizationDetailPageContent.vCardNoteTemplate
        var contentString = template.split("{$eventGuideName}").join(branding.configuration.ogTitle)
        contentString = contentString
            .split("{$date}")
            .join(language === "en" ? date.format("MMMM DD, YYYY") : date.format("DD. MMMM YYYY"))

        return contentString
    }

    function doVCard(organization: Company) {
        saveExhibitorVCard(organization, getVCardNote())
    }

    function onBookmark() {
        if (organization && user) {
            let date = new Date()
            date.setMinutes(date.getMinutes() - 1)

            if (!favoriteState.is("organization", organization.id)) {
                trackTakeAction(`Set Favorite Organization ${organization.id}`, "SetFavorite", "Organization")
            }

            favoriteState.toggle("organization", organization.id)
            const isFavorite = favoriteState.is("organization", organization.id)
            syncFavorites({
                profileId: user.profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [
                        {
                            id: organization.id,
                            kind: "ORGANIZATION",
                            deleted: !isFavorite,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }
                    ]
                }
            }).then(() => {
                favoriteState.setLastSyncTime(new Date())
            })
        }
    }

    const content: JSX.Element = organization ? (
        <OrganizationEntryRoot>
            <HoverRoot>
                <OrganizationEntryContainer>
                    <OrganizationDataRoot
                        type="organization"
                        id={organization.id}
                        name={organization.name}
                        onClick={() => {
                            trackSelectContent(organization.name, "Details", "Organization", "ContactRequest")
                        }}
                    >
                        <AvatarContainer>
                            <OrganizationLogoDiv>
                                {organization.pictureURL ? (
                                    <OrganizationLogo src={organization.pictureURL} alt={organization.name} />
                                ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                                    <OrganizationLogo src={"/branding/applicationMedia/rsz_defaultgrey.png"} />
                                ) : (
                                    <p className="m-0" style={{ fontFamily: branding.font1, fontSize: "16px" }}>
                                        {organization.initials}
                                    </p>
                                )}
                            </OrganizationLogoDiv>
                        </AvatarContainer>
                        <OrganizationName>{organization.name}</OrganizationName>
                        <ContextButtonContainer>
                            <HoverButton
                                onClick={() => setContextMenuCollapsed(!contextMenuCollapsed)}
                                selected={!contextMenuCollapsed}
                            />
                        </ContextButtonContainer>
                    </OrganizationDataRoot>
                </OrganizationEntryContainer>
            </HoverRoot>
            <ContextMenu
                collapsed={contextMenuCollapsed}
                items={() => {
                    const menuItems: MenuItemProps[] = []

                    // Join showroom
                    menuItems.push({
                        disabled: isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding"),
                        title: branding.organizationDetailPageContent.joinShowroom,
                        icon: IconCameraJoin({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => onJoinShowroom()
                    })

                    // Request meeting
                    menuItems.push({
                        disabled: isPostEventPhase,
                        title: branding.organizationDetailPageContent.requestMeeting,
                        icon: IconMeetingSchedule({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => onRequestMeeting()
                    })

                    // Recommend organization
                    menuItems.push({
                        title: branding.organizationDetailPageContent.recommend,
                        icon: IconShare({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => onRecommendOrganization()
                    })

                    // Bookmark organization
                    const isBookmarked = favoriteState.is("organization", organization.id)
                    menuItems.push({
                        title: branding.organizationDetailPageContent.bookmark,
                        icon: isBookmarked
                            ? branding.defaultToggleIcon
                                ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark })
                                : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark })
                            : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => onBookmark()
                    })

                    //Download vCard
                    if (branding.organizationDetailPageContent.vCardDownloadAvailable) {
                        menuItems.push({
                            disabled: !hasAccessToOrganization(userState.user()!, organization.id),
                            title: branding.contactEntry.downloadVCardTitle,
                            icon: IconDownloadVCard({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => doVCard(organization)
                        })
                    }

                    return menuItems
                }}
            />
            <Divider />
            {showRequestMeetingModal && user && (
                <CalendarEntryModal2
                    viewMode={CalendarEntryModalViewMode.CREATE}
                    organizationId={organization.id}
                    close={() => setShowRequestMeetingModal(false)}
                />
            )}
            <RecommendModal />
        </OrganizationEntryRoot>
    ) : (
        // Organization not loaded yet, show loading indicator
        <CenteredLoaderRoot>
            <CenteredLoader />
        </CenteredLoaderRoot>
    )

    return content
}

export default OrganizationEntry
