import styled from "styled-components"
import branding from "../../../branding/branding"

export const StageRoot = styled.div`
    color: #fff;
    height: 100%;
    width: 100%;
    position: relative;
    font-family: ${branding.font1};
`

export const VideoTilesGrid = styled.div`
    height: calc(100vh - 212px); // 100vh - (Controlls height 150px + Breadcrumb height 62px)
    display: grid;
    grid-gap: 5px;
    grid-row-gap: 5px;
    padding: 5px 5px 15px 5px;

    &.videos-1 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1, 100%);
    }
    &.videos-2 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 100%);
    }
    &.videos-3 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 50%);
        & > :nth-child(1) {
            grid-column: 1 / span 2;
        }
    }
    &.videos-4 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 50%);
    }
    &.videos-5 {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 50%);
        & > :nth-child(1) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(2) {
            grid-column: 4 / span 3;
        }
        & > :nth-child(3) {
            grid-column: 1 / span 2;
        }
        & > :nth-child(4) {
            grid-column: 3 / span 2;
        }
        & > :nth-child(5) {
            grid-column: 5 / span 2;
        }
    }
    &.videos-6 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 50%);
    }
    &.videos-7 {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 33.33%);
        & > :nth-child(1),
        & > :nth-child(3) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(2),
        & > :nth-child(4) {
            grid-column: 4 / span 3;
        }
        & > :nth-child(5) {
            grid-column: 1 / span 2;
        }
        & > :nth-child(6) {
            grid-column: 3 / span 2;
        }
        & > :nth-child(7) {
            grid-column: 5 / span 2;
        }
    }
    &.videos-8 {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 33.33%);
        & > :nth-child(1),
        & > :nth-child(6) {
            grid-column: 1 / span 2;
        }
        & > :nth-child(2),
        & > :nth-child(7) {
            grid-column: 3 / span 2;
        }
        & > :nth-child(3),
        & > :nth-child(8) {
            grid-column: 5 / span 2;
        }
        & > :nth-child(4) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(5) {
            grid-column: 4 / span 3;
        }
    }
    &.videos-9 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 33.33%);
    }
    &.videos-10 {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(3, 33.33%);
        & > :nth-child(1),
        & > :nth-child(4) {
            grid-column: 1 / span 4;
        }
        & > :nth-child(2),
        & > :nth-child(5) {
            grid-column: 5 / span 4;
        }
        & > :nth-child(3),
        & > :nth-child(6) {
            grid-column: 9 / span 4;
        }
        & > :nth-child(7) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(8) {
            grid-column: 4 / span 3;
        }
        & > :nth-child(9) {
            grid-column: 7 / span 3;
        }
        & > :nth-child(10) {
            grid-column: 10 / span 3;
        }
    }
    &.videos-11 {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(3, 33.33%);
        & > :nth-child(1) {
            grid-column: 1 / span 4;
        }
        & > :nth-child(2) {
            grid-column: 5 / span 4;
        }
        & > :nth-child(3) {
            grid-column: 9 / span 4;
        }
        & > :nth-child(4),
        & > :nth-child(8) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(5),
        & > :nth-child(9) {
            grid-column: 4 / span 3;
        }
        & > :nth-child(6),
        & > :nth-child(10) {
            grid-column: 7 / span 3;
        }
        & > :nth-child(7),
        & > :nth-child(11) {
            grid-column: 10 / span 3;
        }
    }
    &.videos-12 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 33.33%);
    }
    &.videos-13 {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, 25%);
        & > :nth-child(1) {
            grid-column: 1 / span 6;
        }
        & > :nth-child(2) {
            grid-column: 7 / span 6;
        }
        & > :nth-child(3) {
            grid-column: 1 / span 4;
        }
        & > :nth-child(4) {
            grid-column: 5 / span 4;
        }
        & > :nth-child(5) {
            grid-column: 9 / span 4;
        }
        & > :nth-child(6),
        & > :nth-child(10) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(7),
        & > :nth-child(11) {
            grid-column: 4 / span 3;
        }
        & > :nth-child(8),
        & > :nth-child(12) {
            grid-column: 7 / span 3;
        }
        & > :nth-child(9),
        & > :nth-child(13) {
            grid-column: 10 / span 3;
        }
    }
    &.videos-14 {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, 25%);
        & > :nth-child(1),
        & > :nth-child(4) {
            grid-column: 1 / span 4;
        }
        & > :nth-child(2),
        & > :nth-child(5) {
            grid-column: 5 / span 4;
        }
        & > :nth-child(3),
        & > :nth-child(6) {
            grid-column: 9 / span 4;
        }
        & > :nth-child(7),
        & > :nth-child(11) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(8),
        & > :nth-child(12) {
            grid-column: 4 / span 3;
        }
        & > :nth-child(9),
        & > :nth-child(13) {
            grid-column: 7 / span 3;
        }
        & > :nth-child(10),
        & > :nth-child(14) {
            grid-column: 10 / span 3;
        }
    }
    &.videos-15 {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, 25%);
        & > :nth-child(1) {
            grid-column: 1 / span 4;
        }
        & > :nth-child(2) {
            grid-column: 5 / span 4;
        }
        & > :nth-child(3) {
            grid-column: 9 / span 4;
        }
        & > :nth-child(4),
        & > :nth-child(8),
        & > :nth-child(12) {
            grid-column: 1 / span 3;
        }
        & > :nth-child(5),
        & > :nth-child(9),
        & > :nth-child(13) {
            grid-column: 4 / span 3;
        }
        & > :nth-child(6),
        & > :nth-child(10),
        & > :nth-child(14) {
            grid-column: 7 / span 3;
        }
        & > :nth-child(7),
        & > :nth-child(11),
        & > :nth-child(15) {
            grid-column: 10 / span 3;
        }
    }
    &.videos-16 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 25%);
    }
`

export const TextCanvas = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

export const PaginationBar = styled.div`
    width: 100%;
    position: absolute;
    z-index: 20;
    background-color: #202428;
    height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PaginationControls = styled.div`
    display: flex;
    justify-content: center;
    align-items: space-around;
`

export const PaginationButton = styled.div<{ disabled: boolean }>`
    cursor: pointer;
    color: ${(props) => (props.disabled ? "grey" : "#fff")};
`
export const Pagination = styled.div`
    font-size: 16px;
    margin: 0 8px 0 8px;
`
