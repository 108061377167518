import { ActionType, Severity, useNotificationDispatch, useRosterState } from "amazon-chime-sdk-component-library-react"
import { useEffect, useState } from "react"
import { AttendeeData } from "../../../../../backendServices/MeetingServices"
import branding from "../../../../../branding/branding"
import { IconLock, IconUnLockedEvent } from "../../../../../ui/Icons"
import { useGreenRoomContext } from "../../../../context/GreenRoomContext"
import { LiveStatus } from "../../../../enums/LiveStatus"
import { LockRoomButtonIcon, LockRoomButtonRoot } from "./LockRoomButton.styled"

interface LockRoomButtonInterface {
    onLiveStatusUpdate: (status: LiveStatus) => void
}

function LockRoomButton(props: LockRoomButtonInterface) {
    const [liveStatus, setLiveStatus] = useState(LiveStatus.UNKNOWN)
    const { roster } = useRosterState()
    const attendees = Object.values(roster)
    const greenRoom = useGreenRoomContext()
    const lockButtonLabel = calcLockButtonLabel(liveStatus)
    const isLocked = useGreenRoomContext().isLocked()
    const dispatch = useNotificationDispatch()

    function calcLockButtonLabel(liveStatus: LiveStatus) {
        switch (liveStatus) {
            case LiveStatus.OFF_AIR_UNLOCKED:
                return branding.conferenceTexts.lockRoomMessage
            case LiveStatus.PENDING_LOCKING:
                return branding.conferenceTexts.lockingMessage
            case LiveStatus.PENDING_UNLOCKING:
                return branding.conferenceTexts.unlockingMessage
            case LiveStatus.PENDING_OFF_ON:
            case LiveStatus.ON_AIR:
            case LiveStatus.PENDING_ON_OFF:
            case LiveStatus.OFF_AIR_LOCKED:
                return branding.conferenceTexts.unlockRoomMessage
        }
        return branding.conferenceTexts.initializingMessage
    }

    const onLockParticipantsButtonClicked = async () => {
        if (liveStatus === LiveStatus.OFF_AIR_UNLOCKED) {
            const authorizedUsers: any = attendees.map((attendee: AttendeeData) => attendee.id)
            setLiveStatus(LiveStatus.PENDING_LOCKING)
            const success = await greenRoom.lockChannel(authorizedUsers)
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.SUCCESS,
                    message: branding.conferenceTexts.roomLockedTitle,
                    autoCloseDelay: 3000,
                    icon: IconLock({ fill: "#fff", width: "18", height: "18" })
                }
            })
            if (!success) {
                // success is handled via chime.isLocked()
                setLiveStatus(LiveStatus.OFF_AIR_UNLOCKED)
            }
        } else if (liveStatus === LiveStatus.OFF_AIR_LOCKED) {
            setLiveStatus(LiveStatus.PENDING_UNLOCKING)
            const success = await greenRoom.unlockChannel()
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.SUCCESS,
                    message: branding.conferenceTexts.roomUnlockedTitle,
                    autoCloseDelay: 3000,
                    icon: IconUnLockedEvent({ fill: "#fff", width: "18", height: "18" })
                }
            })
            if (!success) {
                setLiveStatus(LiveStatus.OFF_AIR_LOCKED)
            }
        }
    }

    useEffect(() => {
        props.onLiveStatusUpdate(liveStatus)
        // eslint-disable-next-line
    }, [liveStatus, isLocked])

    useEffect(() => {
        if (isLocked) {
            switch (liveStatus) {
                case LiveStatus.OFF_AIR_UNLOCKED:
                case LiveStatus.PENDING_LOCKING:
                case LiveStatus.UNKNOWN:
                    setLiveStatus(LiveStatus.OFF_AIR_LOCKED)
            }
        } else {
            switch (liveStatus) {
                case LiveStatus.OFF_AIR_LOCKED:
                case LiveStatus.PENDING_UNLOCKING:
                case LiveStatus.UNKNOWN:
                    setLiveStatus(LiveStatus.OFF_AIR_UNLOCKED)
            }
        }
    }, [isLocked]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LockRoomButtonRoot onClick={onLockParticipantsButtonClicked}>
            <LockRoomButtonIcon>
                {isLocked
                    ? IconLock({ fill: "#fff", width: "16", height: "16" })
                    : IconUnLockedEvent({ fill: "#fff", width: "16", height: "16" })}
            </LockRoomButtonIcon>
            {lockButtonLabel}
        </LockRoomButtonRoot>
    )
}

export default LockRoomButton
