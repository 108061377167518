import { useEffect, useRef, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import LanguageToggler from "../ui/LanguageToggle"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import SystemCheckWizard from "./MobileSystemCheckWizard"

const MobileSystemCheckRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    overflow-y: hidden;
    height: 100vh;
    font-family: ${branding.font1};
`

const MobileSystemCheckStyleContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    z-index: 2;
`

const HeaderRoot = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
    vertical-align: middle;
    z-index: 10;

    & img {
        max-width: ${branding.loginRegistrationSite.centerLoginLogoMaxWidth ?? "150px"};
        max-height: ${branding.loginRegistrationSite.centerLoginLogoMaxHeight ?? "50px"};
        object-fit: contain;
        user-select: none;
    }
`

const WizardRoot = styled.div`
    position: relative;
    height: 100vh;
`

const WizardContainer = styled.div<{ isScrollable: boolean }>`
    margin: 0;
    position: absolute;
    top: 33%;
    bottom: 0;
    left: ${(props) => (props.isScrollable ? "47%" : "50%")};
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 350px;

    @media (min-width: 700px) {
        width: 600px;
    }

    @media (min-width: 360px) {
        width: 340px;
    }
`

const ScrollContainerRoot = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
`

const MobileSystemCheck: React.FunctionComponent = () => {
    const containerRef = useRef(null)

    const [isScrollable, setIsScrollable] = useState<boolean>(false)

    useEffect(() => {
        const scroller = containerRef?.current
        if ((scroller as any)?.scrollHeight > (scroller as any)?.clientHeight) {
            setIsScrollable(true)
        } else {
            setIsScrollable(false)
        }
    }, [containerRef])

    return (
        <MobileSystemCheckRoot>
            <MobileSystemCheckStyleContainer>
                <HeaderRoot>
                    <img
                        src="/branding/applicationMedia/logo.png"
                        height={branding.loginRegistrationSite.centerLoginLogoMaxHeightMobile ?? "17.25px"}
                        alt={branding.configuration.ogTitle}
                    />
                    <div style={{ height: branding.loginRegistrationSite.centerLoginLogoMaxHeightMobile ?? "17.25px" }}>
                        <LanguageToggler
                            margin="0"
                            fontSize="16px"
                            color={branding.loginRegistrationSite.mobileLoginTextColor}
                            fullToggler={true}
                        />
                    </div>
                </HeaderRoot>
                <WizardRoot>
                    <ScrollContainerRoot ref={containerRef}>
                        <ContentScrollContainer>
                            <WizardContainer isScrollable={isScrollable}>
                                <SystemCheckWizard />
                            </WizardContainer>
                        </ContentScrollContainer>
                    </ScrollContainerRoot>
                </WizardRoot>
            </MobileSystemCheckStyleContainer>
        </MobileSystemCheckRoot>
    )
}

export default MobileSystemCheck
