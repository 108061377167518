import { createState, State, useState } from "@hookstate/core"
import { ActionType, Severity, useMeetingManager, useNotificationDispatch } from "amazon-chime-sdk-component-library-react"
import { AttendeeData } from "../../backendServices/MeetingServices"
import branding from "../../branding/branding"
import { DataMessageType } from "../enums/DataMessageType"

interface StateValues {}

const getStartValues = (): StateValues => {
    return {}
}

export interface ModContext {
    modKick: (attendeeId: string, username: string, reason: string) => void
    modBan: (attendeeId: string, username: string, reason: string) => void
    modMute: (attendeeId: string) => void
    modStopContentShare: (attendeeId: string) => void
    isMod: (attendee: AttendeeData) => boolean
}

const useStateWrapper = (state: State<StateValues>) => {
    const meetingManager = useMeetingManager()
    const dispatch = useNotificationDispatch()
    return {
        modKick(attendeeId: string, username: string, reason: string) {
            meetingManager.audioVideo?.realtimeSendDataMessage(attendeeId?.slice(0, 10), {
                type: DataMessageType.KICK,
                data: reason
            })
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.WARNING,
                    message: `${branding.conferenceTexts.kickedInfoForMod.replace("{username}", username)}`,
                    autoCloseDelay: 3000
                }
            })
        },
        modBan(attendeeId: string, username: string, reason: string) {
            meetingManager.audioVideo?.realtimeSendDataMessage(attendeeId?.slice(0, 10), {
                type: DataMessageType.BAN,
                data: reason
            })
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.WARNING,
                    message: `${branding.conferenceTexts.bannedInfoForMod.replace("{username}", username)}`,
                    autoCloseDelay: 3000
                }
            })
        },
        modMute(attendeeId: string) {
            meetingManager.audioVideo?.realtimeSendDataMessage(attendeeId?.slice(0, 10), { type: DataMessageType.MUTE })
        },
        modStopContentShare(attendeeId: string) {
            meetingManager.audioVideo?.realtimeSendDataMessage(attendeeId?.slice(0, 10), {
                type: DataMessageType.STOP_SCREENSHARE
            })
        },
        isMod: (attendee: AttendeeData) => {
            return attendee.role === "moderator"
        }
    }
}

const state = createState<StateValues>(getStartValues())
export const useModContext = (): ModContext => useStateWrapper(useState(state))
