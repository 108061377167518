import styled from "styled-components"
import branding from "../../../branding/branding"

export const PrimaryButtonRoot = styled.div`
    font-family: ${branding.font1};
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    padding: 8px 30px;
    margin-right: 10px;
    transition: 0.3s;
    border: 1px solid ${branding.conferenceRoomV2Theme.primaryButtonBorderColor};
    background-color: ${branding.conferenceRoomV2Theme.primaryButtonBackgroundColor};
    color: ${branding.conferenceRoomV2Theme.primaryButtonTextColor};

    :hover {
        border: 1px solid ${branding.conferenceRoomV2Theme.primaryButtonBorderHoverColor};
        background-color: ${branding.conferenceRoomV2Theme.primaryButtonBackgroundHoverColor};
        color: ${branding.conferenceRoomV2Theme.primaryButtonTextHoverColor};
    }
`
