import PrimaryButton from "../../components/Shared/PrimaryButton"
import SecondaryButton from "../../components/Shared/SecondaryButton"
import { ButtonsContainer, CustomMessage, MeetingStatusContainer, Message, Title } from "./MeetingStatus.styled"

export enum MeetingStatusMessageSize {
    SMALL = "SMALL",
    LARGE = "LARGE"
}

interface MeetingStatusMessageProps {
    title?: string
    titleIcon?: any
    message?: string
    messageIcon?: any
    onPositiveActionClick?: () => void
    positiveActionButtonText?: string
    location?: string
    onNegativeActionClick?: () => void
    negativeActionButtonText?: string
    size?: MeetingStatusMessageSize
    customMessage?: string | null
}

export function MeetingStatusMessage(props: MeetingStatusMessageProps) {
    return (
        <MeetingStatusContainer size={props.size}>
            <Title>
                {props.titleIcon} {props.title}
            </Title>
            <Message>
                {props.messageIcon} {props.message}
            </Message>
            {props.customMessage && <CustomMessage maxHeight={"300px"}>{`Reason: ${props.customMessage}`}</CustomMessage>}
            <ButtonsContainer>
                {props.onPositiveActionClick && (
                    <PrimaryButton onClickHandler={props.onPositiveActionClick} text={props.positiveActionButtonText || ""} />
                )}
                {props.onNegativeActionClick && (
                    <SecondaryButton onClickHandler={props.onNegativeActionClick} text={props.negativeActionButtonText || ""} />
                )}
            </ButtonsContainer>
        </MeetingStatusContainer>
    )
}
export default MeetingStatusMessage
