import { Tooltip } from "react-bootstrap"
import styled, { CSSProperties } from "styled-components"
import { ShareTargetType } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { CustomOverlayTrigger } from "../CustomOverlayTrigger"
import { IconProps, IconRecommend } from "../Icons"
import { useRecommendModal } from "./RecommendModal"

export const IconContainer = styled.div`
    margin-left: 80px;
    border-radius: 5px;

    span {
        margin-left: 5px;
        margin-top: 5px;
    }
`

interface ShareButtonProps {
    targetType: ShareTargetType
    targetId: string
    targetName: string
    link: string
    iconProps: IconProps
    iconContainerStyle?: CSSProperties
}
export function ShareButton(props: ShareButtonProps) {
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    return (
        <>
            <RecommendModal />
            <CustomOverlayTrigger
                trigger={["focus", "click", "hover"]}
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {branding.communicationArea.chatToolRecommend}
                    </Tooltip>
                }
            >
                <IconContainer
                    onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        showRecommendModal(props.targetType, props.targetId, props.targetName, props.link)
                    }}
                    style={props.iconContainerStyle}
                >
                    {IconRecommend(props.iconProps)}
                </IconContainer>
            </CustomOverlayTrigger>
        </>
    )
}
