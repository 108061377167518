import styled from "styled-components"
import * as React from "react"
import branding, { ColorString, TopicBadge } from "../branding/branding"
import { Category } from "../backendServices/Types"
import { useSuggestContext } from "../navigationArea/useSuggestContext"
import { useHistory } from "react-router-dom"
import { globalSearchResultPageRoute } from "../navigationArea/RoutePaths"
import { CategoryBadge, CategoryText } from "./BadgeArea"
import _ from "lodash"

export const BadgesContainerRoot = styled.div<{ limitRows?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    height: ${(props) => (props.limitRows ? "20px" : "auto")};

    &.profile {
        width: 290px;
    }
`

const BadgesLimitMarker = styled.div`
    font-size: 16px;
    margin-left: 5px;
    color: ${branding.crsTabs.defaultActionItemActiveStateColor};
`
interface BadgesProps {
    topics?: string[]
    categoryBadges?: Category[]
    otherBadges?: string[]
    communicationArea?: boolean
    limitRows?: boolean // used for limiting rows. specific use case: CC's contact cards, which have it limited to 1 row until the card is expanded
}

const BadgesContainer: React.FC<BadgesProps> = (props) => {
    const suggestState = useSuggestContext()
    const history = useHistory()

    const topicBadges: TopicBadge[] = branding.topicBadges
    const categoryBadgesIdsForFiltering: string[] = branding.categoryBadges

    function getBorderColor(badgeBorderColor: string | ColorString): ColorString {
        //branding color is used if border color is not defined for this category
        return badgeBorderColor && badgeBorderColor?.length! > 0
            ? badgeBorderColor
            : branding.communicationArea.userBadgesDefaultColorBorder
    }

    function getFillColor(badgeFillColor: string | ColorString): ColorString {
        //branding color is used if fill color is not defined for this category
        return badgeFillColor && badgeFillColor?.length! > 0
            ? badgeFillColor
            : branding.communicationArea.userBadgesDefaultColorFill
    }

    function getTextColor(badgeTextColor: string | ColorString): ColorString {
        //branding color is used if text color is not defined for this category
        return badgeTextColor && badgeTextColor?.length! > 0
            ? badgeTextColor
            : branding.communicationArea.userBadgesDefaultColorText
    }

    //here we are filtering the provided category badges, so we use only those whose ids are specified in the branding configuration
    //if branding configuration for filtering is empty, all of the badges are shown

    const categoryBadges: Category[] = _.sortBy(
        _.uniqBy(
            categoryBadgesIdsForFiltering.length === 0
                ? props.categoryBadges || []
                : (props.categoryBadges || []).filter((item: Category) => categoryBadgesIdsForFiltering.indexOf(item.id) >= 0),
            function (item: Category) {
                return item.id
            }
        ),
        "id"
    )

    const topics = props.topics
    const className = props.communicationArea ? "communicationArea" : ""

    let badgesNumber: number = 0

    const onCategorySelect = (category: Category | any) => {
        //category.name is a fallback case here when badgeName is not available, specifically for interest badges in my profile page
        if ((category.key || category.id) && (category.badgeName || category.name)) {
            suggestState.addExternalSuggestion({
                icon: "",
                id: "",
                subtitle: "",
                title: category.badgeName || category.name,
                value: "cat_" + (category.key || category.id),
                active: true
            })
            history.push(globalSearchResultPageRoute)
        }
    }

    if (
        (topicBadges !== undefined && topicBadges.length > 0 && topics !== undefined && topics.length > 0) ||
        (categoryBadges && categoryBadges.length > 0) ||
        (props.otherBadges !== undefined && props.otherBadges?.length! > 0)
    ) {
        return (
            <BadgesContainerRoot className={props.communicationArea ? "" : "profile"} limitRows={props.limitRows}>
                {topicBadges &&
                    topicBadges.length > 0 &&
                    topicBadges
                        .sort((topicBadge1: TopicBadge, topicBadge2: TopicBadge) => {
                            return topicBadge1.badgeText > topicBadge2.badgeText ? 1 : -1
                        })
                        .map((topicBadge: TopicBadge) => {
                            return (
                                topics &&
                                topics.map((topicName: string) => {
                                    if (topicBadge.topicName === topicName) {
                                        if (
                                            props.communicationArea &&
                                            badgesNumber === branding.communicationArea.userBadgesLimit
                                        ) {
                                            badgesNumber += 1
                                            return <BadgesLimitMarker>...</BadgesLimitMarker>
                                        } else if (
                                            props.communicationArea &&
                                            badgesNumber > branding.communicationArea.userBadgesLimit
                                        ) {
                                            return null
                                        } else {
                                            badgesNumber += 1
                                            return (
                                                <CategoryBadge
                                                    key={topicName}
                                                    bgImage=""
                                                    bgColor={getFillColor(topicBadge.backgroundColor)}
                                                    borderColor={getBorderColor(topicBadge.borderColor)}
                                                    textColor={getTextColor(topicBadge.textColor)}
                                                    className={"person profile " + className}
                                                >
                                                    <CategoryText className={"noIcon " + className}>
                                                        {topicBadge.badgeText}
                                                    </CategoryText>
                                                </CategoryBadge>
                                            )
                                        }
                                    }
                                    return null
                                })
                            )
                        })}
                {categoryBadges &&
                    categoryBadges.length > 0 &&
                    categoryBadges.map((categoryBadge) => {
                        if (
                            categoryBadgesIdsForFiltering.indexOf(categoryBadge.id) >= 0 ||
                            categoryBadgesIdsForFiltering.length === 0
                        ) {
                            if (props.communicationArea && badgesNumber === branding.communicationArea.userBadgesLimit) {
                                badgesNumber += 1
                                return <BadgesLimitMarker>...</BadgesLimitMarker>
                            } else if (props.communicationArea && badgesNumber > branding.communicationArea.userBadgesLimit) {
                                return null
                            } else {
                                badgesNumber += 1

                                return (
                                    <CategoryBadge
                                        key={categoryBadge.id + (categoryBadge.badgeName || categoryBadge.name)}
                                        bgImage=""
                                        bgColor={getFillColor(categoryBadge.colorFill)}
                                        borderColor={getBorderColor(categoryBadge.colorBorder)}
                                        textColor={getTextColor(categoryBadge.colorText)}
                                        onClick={(e: any) => {
                                            onCategorySelect(categoryBadge)

                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                        className={"profile " + className}
                                    >
                                        <CategoryText className={"noIcon " + className}>
                                            {/* category.name is a fallback case here when badgeName is not available, specifically for interest badges in my profile page */}
                                            {categoryBadge.badgeName || categoryBadge.name}
                                        </CategoryText>
                                    </CategoryBadge>
                                )
                            }
                        } else {
                            return null
                        }
                    })}
                {props.otherBadges &&
                    props.otherBadges.map((badge) => {
                        if (props.communicationArea && badgesNumber === branding.communicationArea.userBadgesLimit) {
                            badgesNumber += 1
                            return <BadgesLimitMarker>...</BadgesLimitMarker>
                        } else if (props.communicationArea && badgesNumber > branding.communicationArea.userBadgesLimit) {
                            return null
                        } else {
                            badgesNumber += 1
                            return (
                                <CategoryBadge
                                    key={badge}
                                    bgImage=""
                                    bgColor={branding.communicationArea.userBadgesDefaultColorFill}
                                    borderColor={branding.communicationArea.userBadgesDefaultColorBorder}
                                    textColor={branding.communicationArea.userBadgesDefaultColorText}
                                    className={"person profile " + className}
                                >
                                    <CategoryText className={"noIcon " + className}>{badge}</CategoryText>
                                </CategoryBadge>
                            )
                        }
                    })}
            </BadgesContainerRoot>
        )
    } else {
        return null
    }
}

export default BadgesContainer
