import { ActionType, Severity, useNotificationDispatch, useRosterState } from "amazon-chime-sdk-component-library-react"
import { useEffect, useState } from "react"
import { AttendeeData } from "../../../../../backendServices/MeetingServices"
import branding from "../../../../../branding/branding"
import { ChannelStatus } from "../../../../../conference/enums/ChannelStatus"
import { useGreenRoomContext } from "../../../../context/GreenRoomContext"
import { LiveStatus } from "../../../../enums/LiveStatus"
import { GoLiveButtonRoot } from "./GoLiveButton.styled"

interface GoLiveButtonProps {
    liveStatus: LiveStatus
}

function GoLiveButton(props: GoLiveButtonProps) {
    const greenRoom = useGreenRoomContext()
    const channelStatus = greenRoom.getChannelStatus()
    const [liveStatus, setLiveStatus] = useState(LiveStatus.UNKNOWN)
    const { roster } = useRosterState()
    const [attendees, setAttendees] = useState(Object.values(roster))
    const [recorderAvailable, setRecorderAvailable] = useState(
        attendees.filter((participant: AttendeeData) => participant.role === "recorder").length > 0
    )
    const dispatch = useNotificationDispatch()
    useEffect(() => {
        setLiveStatus(props.liveStatus)
    }, [props.liveStatus])

    useEffect(() => {
        setAttendees(Object.values(roster))
    }, [roster])

    useEffect(() => {
        setRecorderAvailable(attendees.filter((participant: AttendeeData) => participant.role === "recorder").length > 0)
        // eslint-disable-next-line
    }, [roster, liveStatus, channelStatus, greenRoom.getChannelStatus()])

    useEffect(() => {
        calcLiveButtonLabel(liveStatus)
        // eslint-disable-next-line
    }, [liveStatus])

    useEffect(() => {
        switch (channelStatus) {
            case ChannelStatus.OFF_AIR:
                setLiveStatus(greenRoom.isLocked() ? LiveStatus.OFF_AIR_LOCKED : LiveStatus.OFF_AIR_UNLOCKED)
                break
            case ChannelStatus.ON_AIR:
                setLiveStatus(LiveStatus.ON_AIR)
                break
            case ChannelStatus.PREPARING:
                setLiveStatus(LiveStatus.PENDING_OFF_ON)
        }
        // eslint-disable-next-line
    }, [liveStatus, channelStatus])

    const liveButtonLabel = calcLiveButtonLabel(liveStatus)

    function calcLiveButtonLabel(liveStatus: LiveStatus) {
        switch (liveStatus) {
            case LiveStatus.OFF_AIR_UNLOCKED:
            case LiveStatus.PENDING_LOCKING:
            case LiveStatus.PENDING_UNLOCKING:
            case LiveStatus.OFF_AIR_LOCKED:
                return branding.conferenceTexts.goLiveMessage
            case LiveStatus.PENDING_OFF_ON:
                return branding.conferenceTexts.goingLiveMessage
            case LiveStatus.ON_AIR:
                return branding.conferenceTexts.stopLiveMessage
            case LiveStatus.PENDING_ON_OFF:
                return branding.conferenceTexts.stoppingLiveMessage
        }
        return branding.conferenceTexts.initializingMessage
    }

    const onLiveStatusButtonClicked = async () => {
        if (channelStatus === ChannelStatus.OFF_AIR) {
            setLiveStatus(LiveStatus.PENDING_OFF_ON)
            let secs = 10
            const notify = setInterval(() => {
                dispatch({
                    type: ActionType.ADD,
                    payload: {
                        severity: Severity.SUCCESS,
                        message: `${branding.conferenceTexts.liveInMessage} ${secs}`,
                        autoCloseDelay: 1000,
                        replaceAll: true
                    }
                })
                secs--
                if (secs === 0) clearInterval(notify)
            }, 1000)

            greenRoom.startLive()
        } else if (channelStatus === ChannelStatus.ON_AIR) {
            setLiveStatus(LiveStatus.PENDING_ON_OFF)
            const success = await greenRoom.stopLive()
            if (success) {
                dispatch({
                    type: ActionType.ADD,
                    payload: {
                        severity: Severity.SUCCESS,
                        message: branding.conferenceTexts.liveStopped,
                        autoCloseDelay: 3000,
                        replaceAll: true
                    }
                })
            }
            if (!success) {
                setLiveStatus(LiveStatus.ON_AIR)
            }
        }
    }

    return (
        <GoLiveButtonRoot
            onClick={() => {
                if (!recorderAvailable || (!greenRoom.isLocked() && channelStatus === ChannelStatus.OFF_AIR)) return
                onLiveStatusButtonClicked()
            }}
            isLive={false}
            disabled={
                !recorderAvailable ||
                (!greenRoom.isLocked() && channelStatus === (ChannelStatus.OFF_AIR || ChannelStatus.PREPARING))
            }
        >
            {liveButtonLabel}
        </GoLiveButtonRoot>
    )
}

export default GoLiveButton
