import { createState, State, useState } from "@hookstate/core"
import {
    useBackgroundBlur,
    useBackgroundReplacement,
    useMeetingManager,
    useVideoInputs
} from "amazon-chime-sdk-component-library-react"
import {
    BackgroundReplacementVideoFrameProcessor,
    ConsoleLogger,
    DefaultVideoTransformDevice,
    LogLevel
} from "amazon-chime-sdk-js"
import branding from "../../branding/branding"
import { LogoProcessor } from "../../conference/processor/LogoProcessor"
import { VirtualBackgroundProcessor } from "../../conference/processor/VirtualBackgroundProcessor"
import { LogoPositions } from "../enums/LogoPosition"
import { VideoInputStorageKey } from "./DeviceController"
import { preMeetingSettingsKey, PreMeetingSettingsObjectType } from "./PreMeetingSettingsContext"
const defaultPosition = "topleft"
const logoProcessor = new LogoProcessor(defaultPosition)
const backgroundProcessor = new VirtualBackgroundProcessor()
let videoTransformDevice: DefaultVideoTransformDevice | undefined
interface StateValues {
    backgroundGalleryItems: string[]
    selectedBackground?: string | null
    isBlurActive: boolean
    customUploadedBackground: string | null
    isLoading: boolean
    logoGalleryItems: string[]
    customUploadedLogo: string | null
    selectedLogo: string | null
    logoProcessor: LogoProcessor | null
    selectedLogoPosition: LogoPositions
    processors: LogoProcessor | BackgroundReplacementVideoFrameProcessor | null
    isLogoProcessorActive: boolean
}

const getStartValues = (): StateValues => {
    return {
        backgroundGalleryItems: branding.audioVideoSettings.galleryItemList,
        selectedBackground: null,
        isBlurActive: false,
        customUploadedBackground: null,
        isLoading: false,
        customUploadedLogo: null,
        logoGalleryItems: ["/branding/applicationMedia/organizerlogo_closed.png"], // TODO: Loop over user organizations logo
        selectedLogo: null,
        logoProcessor: new LogoProcessor(LogoPositions.TOP_LEFT),
        selectedLogoPosition: LogoPositions.TOP_LEFT,
        processors: null,
        isLogoProcessorActive: false
    }
}

const state = createState<StateValues>(getStartValues())

export interface VideoContext {
    getBackgroundGalleryItems: () => string[]
    getSelectedBackground: () => string | null | undefined
    getCustomUploadedBackground: () => string | null
    setSelectedBackground: (url?: string) => Promise<void>
    uploadBackground: (e: any) => void
    removeBackground: () => Promise<void>
    deleteCustomBackground: () => void
    toggleBlur: () => Promise<void>
    getIsBlurActive: () => boolean
    setSelectedLogo: (url: string) => Promise<void>
    uploadLogo: (e: any) => void
    getLogoGalleryItems: () => string[]
    getCustomUploadedLogo: () => string | null | undefined
    getSelectedLogo: () => string | null
    removeLogo: () => void
    setLogoPosition: (pos: LogoPositions) => void
    getLogoPosition: () => LogoPositions
    getIsLogoProcessorActive: () => boolean
    deleteCustomUploadedLogo: () => void
    updateVideoInput: () => void
}

const useStateWrapper = (state: State<StateValues>): VideoContext => {
    const { selectedDevice } = useVideoInputs()
    const { isBackgroundBlurSupported } = useBackgroundBlur()
    const { isBackgroundReplacementSupported } = useBackgroundReplacement()
    const meetingManager = useMeetingManager()

    let isLoading = false

    const setVideoInput = async () => {
        if (isLoading) return
        isLoading = true
        const deviceId = localStorage.getItem(VideoInputStorageKey)
        if (!deviceId) {
            await Promise.all([meetingManager.stopVideoInputDevice(), videoTransformDevice?.stop()])
            videoTransformDevice = undefined
        } else {
            if (!videoTransformDevice) {
                videoTransformDevice = new DefaultVideoTransformDevice(
                    new ConsoleLogger("Video Transformer", LogLevel.OFF),
                    deviceId,
                    [backgroundProcessor, logoProcessor]
                )
            } else videoTransformDevice = videoTransformDevice.chooseNewInnerDevice(deviceId)

            meetingManager.startVideoInputDevice(videoTransformDevice)
            isLoading = false
        }
    }

    return {
        getBackgroundGalleryItems: () => {
            return state.value.backgroundGalleryItems
        },
        getSelectedBackground: () => {
            return state.value.selectedBackground
        },
        setSelectedBackground: async (url?: string) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
            backgroundProcessor.setBackground("choose", url || "")
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                backgroundProcessor.setBackground("choose", url || "")
            } catch (e: any) {
                console.error("Error trying to apply Background image", e)
            } finally {
                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy && url) {
                    localStoragePreMeetingSettingsCopy.backgroundUrl = url
                }

                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
                state.merge({ isLoading: false, selectedBackground: url, isBlurActive: false })
                document.body.style.cursor = "default"
            }
        },
        getCustomUploadedBackground: () => {
            return state.value.customUploadedBackground
        },
        uploadBackground: (e: any) => {
            let { files } = e.target
            let images: any = [],
                fileReaders: any = []
            let isCancel = false
            if (files.length) {
                files.forEach((file: any) => {
                    const fileReader = new FileReader()
                    fileReaders.push(fileReader)
                    fileReader.onload = (e: any) => {
                        const { result } = e.target
                        if (result) {
                            images.push(result)
                            localStorage.setItem("virtualGuide-MeetingBackground", result) // TODO: Save to S3
                        }
                        if (images.length === files.length && !isCancel) {
                            state.set((prevState) => {
                                prevState.backgroundGalleryItems = [
                                    images[0],
                                    // previous gallery items but filtered the previous uploaded item out
                                    ...prevState.backgroundGalleryItems.filter(
                                        (item) => item !== prevState.customUploadedBackground
                                    )
                                ]
                                prevState.customUploadedBackground = images[0]

                                return prevState
                            })
                        }
                    }
                    fileReader.readAsDataURL(file)
                })
            }
            return () => {
                isCancel = true
                fileReaders.forEach((fileReader: any) => {
                    if (fileReader.readyState === 1) {
                        fileReader.abort()
                    }
                })
            }
        },
        removeBackground: async () => {
            if (state.value.isLoading) return
            try {
                state.merge({ isLoading: true })
                backgroundProcessor.setBackground("none", null)
            } catch (e: any) {
                console.error("Errory while trying removing background ", e)
            } finally {
                state.merge({ selectedBackground: null, isBlurActive: false, isLoading: false })
                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy) {
                    localStoragePreMeetingSettingsCopy.backgroundUrl = ""
                }

                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
            }
        },
        deleteCustomBackground: async () => {
            if (state.value.isLoading || !selectedDevice) return
            if (state.value.customUploadedBackground === state.value.selectedBackground) {
                try {
                    state.merge({ isLoading: true })
                    document.body.style.cursor = "wait"
                    backgroundProcessor.setBackground("none", null)
                } catch (e: any) {
                    console.error("Error while trying to apply Background image ", e)
                } finally {
                    state.merge({ isLoading: false, selectedBackground: null, isBlurActive: false })
                    state.set((prevState) => {
                        prevState.backgroundGalleryItems = prevState.backgroundGalleryItems.filter(
                            (galleryItem) => galleryItem !== prevState.customUploadedBackground
                        )
                        return prevState
                    })
                    document.body.style.cursor = "default"
                }
            }
        },
        toggleBlur: async () => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundBlurSupported) return
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                if (!state.value.isBlurActive) {
                    backgroundProcessor.setBackground("blur", null)
                } else {
                    backgroundProcessor.setBackground("none", null)
                }
            } catch (e: any) {
                console.error("Error while trying to apply blur ", e)
            } finally {
                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy) {
                    localStoragePreMeetingSettingsCopy.blurState = !state.value.isBlurActive
                    localStoragePreMeetingSettingsCopy.backgroundUrl = ""
                }

                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))

                state.merge({ isBlurActive: !state.value.isBlurActive, isLoading: false, selectedBackground: null })
                document.body.style.cursor = "default"
            }
        },
        getIsBlurActive: () => {
            return state.value.isBlurActive
        },
        setSelectedLogo: async (url: string) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                logoProcessor.setPosition(defaultPosition)
                logoProcessor.setSrc(url)
            } catch (e: any) {
                console.error("Error while trying to apply logo image ", e)
            } finally {
                document.body.style.cursor = "default"
                state.merge({ isLoading: false, selectedLogo: url })

                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy && url) {
                    localStoragePreMeetingSettingsCopy.logoUrl = url
                }

                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
            }
        },
        uploadLogo: (e: any) => {
            let { files } = e.target
            let images: any = [],
                fileReaders: any = []
            let isCancel = false
            if (files.length) {
                files.forEach((file: any) => {
                    const fileReader = new FileReader()
                    fileReaders.push(fileReader)
                    fileReader.onload = (e: any) => {
                        const { result } = e.target
                        if (result) {
                            images.push(result)
                            // localStorage.setItem("virtualGuide-MeetingLogo", result) // TODO: Save to S3
                        }
                        if (images.length === files.length && !isCancel) {
                            state.set((prevState) => {
                                prevState.logoGalleryItems = [
                                    images[0], // previous gallery items but filtered the previous uploaded item out
                                    ...prevState.logoGalleryItems.filter((item) => item !== prevState.customUploadedLogo)
                                ]
                                prevState.customUploadedLogo = images[0]
                                return prevState
                            })
                        }
                    }
                    fileReader.readAsDataURL(file)
                })
            }
            return () => {
                isCancel = true
                fileReaders.forEach((fileReader: any) => {
                    if (fileReader.readyState === 1) {
                        fileReader.abort()
                    }
                })
            }
        },
        getLogoGalleryItems: () => {
            return state.value.logoGalleryItems
        },
        getCustomUploadedLogo: () => {
            return state.value.customUploadedLogo
        },
        getSelectedLogo: () => {
            return state.value.selectedLogo
        },
        removeLogo: () => {
            logoProcessor.setPosition(defaultPosition)
            logoProcessor.setSrc(null)

            const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
            let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                structuredClone(localStoragePreMeetingSettings)
            if (localStoragePreMeetingSettingsCopy) {
                localStoragePreMeetingSettingsCopy.logoUrl = ""
            }

            localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))

            state.merge({ selectedLogo: null })
        },
        setLogoPosition: (pos: LogoPositions) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
            logoProcessor.setPosition(pos)
            const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
            let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                structuredClone(localStoragePreMeetingSettings)
            if (localStoragePreMeetingSettingsCopy?.logoPosition) {
                localStoragePreMeetingSettingsCopy.logoPosition = pos
            }
            localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
        },
        getLogoPosition: () => {
            return state.value.selectedLogoPosition
        },
        getIsLogoProcessorActive: () => {
            return state.value.isLogoProcessorActive
        },
        deleteCustomUploadedLogo: async () => {
            if (state.value.isLoading || !selectedDevice) return
            if (state.value.customUploadedLogo === state.value.selectedLogo) {
                state.set((prevState) => {
                    prevState.selectedLogo = null
                    return prevState
                })
                if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
                try {
                    state.merge({ isLoading: true })
                    document.body.style.cursor = "wait"
                    logoProcessor.setPosition(defaultPosition)
                    logoProcessor.setSrc(null)
                } catch (e: any) {
                    console.error("Error while trying to remove logo ", e)
                } finally {
                    state.merge({ isLoading: false, selectedLogo: null })
                    document.body.style.cursor = "default"
                }
            }
            state.set((prevState) => {
                prevState.logoGalleryItems = prevState.logoGalleryItems.filter(
                    (galleryItem) => galleryItem !== prevState.customUploadedLogo
                )
                return prevState
            })
        },
        updateVideoInput: () => {
            setVideoInput()
        }
    }
}

export const useVideoContext = (): VideoContext => useStateWrapper(useState(state))
