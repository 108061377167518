import { Tooltip } from "react-bootstrap"
import branding from "../../../branding/branding"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { IconArrowDownLangSwitcher, IconArrowUpLangSwitcher } from "../../../ui/Icons"
import { LocalTileVisibilityToggleButtonRoot } from "./LocalVideo.styled"

interface LocalVideoToggleButtonProps {
    handleClick: () => void
    isLocalTileVisible: boolean
}

export function LocalTileVisibilityToggleButton(props: LocalVideoToggleButtonProps) {
    return (
        <CustomOverlayTrigger
            trigger={["focus", "click", "hover"]}
            placement={"top"}
            overlay={
                <Tooltip id="people-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                    {props.isLocalTileVisible
                        ? branding.conferenceTexts.hideLocalTileLabel
                        : branding.conferenceTexts.showLocalTileLabel}
                </Tooltip>
            }
        >
            <LocalTileVisibilityToggleButtonRoot onClick={props.handleClick} isLocalTileVisible={props.isLocalTileVisible}>
                {props.isLocalTileVisible ? (
                    <IconArrowDownLangSwitcher fill="#fff" width="12px" height="12px" />
                ) : (
                    <IconArrowUpLangSwitcher fill="#fff" width="12px" height="12px" />
                )}
            </LocalTileVisibilityToggleButtonRoot>
        </CustomOverlayTrigger>
    )
}
