import Select from "react-select"
import styled from "styled-components"
import branding from "../../../branding/branding"

export const SettingsBackground = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999 !important;
    animation: fadein 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    *::selection {
        background: transparent;
    }
`
export const SettingsModal = styled.div`
    width: 676px;
    height: 694px;
    background-color: #202428;
    border-radius: 5px;
    padding: 25px 20px 20px 20px;
    position: relative;
    font-family: ${branding.font1};
`
export const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    font-family: ${branding.font1};
    color: #fff;
`

export const CloseIconContainer = styled.div`
    float: right;
    cursor: pointer;
`

export const DeviceSelect = styled(Select)`
    width: 100%;
    border-radius: 3px;
    padding: 0.375rem 1.5rem 0.375rem 0.5rem;
    margin-bottom: 10px;
    font-size: 0.875rem;
    line-height: 1.43;
    padding: 6px;

    & > div[class*="control"] {
        border: none;
        background-color: #17181a;
    }

    & > div[class*="menu"] {
        margin-top: -2px;
        background-color: #17181a !important;
    }

    & > div[class*="option"] {
        background-color: #17181a;
    }
    & div[class*="MenuList"] > div[class*="option"]:hover {
        background-color: #666 !important;
        transition: 0.3s;
    }
`

export const SettingsSectionTitle = styled.div`
    font-family: ${branding.font1};
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-top: 3px;
`

export const SettingsLabel = styled.label`
    font-family: ${branding.font1};
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 5px 6px;
`

export const SettingsFixedBottomContainer = styled.div`
    position: absolute;
    right: 20px;
    left: 20px;
    bottom: 20px;
    height: 40px;
`

export const ErrorMessageContainer = styled.div`
    position: absolute;
    bottom: 50px;
    right: 0;
    font-size: 12px;
    color: #e30613;
    width: 100%;
    text-align: center;
`

export const ButtonsContainer = styled.div`
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: flex-end;
`

export const CenteredSpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
`
