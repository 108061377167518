import { Camera, Phone } from "amazon-chime-sdk-component-library-react"
import { useEffect } from "react"
import { Tooltip } from "react-bootstrap"
import useSound from "use-sound"
import { HookOptions } from "use-sound/dist/types"
import { InvitationType } from "../../API"
import { MeetingParticipant } from "../../backendServices/GraphQLServices"
import branding from "../../branding/branding"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { CustomOverlayTrigger } from "../../ui/CustomOverlayTrigger"
import { IconCalendarEntry, IconCameraJoin, IconClose } from "../../ui/Icons"
import { useMeetingController } from "../context/MeetingController"
import { useMeetingInvitation } from "../context/MeetingInvitation"

import {
    ActionButtonRoot,
    ActionButtons,
    BoldedText,
    CallAnimation,
    CallAnimationWrapper,
    CloseIconWrapper,
    CustomRow,
    HailingOverlayRoot,
    IconAvatarWrapper,
    IconWrapper,
    MeetingInvitationJoinButton,
    MeetingInvitationTextBox,
    MeetingNotificationCallerName,
    MeetingNotificationModalRoot,
    MeetingNotificationTitle
} from "./HailingOverlayV2.styled"

interface HailingOverlayProps {
    audioFileFormat: string
}
enum ModalType {
    Incoming = "INCOMING",
    Outgoing = "OUTGOING",
    MeetingStarted = "MEETINGSTARTED"
}

function HailingOverlayV2(props: HailingOverlayProps) {
    const loggedInUser = useLoggedInState()
    const meetingInvitation = useMeetingInvitation()
    const outgoingInvites: MeetingParticipant[] = meetingInvitation.getOutGoingInvites()
    const incomingInvites: MeetingParticipant[] = meetingInvitation.getIncomingInvites()
    const profileId = loggedInUser.user()?.profileId

    useEffect(() => {
        if (profileId) meetingInvitation.subscribeToCalls(profileId)
    }, [profileId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <HailingOverlayRoot>
            {outgoingInvites &&
                outgoingInvites.map((invite: MeetingParticipant, index: number) => {
                    return <MeetingNotificationModal key={index} invite={invite} type={ModalType.Outgoing} />
                })}

            {incomingInvites &&
                incomingInvites.map((invite: MeetingParticipant, index: number) => {
                    return <MeetingNotificationModal key={index} invite={invite} type={ModalType.Incoming} />
                })}
        </HailingOverlayRoot>
    )
}

interface MeetingNotificationModalProps {
    type: ModalType
    invite: MeetingParticipant
}

function MeetingNotificationModal(props: MeetingNotificationModalProps) {
    const cameraIcon = <Camera color="#fff" width="28px" />
    const phoneIcon1 = <Phone color="#fff" width="28px" />
    const phoneIcon = <Phone color="#fff" width="28px" />
    const meetingInvitation = useMeetingInvitation()
    const meetingController = useMeetingController()
    const outgoingCallSound = "/branding/applicationMedia/sounds/outgoingCall.mp3"
    const meetingStartedSound = "/branding/applicationMedia/sounds/meetingStarted.mp3"
    const loggedInState = useLoggedInState()

    const [playMeetingStarted] = useSound(meetingStartedSound, {
        volume: 0.2,
        interrupt: true,
        loop: false
    } as HookOptions)

    const [playOutgoingCall, { stop }] = useSound(outgoingCallSound, {
        volume: 0.2,
        interrupt: true,
        loop: true
    } as HookOptions)

    useEffect(() => {
        setTimeout(() => {
            if (props && props.invite?.invitationType && props.invite?.inviter) {
                if (
                    props.invite?.invitationType === InvitationType.AUDIO ||
                    props.invite?.invitationType === InvitationType.VIDEO
                ) {
                    // calling sound
                    // play the sound no matter if you the inviter or the invitee
                    playOutgoingCall()
                } else if (
                    // meeting started sound
                    // play the sound only if you the invitee
                    props.invite?.invitationType === InvitationType.NOTIFY &&
                    loggedInState.user()?.profileId !== props.invite?.inviter?.id
                ) {
                    playMeetingStarted()
                }
            }
        })
        return () => {
            stop()
        }
        //eslint-disable-next-line
    }, [props.invite?.invitationType, props.invite?.inviter?.id, loggedInState.user()])

    let content: JSX.Element = <div />
    if (props.invite.invitationType === InvitationType.VIDEO || props.invite.invitationType === InvitationType.AUDIO) {
        content = (
            <>
                <CustomRow>
                    <CallAnimationWrapper>
                        <CallAnimation>
                            <AvatarWithDefault
                                size={100}
                                badgesize={100}
                                alt={props.invite.invitee.name}
                                src={
                                    props.type === ModalType.Outgoing
                                        ? props.invite.invitee.pictureUrl
                                        : props.invite.inviter.pictureUrl
                                }
                                backgroundSize="cover"
                            />
                        </CallAnimation>
                    </CallAnimationWrapper>
                </CustomRow>
                <CustomRow>
                    <MeetingNotificationTitle>
                        {props.type === ModalType.Incoming
                            ? branding.conferenceTexts.incomingCall
                            : branding.conferenceTexts.outgoingCall}
                    </MeetingNotificationTitle>
                    <MeetingNotificationCallerName>
                        {props.type === ModalType.Outgoing ? props.invite.invitee.name : props.invite.inviter.name}
                    </MeetingNotificationCallerName>
                </CustomRow>
                {props.type === ModalType.Incoming ? (
                    <ActionButtons>
                        <ActionButton
                            onClick={() => {
                                if (meetingController.getIsMeetingActive()) {
                                    meetingController.setIsSwitchingRoom(true)
                                }

                                meetingController.setIsMeetingChangeAccepted(false)
                                window.sessionStorage.setItem("currentTabCall", props.invite.id)
                                meetingInvitation.acceptInvite(props.invite.id, InvitationType.VIDEO)
                            }}
                            icon={cameraIcon}
                        />
                        <ActionButton
                            onClick={() => {
                                if (meetingController.getIsMeetingActive()) {
                                    meetingController.setIsSwitchingRoom(true)
                                }

                                meetingController.setIsMeetingChangeAccepted(false)
                                window.sessionStorage.setItem("currentTabCall", props.invite.id)
                                meetingInvitation.acceptInvite(props.invite.id)
                            }}
                            icon={phoneIcon}
                        />
                        <ActionButton
                            onClick={() => meetingInvitation.declineInvite(props.invite.id)}
                            backgroundColor={"#E30613"}
                            icon={phoneIcon1}
                        />
                    </ActionButtons>
                ) : (
                    <ActionButtons>
                        <ActionButton
                            onClick={() => meetingInvitation.cancelInvite(props.invite.id)}
                            backgroundColor={"#E30613"}
                            icon={phoneIcon1}
                        />
                    </ActionButtons>
                )}
            </>
        )
    } else {
        content = (
            <>
                <CloseIconWrapper onClick={() => meetingInvitation.declineInvite(props.invite.id)}>
                    <IconClose fill={"#fff"} height={"15px"} width={"15px"} />
                </CloseIconWrapper>
                <CustomRow>
                    <IconAvatarWrapper>
                        <IconCalendarEntry fill="#fff" width="30" height="30" />
                    </IconAvatarWrapper>
                </CustomRow>
                <CustomRow>
                    <MeetingInvitationTextBox>
                        <BoldedText> {props.invite.inviter.name}</BoldedText> started the meeting
                        <BoldedText> {props.invite.meetingTitle}</BoldedText>
                    </MeetingInvitationTextBox>
                </CustomRow>
                <CustomRow>
                    <MeetingInvitationJoinButton
                        onClick={() => {
                            if (meetingController.getIsMeetingActive()) {
                                meetingController.setIsSwitchingRoom(true)
                            }
                            meetingController.setIsMeetingChangeAccepted(false)
                            window.sessionStorage.setItem("currentTabCall", props.invite.id)
                            meetingInvitation.acceptInvite(props.invite.id)
                        }}
                    >
                        <IconWrapper>
                            <IconCameraJoin fill="#fff" width="20" height="20" />
                        </IconWrapper>
                        Join meeting
                    </MeetingInvitationJoinButton>
                </CustomRow>
            </>
        )
    }

    return <MeetingNotificationModalRoot>{content}</MeetingNotificationModalRoot>
}

interface ControlButtonProps {
    icon: any
    backgroundColor?: string
    onClick?: () => void
}

export function ActionButton(props: ControlButtonProps) {
    return (
        <div>
            <CustomOverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="settings-tooltip" style={{ pointerEvents: "none" }}>
                        {"Enter label"}
                    </Tooltip>
                }
            >
                <ActionButtonRoot onClick={props.onClick} backgroundColor={props.backgroundColor}>
                    {props.icon}
                </ActionButtonRoot>
            </CustomOverlayTrigger>
        </div>
    )
}

export default HailingOverlayV2
