import styled from "styled-components"
import CenteredLoader from "../../ui/CenteredLoader"
import branding from "../../branding/branding"
import { device } from "../../utils/Device"

export const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
`

/*********************************************************************************************
 * site assembly
 **********************************************************************************************/
export const SpeakersRoot = styled.div`
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
`

export const ContentRoot = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
`

export const TabContentContainer = styled.div<{ currentPage?: string }>`
    position: relative;
    padding: ${(props) =>
        window.scrollY > 0
            ? "25px 25px 15px 25px"
            : props.currentPage === "program"
            ? "10px 25px 15px 25px"
            : "25px 15px 15px 25px"};

    @media (max-height: 1000px) {
        padding-bottom: 40px;
    }

    @media (max-height: 600px) {
        padding-bottom: 65px;
    }
`

export const SectionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const SectionHeaderTitle = styled.div`
    color: ${branding.mainInfoColor ?? "#000"};
    margin-bottom: 10px;

    font-size: 16px;

    font-family: ${branding.exhibitorsPageContent.sectionTitleFontFamily};
    text-transform: ${branding.exhibitorsPageContent.sectionTitleTextTransform};
    font-weight: ${branding.exhibitorsPageContent.sectionTitleFontWeight};
    letter-spacing: ${branding.exhibitorsPageContent.sectionTitleLetterSpacing};

    @media ${device.tablet} {
        font-size: ${branding.exhibitorsPageContent.sectionTitleFontSize};
        margin-bottom: 15px;
    }
`

export const SectionContentContainer = styled.div`
    padding-bottom: 20px;
`

export const SearchBarComponentRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    padding: 10px 25px 15px 25px;
`

export const SearchBarContainer = styled.div`
    svg {
        color: ${branding.primaryColor};
    }
`
