import { createState, State, useState } from "@hookstate/core"
import { useAudioInputs, useAudioOutputs, useMeetingManager, useVideoInputs } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import TestSound from "../components/TestSound"
import { defaultLogger as logger } from "../../globalStates/AppState"
// selected devices from local storage
export const AudioInputStorageKey = "virtualGuide-audioInput"
export const AudioOutputStorageKey = "virtualGuide-audioOutput"
export const VideoInputStorageKey = "virtualGuide-videoInput"

interface StateValues {
    audioInputDevices: DeviceType[] | null
    audioOutputDevices: DeviceType[] | null
    videoInputDevices: DeviceType[] | null

    currentAudioInputDevice: DeviceType | null
    currentAudioOutputDevice: DeviceType | null
    currentVideoInputDevice: DeviceType | null
}

const getStartValues = (): StateValues => {
    return {
        videoInputDevices: [],
        audioInputDevices: [],
        audioOutputDevices: [],
        currentVideoInputDevice: null,
        currentAudioInputDevice: null,
        currentAudioOutputDevice: null
    }
}

export interface DeviceController {
    /**
       This function loads the available devices and if available in the local storage
       it sets the previously selected devices. If not, then it selects the first
       available device from the list of available devices.
     */
    loadDevices: () => Promise<void>
    setCurrentAudioInputDevice: (deviceId: string, label: string) => Promise<void>
    getCurrentAudioInputDevice: () => DeviceType | null
    getAudioInputDevices: () => DeviceType[] | null
    setCurrentAudioOutputDevice: (deviceId: string, label: string) => Promise<void>
    getCurrentAudioOutputDevice: () => DeviceType | null
    getAudioOutputDevices: () => DeviceType[] | null
    testCurrentAudioOutputDevice: () => void
    setCurrentVideoInputDevice: (deviceId: string, label: string) => Promise<void>
    getCurrentVideoInputDevice: () => DeviceType | null
    getVideoInputDevices: () => DeviceType[] | null
    checkAudioInputPermissions: () => Promise<boolean>
    checkVideoInputPermissions: () => Promise<boolean>
}
const state = createState<StateValues>(getStartValues())

const useStateWrapper = (state: State<StateValues>): DeviceController => {
    const meetingManager = useMeetingManager()
    const availableAudioInputDevices = useAudioInputs()
    const availableAudioOutputDevices = useAudioOutputs()
    const availableVideoInputDevices = useVideoInputs()

    return {
        loadDevices: async () => {
            // If available set the selected device from local storage, else set the first available device
            // 1. Set the selected audio input device and the list of available audio input devices
            let audioInputDevices = availableAudioInputDevices.devices
            let currentAudioInputDevice =
                availableAudioInputDevices.devices.find(
                    (device: DeviceType) => device.deviceId === localStorage.getItem(AudioInputStorageKey)
                ) || availableAudioInputDevices.devices[0]
            await meetingManager.startAudioInputDevice(currentAudioInputDevice.deviceId)
            // 2. Set the selected audio output device and the list of available audio output devices
            let audioOutputDevices = availableAudioOutputDevices.devices
            let currentAudioOutputDevice =
                availableAudioOutputDevices.devices.find(
                    (device: DeviceType) => device.deviceId === localStorage.getItem(AudioOutputStorageKey)
                ) || availableAudioOutputDevices.devices[0]
            await meetingManager.startAudioOutputDevice(currentAudioOutputDevice.deviceId)
            // 3. Set the selected video input device and the list of available video input devices
            let videoInputDevices = availableVideoInputDevices.devices
            let currentVideoInputDevice =
                availableVideoInputDevices.devices.find(
                    (device: DeviceType) => device.deviceId === localStorage.getItem(VideoInputStorageKey)
                ) || availableVideoInputDevices.devices[0]

            // Update the state
            state.merge({
                audioInputDevices: audioInputDevices,
                currentAudioInputDevice: currentAudioInputDevice,
                audioOutputDevices: audioOutputDevices,
                currentAudioOutputDevice: currentAudioOutputDevice,
                videoInputDevices: videoInputDevices,
                currentVideoInputDevice: currentVideoInputDevice
            })

            // Update meeting manager
            // For some reason not available for audio input and audio output
            meetingManager.selectVideoInputDevice(currentVideoInputDevice)
        },
        setCurrentAudioInputDevice: async (deviceId: string, label: string) => {
            localStorage.setItem(AudioInputStorageKey, deviceId)
            await meetingManager.startAudioInputDevice(deviceId)
            state.set((prevState) => {
                prevState.currentAudioInputDevice = { deviceId: deviceId, label: label }
                return prevState
            })
        },
        getCurrentAudioInputDevice: () => {
            return state.value.currentAudioInputDevice
        },
        getAudioInputDevices: () => {
            return state.value.audioInputDevices
        },
        setCurrentAudioOutputDevice: async (deviceId: string, label: string) => {
            localStorage.setItem(AudioOutputStorageKey, deviceId)
            await meetingManager.startAudioOutputDevice(deviceId)
            state.set((prevState) => {
                prevState.currentAudioOutputDevice = { deviceId: deviceId, label: label }
                return prevState
            })
        },
        getCurrentAudioOutputDevice: () => {
            /** No audio output on Firefox or Safari */
            /** https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/07_FAQs.md#i-am-unable-to-select-an-audio-output-device-in-some-browsers-is-this-a-known-issue */
            return state.value.currentAudioOutputDevice
        },
        getAudioOutputDevices: () => {
            return state.value.audioOutputDevices
        },
        testCurrentAudioOutputDevice: () => {
            new TestSound(state.value.currentAudioOutputDevice?.deviceId || "", 440, 2)
        },
        setCurrentVideoInputDevice: async (deviceId: string, label: string) => {
            localStorage.setItem(VideoInputStorageKey, deviceId)
            await meetingManager.startVideoInputDevice(deviceId)
            state.set((prevState) => {
                prevState.currentVideoInputDevice = { deviceId: deviceId, label: label }
                return prevState
            })
        },
        getCurrentVideoInputDevice: () => {
            return state.value.currentVideoInputDevice
        },
        getVideoInputDevices: () => {
            return state.value.videoInputDevices
        },
        checkAudioInputPermissions: async () => {
            let perm = false
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(function (stream) {
                    perm = true
                })
                .catch(function (err) {
                    perm = false
                })

            return perm
        },
        checkVideoInputPermissions: async () => {
            navigator.mediaDevices
                .getUserMedia({ video: true })
                .then(function (stream) {
                    return true
                })
                .catch(function (err) {
                    logger.info("Camera usage is forbidden")
                })

            return false
        }
    }
}

export const useDeviceController = (): DeviceController => useStateWrapper(useState(state))
