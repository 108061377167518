import { useLocalVideo } from "amazon-chime-sdk-component-library-react"
import LocalAvatar from "../LocalAvatar/LocalAvatar"
import LocalVideo from "../LocalVideo/LocalVideo"
import { LocalVideoRoot } from "./LocalTile.styled"

interface LocalVideoProps {
    isLocalTileVisibleToUser: boolean
}
function LocalTile(props: LocalVideoProps) {
    const { isVideoEnabled } = useLocalVideo()
    return (
        <LocalVideoRoot isLocalTileVisibleToUser={props.isLocalTileVisibleToUser}>
            {isVideoEnabled ? <LocalVideo /> : <LocalAvatar />}
        </LocalVideoRoot>
    )
}

export default LocalTile
