import { useNavigationSource } from "../../../tracking/NavigationSource"
import { News } from "../../../backendServices/Types"
import { buildDetailLink, DetailNavLink } from "../../../contentArea/detailPages/DetailNavLink"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import * as React from "react"
import branding, { TopNews } from "../../../branding/branding"
import ActionsWrapper from "../../../contentArea/entitiesActions/ActionsWrapper"
import { device } from "../../../utils/Device"
import useWindowDimensions from "../../WindowDimensionsHook"
import { BasisPremiumType } from "../../../branding/BasisPremiumBranding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { getNewsDateFormatted } from "../../../contentArea/detailPages/components/DetailPageSections"
import { trackNewsAction } from "../../../backendServices/TrackingServices"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { trackSelectContent, trackViewSearchResult } from "../../../utils/GTMTracking"
import { useEffect, useRef } from "react"
import { homePageRoute } from "../../../navigationArea/RoutePaths"

interface NewsProps {
    news: News
    lobby?: boolean
}

export const NewsComponent: React.FunctionComponent<NewsProps> = (props) => {
    const user = useLoggedInState().user()
    const nav = useNavigationSource()
    const { isMobile } = useWindowDimensions()
    const lang = useLanguageState().getLanguage()
    const targetRef = useRef(null)

    const getBackground = (): string => {
        let item: TopNews[] = branding.receptionPage.topNewsList.filter((n: TopNews) => n.id === props.news.id)

        if (item.length > 0 && item[0].background.length > 0) {
            return item[0].background
        }

        return "/branding/applicationMedia/lobby-news-placeholder.png"
    }

    const callback = (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                trackViewSearchResult(
                    props.news.id,
                    props.news.name,
                    "News",
                    window.location.pathname === homePageRoute ? "Home" : "Collection"
                )
            }
        })
    }

    useEffect(() => {
        const targetElement = targetRef.current

        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }

        const observer = new IntersectionObserver(callback, options)

        if (targetElement) {
            observer.observe(targetElement)
        }

        return () => {
            if (targetElement) {
                observer.unobserve(targetElement)
            }
        }
    }, []) // eslint-disable-line

    const content: JSX.Element = (
        <NewsComponentRoot ref={targetRef}>
            <Tile
                onClick={() => {
                    trackSelectContent(props.news.name, "Details", "News")
                    trackNewsAction(user?.profileId!, "news", props.news.id)
                    nav.set(props.lobby ? "LOBBY" : "COLLECTION")
                }}
                $background={getBackground()}
                className={isMobile ? "isMobile" : ""}
            >
                <NavLink
                    to={buildDetailLink(props.news.organization?.id || "", props.news.organization?.name || "", "organization")}
                >
                    <Logo url={props.news.organization?.pictureURL} initials={props.news.initials} />
                </NavLink>
                <Actions news={props.news} />
                <Infos className={isMobile ? "isMobile" : ""}>
                    <InfosItem className={"date " + (isMobile ? "isMobile" : "")}>
                        {props.news.date ? getNewsDateFormatted(props.news.date, lang) : ""}
                    </InfosItem>

                    <InfosItem className={isMobile ? "isMobile" : ""}>{props.news.name}</InfosItem>
                    <InfosItem className={isMobile ? "isMobile" : ""}>{props.news.descriptionTeaser}</InfosItem>
                </Infos>
            </Tile>
        </NewsComponentRoot>
    )

    return (
        <>
            {props.news.externalUrl ? (
                <a href={props.news.externalUrl} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            ) : (
                <DetailNavLink
                    key={props.news.id}
                    id={props.news.id}
                    type="news"
                    name={props.news.name}
                    source={props.lobby ? "LOBBY" : "COLLECTION"}
                    onClick={() => {
                        trackSelectContent(props.news.name, "Details", "news")
                    }}
                >
                    {content}
                </DetailNavLink>
            )}
        </>
    )
}

/* #region  Styled Components */

export const NewsComponentRoot = styled.div`
    border-radius: 5px;
`

const IconContainer = styled.div`
    margin-top: 15px;
    margin-left: 85px;
    color: white;
    display: flex;
    justify-content: center;

    span {
        margin-left: 2px;
        margin-top: 0px;
    }
`

const Tile = styled.div<{ $background?: string }>`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 95px;
    width: 205px;
    color: white !important;
    background: ${(props) => "url(" + props.$background + ")"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; /* left top */
    padding: 25px 25px 25px 8px;
    border-radius: 5px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};

    ${IconContainer} {
        margin-top: -5px;
        margin-left: 0;
        margin-right: 20px;
    }

    .rs-row {
        position: initial;

        div {
            margin-top: 5px;
            width: 50px;
            height: 50px;
            display: none;
            img {
                width: 40px;
                height: 40px;
                margin-top: 3px;
            }
        }

        div:first-child {
            display: initial;
        }
    }

    &:active,
    &:link {
        text-decoration: none;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.receptionPage.lobbypageShowfloorTilesDarkenOverlay ?? "rgba(0,0,0, .5)"};
        border-radius: 5px;
    }

    & > * {
        z-index: 2;
    }

    @media ${device.tablet} {
        height: 280px;
        width: 500px;
        padding-left: 25px;

        &.isMobile {
            height: 95px;
            width: 205px;
            padding: 25px 25px 25px 8px;
        }
    }

    @media ${device.mobile} {
        height: 95px;
        width: 205px;
        padding: 25px 25px 25px 8px;
    }
`
export const InfosItem = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    width: 110px;
    margin-top: 10px;
    margin-bottom: 5px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media ${device.tablet} {
        font-size: 14px;
        line-height: 17px;
        width: 460px;
        margin-top: inherit;
        -webkit-line-clamp: 3;

        &.isMobile {
            display: flex;
            font-family: ${branding.font1};
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 10px !important;
            line-height: 12px !important;
            width: auto !important;
            margin-top: 10px !important;
            margin-bottom: 0px;

            &.date {
                display: none;
            }
        }
    }

    @media ${device.mobile} {
        display: flex;
        font-family: ${branding.font1};
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 10px !important;
        line-height: 12px !important;
        width: auto !important;
        margin-top: 10px !important;
        margin-bottom: 0px;

        &.date {
            display: none;
        }
    }
`

export const Infos = styled.div`
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 5px;
    margin-left: 75px;

    ${InfosItem}:nth-child(2) {
        width: 103px;
        font-weight: bold !important;
        font-size: 14px !important;
        line-height: 16px !important;
        -webkit-line-clamp: 3;

        @media ${device.tablet} {
            width: unset;
            font-size: 18px !important;
            line-height: 22px !important;
            line-height: unset;

            &.isMobile {
                width: 103px;
                font-weight: bold !important;
                font-size: 12px !important;
                line-height: 14px !important;
                -webkit-line-clamp: 3;
            }
        }

        @media ${device.mobile} {
            width: 103px;
            font-weight: bold !important;
            font-size: 12px !important;
            line-height: 14px !important;
            -webkit-line-clamp: 3;
        }
    }

    @media ${device.tablet} {
        top: unset;
        bottom: 25px;
        margin-left: unset;

        &.isMobile {
            top: 5px;
            margin-left: 75px;
            bottom: unset;
        }
    }

    @media ${device.mobile} {
        top: 5px;
        margin-left: 75px;
        bottom: unset;
    }
`

/* #endregion */

/* #region  Actions */

const ActionsRoot = styled.div`
    display: flex;
    position: absolute;
    right: 10px;
    justify-content: flex-end;

    div {
        position: relative;
    }
`

interface ActionsProps {
    news: News
}
function Actions(props: ActionsProps) {
    const { news } = props
    const { isMobile } = useWindowDimensions()

    if (isMobile) return null
    return (
        <ActionsRoot>
            <ActionsWrapper
                layout="lobby-layout"
                organization={news}
                entity={{ id: news.id, entityType: "news" }}
                exhibitorHasContacts={false}
                basisPremiumType={BasisPremiumType.BASIC}
            />
        </ActionsRoot>
    )
}

/* #endregion */

/* #region  CompanyLogo */

const LogoRoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 60px;
    height: 60px;
    padding: 2px;
    margin-top: -10px;

    & p {
        font-family: ${branding.font1};
        max-width: 40px;
        font-size: 14px;
        line-height: 0.6rem;
        color: rgb(0, 0, 0);
        text-align: center;
        display: inline-table;
        margin-bottom: 0;
        white-space: normal;
        word-break: break-word;
    }

    @media ${device.tablet} {
        width: 80px;
        height: 80px;
        padding: 10px;
        margin: inherit;

        &.isMobile {
            width: 60px;
            height: 60px;
            padding: 2px;
            margin-top: -10px;
        }
    }

    @media ${device.mobile} {
        width: 60px;
        height: 60px;
        padding: 2px;
        margin-top: -10px;
    }
`

const LogoImg = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`

interface LogoProps {
    url?: string
    initials?: string
}
export function Logo(props: LogoProps) {
    const { url, initials } = props
    const { isMobile } = useWindowDimensions()

    let logo
    if (url) logo = <LogoImg src={url} />
    else if (branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles)
        logo = <LogoImg src={"/branding/applicationMedia/rsz_defaultgrey.png"} />
    else logo = <p style={{ fontFamily: branding.font1 }}>{initials}</p>

    return <LogoRoot className={isMobile ? "isMobile" : ""}>{logo}</LogoRoot>
}

/* #endregion */
