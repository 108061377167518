import { darkTheme } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import { useDeviceController } from "../../context/DeviceController"
import { DeviceSelect } from "../Settings/Settings.styled"

function CameraSelection() {
    const { getCurrentVideoInputDevice, getVideoInputDevices, setCurrentVideoInputDevice } = useDeviceController()
    return (
        <DeviceSelect
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            theme={darkTheme}
            placeholder={getCurrentVideoInputDevice()?.label}
            options={getVideoInputDevices()}
            value={getCurrentVideoInputDevice()?.deviceId}
            onChange={async (device: DeviceType) => {
                setCurrentVideoInputDevice(device.deviceId, device.label)
            }}
        />
    )
}

export default CameraSelection
