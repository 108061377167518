import * as React from "react"
import styled from "styled-components"
import BookmarkWithToggle from "./BookmarkWithToggle"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { Row, Col, Tooltip } from "react-bootstrap"
import branding from "../branding/branding"
import { Person, ShareTargetType } from "../backendServices/Types"
import { AvatarWithDefault } from "./AvatarWithDefault"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { useFavoriteState } from "../globalStates/Favorites"
import { CustomOverlayTrigger } from "./CustomOverlayTrigger"
import { ShareButton } from "./modals/ShareButton"
import { trackSelectContent } from "../utils/GTMTracking"

const UserImageDiv = styled.div`
    display: flex;
    align-items: center;
    width: 95px;
    height: 95px;
    object-fit: contain;

    .avatar-styled {
        width: 95px;
        height: 95px;
        line-height: 95px;
        font-size: 30px;
    }

    @media (max-width: 1200px) {
        width: 30px;
        height: 30px;

        .avatar-styled {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 15px;
        }
    }
`

interface SpeakersListLayoutProps {
    speakersList: Person[]
    customBookmarkFunction?: (id: string) => void
}

const SpeakerRowRoot = styled(Row)`
    padding: 25px 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`

const SpeakerTitle = styled.p`
    font-family: ${branding.programSpeakers.listViewTitleFont};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    color: ${branding.programSpeakers.listViewTitleColor ?? "#000"};
`

const SpeakerPosition = styled.p`
    font-family: ${branding.font1};
    font-style: normal;
    font-size: 14px;
    color: ${branding.programSpeakers.speakerColInfoTextColor ?? "#000"};
`

const SpeakerIconsRoot = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0;

    & svg {
        vertical-align: top;
    }
`

const SpeakersListRoot = styled.div`
    ${SpeakerRowRoot} {
        border-bottom: 1px solid ${branding.listDividerColor};
    }

    & > :last-child {
        border-bottom: none;
    }
`

const SpeakersListLayout: React.FC<SpeakersListLayoutProps> = (props: SpeakersListLayoutProps) => {
    return (
        <SpeakersListRoot>
            {props.speakersList &&
                props.speakersList.map((person, key) => {
                    return (
                        <SpeakerRowRoot>
                            <Col className="p-0" xs={8}>
                                <DetailNavLink
                                    source="BOOKMARK"
                                    id={person.id}
                                    type={"person"}
                                    name={`/person/${person.firstName}_${person.lastName}`}
                                    onClick={() => {
                                        trackSelectContent(person.firstName! + " " + person.lastName!, "Details", "Person")
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                                        <UserImageDiv>
                                            <AvatarWithDefault
                                                size={90}
                                                src={person.logoUrl ?? person.userLogoUrl}
                                                alt={[person.firstName, person.lastName].join(" ") ?? "#"}
                                                backgroundSize="cover"
                                            />
                                        </UserImageDiv>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginLeft: "25px",
                                                justifyContent: "space-beteen"
                                            }}
                                        >
                                            <SpeakerTitle>
                                                {[person.firstName, person.lastName].filter(Boolean).join(" ")}
                                            </SpeakerTitle>
                                            <SpeakerPosition>
                                                {[person.position, person.organization]
                                                    .filter(Boolean)
                                                    .join(" " + branding.communicationArea.personCompanyLink + " ")}
                                            </SpeakerPosition>
                                        </div>
                                    </div>
                                </DetailNavLink>
                            </Col>
                            <SpeakerIconsRoot xs={4}>
                                <ShareBookmarkDiv>
                                    <ShareBookmarkIcons
                                        personId={person.id}
                                        personName={[person.firstName, person.lastName].filter(Boolean).join(" ")}
                                        customBookmarkFunction={props.customBookmarkFunction}
                                    />
                                </ShareBookmarkDiv>
                            </SpeakerIconsRoot>
                        </SpeakerRowRoot>
                    )
                })}
        </SpeakersListRoot>
    )
}

export default SpeakersListLayout

const ShareBookmarkDiv = styled.div`
    float: right;
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    color: ${branding.programSpeakers.speakerColInfoTextColor ?? "#000"};
`

interface ShareBookmarkIconsProps {
    personId: string
    personName: string
    customBookmarkFunction?: (id: string) => void
}

const ShareBookmarkIcons: React.FunctionComponent<ShareBookmarkIconsProps> = (props) => {
    const favorites = useFavoriteState()
    const isBookmarked = favorites.is("person", props.personId)
    const link = buildDetailLink(
        props.personId,
        `/person/${props.personName.split(" ")[0]}_${props.personName.split(" ")[1]}`,
        "person"
    )
    return (
        <>
            <div>
                <ShareButton
                    targetType={ShareTargetType.PERSON}
                    targetId={props.personId}
                    targetName={props.personName}
                    link={link}
                    iconProps={{ fill: branding.sideIconBar.sideIconColorDark }}
                    iconContainerStyle={{ cursor: "pointer", marginRight: "20px" }}
                />
            </div>

            <CustomOverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>
                        {isBookmarked
                            ? branding.organizationDetailPageContent.removeFavoritePerson
                            : branding.organizationDetailPageContent.favoritePerson}
                    </Tooltip>
                }
            >
                <div>
                    <BookmarkWithToggle
                        newBookmarkItem={true}
                        fontSize={"25px"}
                        color={branding.sideIconBar.sideIconColorDark}
                        favIconBasic={true}
                        type="person"
                        favouriteButton={true}
                        id={props.personId}
                        name={props.personName}
                        customFunction={() => {
                            if (props.customBookmarkFunction) {
                                props.customBookmarkFunction(props.personId)
                            }
                        }}
                    />
                </div>
            </CustomOverlayTrigger>
        </>
    )
}
