import styled from "styled-components"
import branding from "../../../branding/branding"

export const SwitcherContainer = styled.ul`
    margin-top: 15px;
    cursor: pointer;
    svg {
        margin-right: 8px;
    }
`

export const SwitcherItem = styled.li<{ isActive: boolean }>`
    font-family: ${branding.font1};
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    line-height: 17px;
    padding: 8px;
    border-radius: 3px;
    margin-bottom: 2px;
    background-color: ${(props) => (props.isActive ? "#666666" : "#202428")};
    transition: 0.3s;
    position: relative;
    :hover {
        background-color: rgba(102, 102, 102, 0.3);
    }
`

export const ToggleWrapper = styled.div`
    display: inline-block;
    position: absolute;
    top: 3px;
    right: 0;
    width: 20%;
    height: 20px;
    z-index: 10;
`

export const IconWrapper = styled.div`
    width: 80%;
`
