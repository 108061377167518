import { useState } from "react"
import * as React from "react"
import { meetingPageRoute } from "../navigationArea/RoutePaths"
import { useRouteMatch } from "react-router-dom"
import { IconClearSearchItem, IconClearSearchItemLarge, IconSearch, IconSearchSmall } from "./Icons"
import branding from "../branding/branding"
import { TextField } from "./TextField"
import styled from "styled-components"

const SearchBarContainer = styled.div`
    #searchIcon {
        margin-left: ${branding.businessAreaPageContent.searchBarIconMargin} !important;
    }

    #egFocusTextInput {
        padding-left: ${branding.businessAreaPageContent.searchBarTextMargin} !important;
    }
`
interface SearchBarProps {
    setSearchParam: (setSearchParam: string) => void
    searchValue: string
    placeholder: string
    height?: string
    width: string
    bgColor?: string
    isSideBarSearchBar?: boolean
    largeIcon?: boolean
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    clearIconPressed?: () => void
    isSearchBar?: boolean
    bottom?: string
    bottomEnd?: string
}

const SearchBar: React.FunctionComponent<SearchBarProps> = (props: SearchBarProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)
    // eslint-disable-next-line
    const [isFocused, setIsFocused] = useState(false)

    // eslint-disable-next-line
    const [placeholder, setPlaceholder] = useState("")
    // const appState = useAppState()

    function handleClick() {
        setIsFocused(false)
        // appState.clearSuggestParam()
        props.setSearchParam("")
        // props.searchValue = ""
        setPlaceholder(props.placeholder)

        if (props.clearIconPressed) props.clearIconPressed()
    }

    return (
        <SearchBarContainer>
            <TextField
                backgroundColor={
                    props.isSideBarSearchBar && isMeetingPage
                        ? branding.mainInfoColor
                        : branding.communicationArea.searchBarBackgroundColor ?? "transparent"
                }
                textColor={
                    isFocused
                        ? "#202428"
                        : props.isSideBarSearchBar && isMeetingPage
                        ? "white"
                        : branding.businessAreaPageContent.searchTermTextColor
                }
                width={props.width}
                placeholder={isFocused ? "" : props.placeholder}
                fontSize={branding.searchBarFontSize}
                height={props.height}
                fontFamily={branding.searchBarFontFamily}
                textTransform={branding.searchBarTextTransform}
                fontWeight={branding.searchBarFontWeight}
                letterSpacing={branding.searchBarLetterSpacing}
                value={props.searchValue || ""}
                setValue={props.setSearchParam}
                search={true}
                isSearchBar={true}
                onFocusToggle={setIsFocused}
                onKeyPress={props.onKeyPress}
                borderAround={isFocused ? "1px solid #727272" : branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"}
                borderRadius={"5px"}
                bottom={props.bottom}
                bottomEnd={props.bottomEnd}
                startAdornment={
                    <>
                        <span
                            id="searchIcon"
                            style={{
                                color: props.isSideBarSearchBar
                                    ? isMeetingPage
                                        ? "white"
                                        : branding.primaryColor ?? "black"
                                    : branding.mainInfoColor ?? "black"
                            }}
                        >
                            {props.largeIcon
                                ? IconSearch({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })
                                : IconSearchSmall({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                        </span>
                    </>
                }
                endAdornment={
                    <>
                        {
                            <span
                                style={{
                                    color: props.isSideBarSearchBar
                                        ? isMeetingPage
                                            ? "white"
                                            : branding.mainInfoColor ?? "black"
                                        : branding.mainInfoColor ?? "black",
                                    paddingRight: "7px",
                                    cursor: "pointer",
                                    visibility: props.searchValue !== "" ? "visible" : "hidden",
                                    float: "right"
                                }}
                                onClick={handleClick}
                            >
                                {props.largeIcon
                                    ? IconClearSearchItemLarge({
                                          fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                      })
                                    : IconClearSearchItem({
                                          fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                      })}
                            </span>
                        }
                    </>
                }
            />
        </SearchBarContainer>
    )
}

export default SearchBar
