import {
    useRosterState,
    useRemoteVideoTileState,
    RosterAttendeeType,
    useContentShareState,
    useActiveSpeakersState
} from "amazon-chime-sdk-component-library-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import branding from "../../../branding/branding"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { IconChevronLeftSolid, IconChevronRightSolid } from "../../../ui/Icons"
import ContentShare from "../ContentShare/ContentShare"
import RemoteTile from "../RemoteTile/RemoteTile"
import StageNotifications from "../StageNotification/StageNotifications"
import { StageIndicators } from "./StageIndicators/StageIndicators"
import {
    Pagination,
    PaginationBar,
    PaginationButton,
    PaginationControls,
    StageRoot,
    TextCanvas,
    VideoTilesGrid
} from "./Stage.styled"
import { throttle } from "lodash"
export function Stage() {
    const { roster } = useRosterState()
    const userState = useLoggedInState()
    const [attendees, setAttendees] = useState<RosterAttendeeType[]>(
        Object.values(roster).filter(
            (attendee: any) => attendee.role !== "recorder" && attendee.externalUserId !== userState.user()?.profileId
        )
    )
    const { sharingAttendeeId } = useContentShareState()
    const { attendeeIdToTileId } = useRemoteVideoTileState()
    const tilesPerPage = 16
    const activeSpeakerState = useActiveSpeakersState()
    const [currentPage, setCurrentPage] = useState(1)
    const [gridName, setGridName] = useState("")
    const throttleInterval = 10000
    const [attendeesFinal, setAttendeesFinal] = useState<RosterAttendeeType[]>(attendees)
    let isFirstLoad = true

    useEffect(() => {
        setAttendees(
            Object.values(roster).filter(
                (attendee: any) => attendee.role !== "recorder" && attendee.externalUserId !== userState.user()?.profileId
            )
        )
        // eslint-disable-next-line
    }, [roster])

    function isRecorder(attendee: any) {
        return attendee.role === "recorder"
    }
    function filterRecorderAndLoggedInUser() {
        return Object.values(roster).filter(
            (attendee: any) => !isRecorder(attendee) && !userState.isLoggedInUser(attendee.externalUserId)
        )
    }
    useEffect(() => {
        setAttendees(filterRecorderAndLoggedInUser)
        // eslint-disable-next-line
    }, [roster])

    /** Logic for sorting remote tiles */
    const calculateSortingScore = (attendee: RosterAttendeeType, activeSpeakers: string[], activeCameras: string[]) => {
        let score = 0
        /**Priorities for sorting:
         * 1. Users with camera on and talking
         * 2. Users with camera on and not talking
         * 3. Users with camera off and talking
         * 4. Users with camera off and not talking
         */

        /** This logic could be more improved but for now lets keep it like it is.
         * We could also improve this logic to add two more parameters in the sorting conditions,
         * for example check if the user is muted and also if the user has hand raised.
         * The idea is to push the user with raised hands even more on top, and muted users more on the bottom.
         * Currently there is no way to implement this since the SDK is limited. The hook useAttendeeStatus
         * has this data, but it does not fit this case, since hooks can only be used in root of the component.
         * https://aws.github.io/amazon-chime-sdk-component-library-react/?path=/docs/sdk-hooks-useattendeestatus--page
         * The raised hand parameter could maybe be used, if there is need for it.
         */

        // Camera on and talking
        if (
            activeCameras.length &&
            activeCameras.includes(attendee.chimeAttendeeId) &&
            activeSpeakers.includes(attendee.chimeAttendeeId)
        ) {
            score += 4 + activeSpeakers.indexOf(attendee.chimeAttendeeId)
        }

        // Camera on and muted or not talking
        if (
            activeCameras.length &&
            activeCameras.includes(attendee.chimeAttendeeId) &&
            !activeSpeakers.includes(attendee.chimeAttendeeId)
        ) {
            score += 3
        }

        // Camera off and talking or not muted
        if (
            activeCameras.length &&
            !activeCameras.includes(attendee.chimeAttendeeId) &&
            activeSpeakers.includes(attendee.chimeAttendeeId)
        ) {
            score += 2 + activeSpeakers.indexOf(attendee.chimeAttendeeId)
        }
        // Camera off and muted or not talking
        if (
            activeCameras.length &&
            !activeCameras.includes(attendee.chimeAttendeeId) &&
            !activeSpeakers.includes(attendee.chimeAttendeeId)
        ) {
            score += 1
        }
        return score
    }

    const sortTiles = throttle((activeSpeakers, activeCameras, attendees) => {
        // 5.
        const sortedAttendees = [...attendees].sort(
            (a, b) =>
                calculateSortingScore(b, activeSpeakers, activeCameras) - calculateSortingScore(a, activeSpeakers, activeCameras)
        )
        setAttendeesFinal(sortedAttendees)
    }, throttleInterval) // Throttle to once per second

    // Create a memoized version of the throttled function
    const throttledFunctionMemoized = useMemo(() => {
        // 4.
        return sortTiles
        // eslint-disable-next-line
    }, []) // This array of dependencies ensures that the function is memoized once

    // Create a memoized callback using the throttled function
    const throttledCallback = useCallback(() => {
        // 3.
        throttledFunctionMemoized(activeSpeakerState, Object.keys(attendeeIdToTileId), attendees) // Pass any required parameters to the throttled function
    }, [attendees, activeSpeakerState, attendeeIdToTileId, throttledFunctionMemoized]) // The callback will only change if count or the throttled function changes

    useEffect(() => {
        // 2.
        throttledCallback()
        // eslint-disable-next-line
    }, [attendees, activeSpeakerState, attendeeIdToTileId])

    useEffect(() => {
        // 1.
        console.log("SORT OUTPUT: ", attendeesFinal)
    }, [attendeesFinal])

    useEffect(() => {
        if (isFirstLoad) setAttendeesFinal(attendees)
        isFirstLoad = false // eslint-disable-line
        // eslint-disable-next-line
    }, [attendees])

    /** End of Logic for sorting remote tiles */

    useEffect(() => {
        attendeesFinal.length > tilesPerPage
            ? setGridName(
                  `videos-${attendeesFinal.slice((currentPage - 1) * tilesPerPage, currentPage * tilesPerPage).length.toString()}`
              )
            : setGridName(`videos-${attendeesFinal.length.toString()}`)
        // eslint-disable-next-line
    }, [attendeesFinal, currentPage, tilesPerPage])

    return (
        <StageRoot>
            <>
                <StageNotifications />
                <StageIndicators />
                {!sharingAttendeeId && attendeesFinal.length >= 1 && (
                    <VideoTilesGrid className={gridName}>
                        {attendeesFinal &&
                            attendeesFinal
                                .slice((currentPage - 1) * tilesPerPage, currentPage * tilesPerPage)
                                .map((attendee: any, key: number) => {
                                    return (
                                        <RemoteTile
                                            key={attendee.tileId}
                                            attendee={attendee}
                                            isVideoTile={Object.keys(attendeeIdToTileId).includes(attendee.chimeAttendeeId)}
                                            tileId={attendeeIdToTileId[attendee.chimeAttendeeId]}
                                        />
                                    )
                                })}
                    </VideoTilesGrid>
                )}
                {sharingAttendeeId && <ContentShare />}
                {!sharingAttendeeId && attendeesFinal.length < 1 && (
                    <TextCanvas>{branding.conferenceTexts.noAttendees}</TextCanvas>
                )}
                {/* Pagination of tiles */}
                {attendeesFinal.length > tilesPerPage && !sharingAttendeeId && (
                    <PaginationBar>
                        <PaginationControls>
                            <PaginationButton
                                disabled={currentPage === 1}
                                onClick={() => {
                                    if (currentPage === 1) return
                                    setCurrentPage(currentPage - 1)
                                }}
                            >
                                <IconChevronLeftSolid width="10" height="10" />
                            </PaginationButton>
                            <Pagination>{`${currentPage} / ${Math.ceil(attendeesFinal.length / tilesPerPage)}`}</Pagination>
                            <PaginationButton
                                disabled={currentPage === Math.ceil(attendeesFinal.length / tilesPerPage)}
                                onClick={() => {
                                    if (currentPage === Math.ceil(attendeesFinal.length / tilesPerPage)) return
                                    setCurrentPage(currentPage + 1)
                                }}
                            >
                                <IconChevronRightSolid width="10" height="10" />
                            </PaginationButton>
                        </PaginationControls>
                    </PaginationBar>
                )}
            </>
        </StageRoot>
    )
}

export default Stage
