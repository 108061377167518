import React from "react"
import { CalendarEntry, CalendarEntryParticipation } from "../../../backendServices/GraphQLServices"
import { InvitePerson } from "../../../backendServices/Types"
import { User } from "../../../globalStates/LoggedInUser"
import { AvatarWithDefault } from "../../AvatarWithDefault"
import { IconClose } from "../../Icons"
import {
    AvatarWithDefaultBackground,
    ButtonBackground,
    IconCloseBackground,
    ParticipantName,
    ParticipantStatusName,
    ParticipantWrapper
} from "./CalendarEntryModal.styled"
import {
    CalendarEntryModalViewMode,
    CalendarEntryParticipationStatus,
    openDetailPage,
    showParticipantStatus,
    showUsername
} from "./ModalFunctions"
import branding from "../../../branding/branding"

interface ModalParticipantButtonProps {
    contact: InvitePerson
    onClickRemovePerson: () => void
    meetingOrganizer: InvitePerson | undefined
    user: User | undefined
    label: string
    item?: CalendarEntryParticipation
    viewMode?: CalendarEntryModalViewMode
    calendarEntry?: CalendarEntry
    status: string
    participantStatus?: string
}

const ModalParticipantButton = (props: ModalParticipantButtonProps) => {
    const statusForViewColor =
        props.participantStatus === CalendarEntryParticipationStatus.ACCEPTED
            ? branding.calendarEntryModalPageContent.participationStatusViewModeAcceptedColor
            : props.participantStatus === CalendarEntryParticipationStatus.DECLINED
            ? branding.calendarEntryModalPageContent.participationStatusViewModeDeclinedColor
            : branding.calendarEntryModalPageContent.participationStatusViewModeRequestedColor

    const statusForViewText =
        props.participantStatus === CalendarEntryParticipationStatus.ACCEPTED
            ? branding.calendarEntryModalPageContent.calendarEntryParticipationStatusAccepted
            : props.participantStatus === CalendarEntryParticipationStatus.DECLINED
            ? branding.calendarEntryModalPageContent.calendarEntryParticipationStatusDeclined
            : branding.calendarEntryModalPageContent.calendarEntryParticipationStatusRequested

    const statusForEditAndCreate =
        props.status === "Free"
            ? branding.calendarEntryModalPageContent.invitedUserFreeStatusTextColor
            : branding.calendarEntryModalPageContent.invitedUserBusyStatusTextColor

    const freeOrBusyStatus =
        props.status === "Free" ? branding.calendarEntryModalPageContent.available : branding.calendarEntryModalPageContent.busy

    return (
        <ButtonBackground>
            <ParticipantWrapper>
                <AvatarWithDefaultBackground onClick={() => openDetailPage(props.contact!, props.user!)}>
                    <AvatarWithDefault size={27} src={props.contact.logoUrl} alt={props.label} backgroundSize="cover" />
                </AvatarWithDefaultBackground>

                <ParticipantStatusName>
                    <ParticipantName>
                        {showUsername(props.viewMode, props.contact.lastName, props.contact.firstName, props.contact.name)}
                    </ParticipantName>
                    {showParticipantStatus(
                        props.viewMode,
                        statusForViewColor,
                        statusForEditAndCreate,
                        freeOrBusyStatus,
                        props.calendarEntry,
                        props.contact,
                        statusForViewText
                    )}
                </ParticipantStatusName>
                {props.meetingOrganizer?.id === props.contact!.id ||
                props.viewMode === CalendarEntryModalViewMode.VIEW ? undefined : (
                    <IconCloseBackground onClick={props.onClickRemovePerson}>
                        <IconClose fill="#727272" width="7px" height="7px" />
                    </IconCloseBackground>
                )}
            </ParticipantWrapper>
        </ButtonBackground>
    )
}

export default ModalParticipantButton
