import { MicrophoneActivity, useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { useContext, useEffect, useState } from "react"
import branding from "../../../branding/branding"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { AvatarWithDefault } from "../../../ui/AvatarWithDefault"
import { IconHandYellow } from "../../../ui/Icons"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"

import { LocalAvatarContainer, LocalAvatarRoot, LocalAvatarNamePlate } from "./LocalAvatar.styled"

function LocalAvatar() {
    const userState = useLoggedInState()
    const name = userState?.user()?.firstName || "" + userState?.user()?.lastName || ""
    const avatarUrl = useLoggedInState()?.user()?.logoUrl
    const meetingManager = useMeetingManager()
    const raisedHands = useContext(RaisedHandContext)
    const [chimeAttendeeId, setChimeAttendeeId] = useState(meetingManager.meetingSession?.configuration.credentials?.attendeeId)
    useEffect(() => {
        setChimeAttendeeId(meetingManager.meetingSession?.configuration.credentials?.attendeeId)
    }, [meetingManager.meetingSession?.configuration.credentials?.attendeeId])

    return (
        <LocalAvatarRoot>
            <LocalAvatarContainer isHandRaised={false} isSpeaking={false}>
                <AvatarWithDefault size={50} badgesize={500} alt={name} src={avatarUrl} backgroundSize="cover" />
            </LocalAvatarContainer>
            <LocalAvatarNamePlate>
                {raisedHands &&
                    raisedHands.includes(meetingManager.meetingSession?.configuration.credentials?.externalUserId || "") && (
                        <IconHandYellow height="18px" width="18px" />
                    )}
                {branding.conferenceTexts.localVideoLabel}
                {chimeAttendeeId && <MicrophoneActivity className="activity" attendeeId={chimeAttendeeId} />}
            </LocalAvatarNamePlate>
        </LocalAvatarRoot>
    )
}

export default LocalAvatar
