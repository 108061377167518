import { useState } from "react"
import * as React from "react"
import {
    CalendarEntry,
    CalendarEntryParticipation,
    updateCalendarEntryParticipationStatus,
    deleteCalendarEntryForUser
} from "../backendServices/GraphQLServices"
import { IconCheckmark, IconClose, IconEdit, IconSearch, IconChat, IconCamera, IconCalendarEntryDownload } from "../ui/Icons"
import moment from "moment"
import styled from "styled-components"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { CalendarEntryParticipationStatus, ConversationType } from "../API"
import { CalendarEntryModalViewMode } from "../ui/modals/CalendarEntryModal"
import branding from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"
import "moment/locale/de" // TODO better way to implement locale de/en?
import { useAppState } from "../globalStates/AppState"
import { ChatConversationParam } from "./ChatPage"
import { saveMeetings } from "./ICal"
import ContextMenu, { HoverButton, MenuItemProps } from "../ui/ContextMenu"
import { useChimeContext } from "../conference/context/ChimeContext"
import AvatarWithPresenceState from "../ui/AvatarWithPresenceState"
import { isExplorationOrPostEventPhase } from "../utils/EventPhaseChecker"
import { getIamPartOf } from "../globalStates/IAmPartOf"
import queryString from "query-string"
import DeleteCalendarEntryModal from "../ui/modals/DeleteCalendarEntryModal"
import { useHistory } from "react-router-dom"
import CalendarEntryModal2 from "../ui/modals/calendarEntry/CalendarEntryModal2"
import { trackSelectContent } from "../utils/GTMTracking"

const CalendarEntryContainer = styled.div`
    overflow: hidden;
`

const CalendarEntryContentRoot = styled.div`
    cursor: pointer;
    background-color: #fff;
    position: relative;
    overflow: hidden;

    &:hover {
        background-color: rgba(240, 240, 240, 1);
    }
`

const CalendarEntryListViewBorderBox = styled.div`
    margin-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
`

const CalendarEntryTitle = styled.div`
    margin-right: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    font-size: 16px;
    font-family: ${branding.font1};
    color: ${branding.calendarEntryListView.titleContentPanelTextColor ?? "#000"};
`

const CalendarEntryContent = styled.div`
    margin-top: 10px;
    display: flex;
`

const ParticipantsContent = styled.div`
    width: 135px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;

    .rs-avatar {
        margin-right: -10px;
    }
`

const AvatarContainer = styled.div`
    width: 40px;
    margin-right: -10px;
    position: relative;
`

const MenuPanel = styled.div`
    margin: auto;
`

const ContextMenuRoot = styled.div`
    &.visible {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`

const AvatarMorePanel = styled.div`
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 20px;
    border: solid black 1px;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
`

const DateContentPanel = styled.div<{ centered: boolean }>`
    font-family: ${branding.font1};
    color: ${branding.calendarEntryListView.dateContentPanelTextColor ?? "#000"};
    width: 135px;
    margin-top: ${(props) => (props.centered ? "auto" : 0)};
    margin-bottom: ${(props) => (props.centered ? "auto" : 0)};
`

const BorderPanel = styled.div`
    margin-left: 15px;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    color: ${branding.mainInfoColor ?? "black"};
`

type CalendarEntryListViewProps = {
    calendarEntry: CalendarEntry
    isPast: boolean
    onUpdated: (calendarEntry: CalendarEntry) => void
    onRemovedFromList: () => void
    numberOfAcceptedParticipants: number
    reloadScheduleList?: boolean
    setReloadScheduleList: (value: boolean) => void
}
const CalendarEntryListView: React.FunctionComponent<CalendarEntryListViewProps> = (props) => {
    const [viewMode, setViewMode] = useState<CalendarEntryModalViewMode | undefined>()
    const userLink = useLoggedInState()
    const profileId = userLink.user()?.profileId
    const [showCalendarEntry, setShowCalendarEntry] = useState<boolean>(false)

    const [showCancelMeetingModal, setShowCancelMeetingModal] = useState<boolean>(false)
    const [showDeclineMeetingModal, setShowDeclineMeetingModal] = useState<boolean>(false)

    const appState = useAppState()
    const myParticipation = props.calendarEntry.participants.items.find((item) => item.userId === profileId)
    const chimeContext = useChimeContext()
    const [contextMenuCollapsed, setContextMenuCollapsed] = useState<boolean>(true)
    const queryParams: any = queryString.parse(window.location.search)
    const history = useHistory()

    if (profileId === null || !myParticipation) {
        return null
    }

    var userIsCalendarEntryOwner = false
    if (props.calendarEntry.userId === profileId) {
        userIsCalendarEntryOwner = true
    }
    const myParticipationStatus = myParticipation.status
    const showParticipationMenu = myParticipationStatus === CalendarEntryParticipationStatus.REQUESTED && !props.isPast

    function onEditCalendarEntry() {
        setShowCalendarEntry(true)
    }

    async function onDeleteCalendarEntry() {
        setShowCancelMeetingModal(false)
        if (profileId != null) {
            const result = await deleteCalendarEntryForUser(profileId, props.calendarEntry.id)

            if (result != null) {
                props.onRemovedFromList()
                props.setReloadScheduleList(false)
                props.setReloadScheduleList(true)
            }
        }
        // TODO RETURN ERROR?
    }

    async function onDeclineCalendarEntry() {
        setShowDeclineMeetingModal(false)
        setShowCalendarEntry(false)
        onChangeCalendarEntryParticipationStatus(CalendarEntryParticipationStatus.DECLINED)
    }

    async function onChangeCalendarEntryParticipationStatus(status: CalendarEntryParticipationStatus) {
        if (profileId != null) {
            if (myParticipation) {
                const result = await updateCalendarEntryParticipationStatus(myParticipation.id, profileId, undefined, status)

                if (result != null) {
                    props.onRemovedFromList()
                    props.setReloadScheduleList(false)
                    props.setReloadScheduleList(true)
                    return
                }
            }
        }
        // TODO RETURN ERROR?
    }

    function declineMeeting() {
        setShowDeclineMeetingModal(true)
    }

    async function onCallParticipants() {
        if (branding.configuration.useConferenceRoomV2) {
            history.push(`/meetingV2/ce_${props.calendarEntry.id}`)
        } else {
            chimeContext.createOrJoinMeeting(props.calendarEntry.id, "calenderEntry")
        }
    }

    function onChatWithParticipants() {
        appState.setShowChatsTab(
            ChatConversationParam.conversationByConversationId(
                ConversationType.CALENDARENTRY,
                props.calendarEntry.id,
                props.calendarEntry.title,
                props.calendarEntry.description,
                props.calendarEntry.participants.items
                    .filter(
                        (participant) =>
                            participant.status === CalendarEntryParticipationStatus.ACCEPTED && participant.userId !== profileId
                    )
                    .map((participant) => participant.user)
            )
        )
    }

    function onExportMeeting() {
        saveMeetings([props.calendarEntry])
    }

    function checkViewMode() {
        if (!props.isPast && userIsCalendarEntryOwner) return CalendarEntryModalViewMode.EDIT
        else return CalendarEntryModalViewMode.VIEW
    }

    return (
        <>
            <CalendarEntryContainer>
                <CalendarEntryContentRoot
                    onClick={(event) => {
                        event.stopPropagation()
                        setViewMode(CalendarEntryModalViewMode.VIEW)
                        onEditCalendarEntry()
                    }}
                >
                    <CalendarEntryListViewBorderBox>
                        <CalendarEntryTitle>{props.calendarEntry.title}</CalendarEntryTitle>
                        <CalendarEntryContent>
                            <DateContent centered start={props.calendarEntry.start} end={props.calendarEntry.end} />
                            <ParticipantsContent>{getParticipantsContent(props.calendarEntry, profileId)}</ParticipantsContent>
                            {!showParticipationMenu && (
                                <MenuPanel>
                                    <HoverButton
                                        onClick={() => setContextMenuCollapsed(!contextMenuCollapsed)}
                                        selected={!contextMenuCollapsed}
                                    />
                                </MenuPanel>
                            )}
                        </CalendarEntryContent>
                    </CalendarEntryListViewBorderBox>
                </CalendarEntryContentRoot>
                {showParticipationMenu && (
                    <ParticipationMenu
                        numberOfAcceptedParticipants={props.numberOfAcceptedParticipants}
                        isPast={props.isPast}
                        calendarEntry={props.calendarEntry}
                        onUpdated={props.onUpdated}
                        onRemovedFromList={props.onRemovedFromList}
                        reloadScheduleList={props.reloadScheduleList}
                        setReloadScheduleList={props.setReloadScheduleList}
                    />
                )}
                <ContextMenuRoot className={!contextMenuCollapsed ? "visible" : ""}>
                    <ContextMenu
                        collapsed={contextMenuCollapsed}
                        items={() => {
                            const menuItems: MenuItemProps[] = []

                            // Edit/View button
                            menuItems.push({
                                title:
                                    userIsCalendarEntryOwner && !props.isPast
                                        ? branding.calendarEntryListView.editButtonTitle
                                        : branding.calendarEntryListView.viewButtonTitle,
                                icon:
                                    userIsCalendarEntryOwner && !props.isPast
                                        ? IconEdit({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })
                                        : IconSearch({ fill: branding.sideIconBar.sideIconColorDark }),
                                onClick: () => {
                                    setViewMode(checkViewMode())
                                    onEditCalendarEntry()

                                    trackSelectContent("EditMeeting", "Edit", "Meeting")
                                }
                            })
                            // Delete button
                            if (userIsCalendarEntryOwner && !props.isPast) {
                                menuItems.push({
                                    title: branding.calendarEntryListView.deleteButtonTitle,
                                    icon: IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "16", height: "16" }),
                                    onClick: () => setShowCancelMeetingModal(true)
                                })
                            }
                            // Accept button
                            if (
                                !userIsCalendarEntryOwner &&
                                !props.isPast &&
                                myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED
                            ) {
                                menuItems.push({
                                    title: branding.calendarEntryListView.acceptButtonTitle,
                                    icon: IconCheckmark({
                                        fill: branding.sideIconBar.sideIconColorDark,
                                        width: "22",
                                        height: "22"
                                    }),
                                    onClick: () => {
                                        onChangeCalendarEntryParticipationStatus(CalendarEntryParticipationStatus.ACCEPTED)

                                        trackSelectContent("AcceptRequest", "Accept", "Meeting")
                                    }
                                })
                            }
                            // Decline button
                            if (
                                (!userIsCalendarEntryOwner &&
                                    !props.isPast &&
                                    myParticipationStatus !== CalendarEntryParticipationStatus.DECLINED) ||
                                (props.isPast && myParticipationStatus !== CalendarEntryParticipationStatus.DECLINED)
                            ) {
                                menuItems.push({
                                    title: branding.calendarEntryListView.declineButtonTitle,
                                    icon: IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "16", height: "16" }),
                                    onClick: () => {
                                        declineMeeting()

                                        trackSelectContent("DeclineRequest", "Decline", "Meeting")
                                    }
                                })
                            }
                            // Call button
                            menuItems.push({
                                disabled:
                                    (isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding")) ||
                                    myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED ||
                                    props.isPast,
                                title: branding.calendarEntryListView.callButtonTitle,
                                icon: IconCamera({ fill: branding.sideIconBar.sideIconColorDark }),
                                onClick: () => onCallParticipants()
                            })
                            // Chat button
                            menuItems.push({
                                disabled: myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED,
                                title: branding.calendarEntryListView.chatButtonTitle,
                                icon: IconChat({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" }),
                                onClick: () => onChatWithParticipants()
                            })
                            // Export button
                            menuItems.push({
                                disabled: myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED,
                                title: branding.calendarEntryListView.exportButtonTitle,
                                icon: IconCalendarEntryDownload({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "20px",
                                    height: "20px"
                                }),
                                onClick: () => {
                                    onExportMeeting()

                                    trackSelectContent(
                                        "Meeting",
                                        "Download",
                                        "Meeting",
                                        undefined,
                                        undefined,
                                        "ics",
                                        `${props.calendarEntry.title}.ics`
                                    )
                                }
                            })

                            return menuItems
                        }}
                    />
                </ContextMenuRoot>
                <BorderPanel />
            </CalendarEntryContainer>

            {showCalendarEntry && (
                <CalendarEntryModal2
                    onUpdated={props.onUpdated}
                    onRemovedFromList={props.onRemovedFromList}
                    calendarEntry={props.calendarEntry}
                    viewMode={
                        // userIsCalendarEntryOwner && !props.isPast
                        //     ? CalendarEntryModalViewMode.EDIT
                        //     : CalendarEntryModalViewMode.VIEW
                        viewMode
                    }
                    close={() => {
                        setTimeout(() => {
                            setShowCalendarEntry(false)
                        })
                        props.setReloadScheduleList(false)
                        props.setReloadScheduleList(true)
                    }}
                    isPast={props.isPast}
                    setViewMode={setViewMode}
                />
            )}

            {showCancelMeetingModal && (
                <DeleteCalendarEntryModal
                    type="cancel"
                    show={showCancelMeetingModal}
                    animation={false}
                    backdrop="static"
                    setShowConfirmationDialog={() => setShowCancelMeetingModal(false)}
                    deleteCalendarEntry={() => onDeleteCalendarEntry()}
                />
            )}

            {showDeclineMeetingModal && (
                <DeleteCalendarEntryModal
                    type="decline"
                    show={showDeclineMeetingModal}
                    animation={false}
                    backdrop="static"
                    setShowConfirmationDialog={() => setShowDeclineMeetingModal(false)}
                    deleteCalendarEntry={() => onDeclineCalendarEntry()}
                />
            )}
        </>
    )
}

type DateContentProps = {
    start: string
    end: string
    centered: boolean
}

export function DateContent(props: DateContentProps) {
    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    const timezone = useAppState().timezone

    moment.locale(language)
    const startHour = moment(props.start).tz(timezone)
        ? moment(props.start).tz(timezone).format(branding.calendarEntryListView.dateHourFormat)
        : ""
    const endHour = moment(props.end).tz(timezone)
        ? moment(props.end).tz(timezone).format(branding.calendarEntryListView.dateHourFormat)
        : ""
    const day = moment(props.start).tz(timezone)
        ? moment(props.start).tz(timezone).format(branding.calendarEntryListView.dateDayFormat)
        : ""

    function getTimeString(): string {
        return language === "de"
            ? startHour + " - " + endHour + " Uhr"
            : startHour + " " + branding.calendarEntryListView.dateTo + " " + endHour
    }

    return (
        <DateContentPanel centered={props.centered}>
            <div>{getTimeString()}</div>
            <div>{day}</div>
        </DateContentPanel>
    )
}

function getParticipantsContent(calendarEntry: CalendarEntry, profileId: string | undefined) {
    const participations = calendarEntry.participants.items
    // Shift organizer participation to first index
    participations.unshift(
        participations.splice(
            participations.findIndex((participation) => participation.userId === calendarEntry.userId),
            1
        )[0]
    )
    const participantsCount = participations.length
    const maxParticipations = 4
    const minParticipations = 2
    const participationsToLarge = participantsCount > maxParticipations
    const slicedParticipations = participationsToLarge ? participations.slice(0, minParticipations) : participations

    return (
        <ParticipantsContent>
            {slicedParticipations.map((participation, index) => {
                return (
                    <div style={{ zIndex: 0 }}>
                        <AvatarContainer key={index} style={{ zIndex: slicedParticipations.length - index }}>
                            <AvatarWithPresenceState
                                badgeSize={15}
                                showAvatarBadge={true}
                                badgeRight={9}
                                badgeTop={31}
                                userId={participation.userId}
                                avatarSize={46}
                                content={{ pictureUrl: participation.user.pictureUrl, alt: participation.user.name }}
                            />
                        </AvatarContainer>
                    </div>
                )
            })}
            {participationsToLarge ? <AvatarMorePanel>{"+" + (participantsCount - minParticipations)}</AvatarMorePanel> : null}
        </ParticipantsContent>
    )
}

function ParticipationMenu(props: CalendarEntryListViewProps) {
    const userLink = useLoggedInState()
    const participations = props.calendarEntry.participants.items

    const [showDeclineMeetingModal, setShowDeclineMeetingModal] = useState<boolean>(false)

    const profileId = userLink.user()?.profileId
    let participationMenuElement = null

    function declineMeeting() {
        setShowDeclineMeetingModal(true)
    }

    async function onAcceptClicked(participation: CalendarEntryParticipation) {
        const result = await updateCalendarEntryParticipationStatus(
            participation.id,
            participation.userId,
            undefined,
            CalendarEntryParticipationStatus.ACCEPTED
        )

        if (result != null) {
            props.onRemovedFromList()
            props.setReloadScheduleList(false)
            props.setReloadScheduleList(true)
        } else {
            // TODO ERROR
        }
    }

    async function onDeclineClicked(participation: CalendarEntryParticipation) {
        setShowDeclineMeetingModal(false)

        const result = await updateCalendarEntryParticipationStatus(
            participation.id,
            participation.userId,
            undefined,
            CalendarEntryParticipationStatus.DECLINED
        )

        if (result != null) {
            props.onRemovedFromList()
            props.setReloadScheduleList(false)
            props.setReloadScheduleList(true)
        } else {
            // TODO ERROR
        }
    }

    if (profileId != null) {
        participations.forEach((participation) => {
            if (
                profileId === participation.userId &&
                participation.status === CalendarEntryParticipationStatus.REQUESTED &&
                !props.isPast
            ) {
                participationMenuElement = (
                    <>
                        <ContextMenu
                            collapsed={false}
                            items={() => [
                                {
                                    title: branding.calendarEntryListView.acceptTitle,
                                    icon: IconCheckmark({
                                        fill: branding.sideIconBar.sideIconColorDark,
                                        width: "22",
                                        height: "22"
                                    }),
                                    onClick: () => onAcceptClicked(participation)
                                },
                                {
                                    title: branding.calendarEntryListView.declineTitle,
                                    icon: IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "16", height: "16" }),
                                    onClick: () => declineMeeting()
                                }
                            ]}
                        />
                        {showDeclineMeetingModal && (
                            <DeleteCalendarEntryModal
                                type="decline"
                                show={showDeclineMeetingModal}
                                animation={false}
                                backdrop="static"
                                setShowConfirmationDialog={() => setShowDeclineMeetingModal(false)}
                                deleteCalendarEntry={() => onDeclineClicked(participation)}
                            />
                        )}
                    </>
                )
                return
            }
        })
        return participationMenuElement
    }
    return null
}

export default CalendarEntryListView
