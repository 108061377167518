export enum GTMEventType {
    login = "login",
    sign_up = "sign_up",
    search = "search",
    select_content = "select_content",
    take_action = "take_action",
    page_view = "page_view",
    share = "share",
    view_promotion = "view_promotion",
    view_search_result_list = "view_search_result_list",
    banner_click = "banner_click",
    video_engagement = "video_engagement", // used only for branding
    video_start = "video_start",
    video_progress = "video_progress",
    video_complete = "video_complete"
}

export interface CustomerTypes {
    /**
     * @title Customer type staff
     * @description ...
     * @title_de Customer type staff
     * @description_de ...
     */
    staff: string

    /**
     * @title Customer type attendee
     * @description ...
     * @title_de Customer type attendee
     * @description_de ...
     */
    attendee: string

    /**
     * @title Customer type guest
     * @description ...
     * @title_de Customer type guest
     * @description_de ...
     */
    guest: string

    /**
     * @title Customer type press
     * @description ...
     * @title_de Customer type press
     * @description_de ...
     */
    press: string

    /**
     * @title Customer type other
     * @description ...
     * @title_de Customer type other
     * @description_de ...
     */
    other: string
}

export type LoginState = "Logged_in" | "Logged_out"

export interface GTMConfig {
    /**
     * @title GTM script
     * @description Google Tag Manager's initially loaded script
     * @title_de GTM Skript
     * @description_de Das ursprünglich geladene Skript von Google Tag Manager
     */
    tagScript: string

    /**
     * @title GTM noscript
     * @description Google Tag Manager's noscript element
     * @title_de GTM noscript
     * @description_de Noscript-Element von Google Tag Manager
     */
    noScript: string

    /**
     * @title GTM fair number
     * @description Code used by Google Tag Manager for single Event Guide's tracking
     * @title_de GTM-Messenummer
     * @description_de Code, der vom Google Tag Manager für die Nachverfolgung einzelner Event Guides verwendet wird
     */
    fairNumber: string

    /**
     * @title GTM event types
     * @description Configure list of event types used in this Event Guide instance
     * @title_de GTM event types
     * @description_de Konfigurieren Sie die Liste der in dieser Event Guide-Instanz verwendeten Event-Typen
     */
    eventTypes: GTMEventType[]

    /**
     * @title Customer types
     * @description ...
     * @title_de Customer types
     * @description_de ...
     */
    customerTypes: CustomerTypes
}
