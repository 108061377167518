import { SecondaryButtonRoot } from "./SecondaryButton.styled"

interface ButtonProps {
    text: string
    onClickHandler: () => void
}

export function SecondaryButton(props: ButtonProps) {
    return <SecondaryButtonRoot onClick={props.onClickHandler}>{props.text}</SecondaryButtonRoot>
}

export default SecondaryButton
