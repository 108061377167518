import {
    ContentShare,
    useContentShareState,
    useRemoteVideoTileState,
    useRosterState
} from "amazon-chime-sdk-component-library-react"
import { useEffect, useState } from "react"
import RemoteVideo from "../RemoteVideo/RemoteVideo"
import { ContentShareRoot, ContentShareUserVideoRoot } from "./ContentShare.styled"

function ContentShareCustom() {
    const { tiles, attendeeIdToTileId } = useRemoteVideoTileState()
    const { sharingAttendeeId } = useContentShareState()
    const { roster } = useRosterState()
    const [attendees, setAttendees] = useState(Object.values(roster))
    const [sharingAttendee, setSharingAttendee] = useState<any>(
        attendees.filter((attendee: any) => attendee.chimeAttendeeId === sharingAttendeeId?.replace("#content", ""))[0]
    )

    useEffect(() => {
        setAttendees(Object.values(roster))
    }, [roster])

    useEffect(() => {
        setSharingAttendee(
            attendees.filter((attendee: any) => attendee.chimeAttendeeId === sharingAttendeeId?.replace("#content", ""))[0]
        )
    }, [attendees, sharingAttendeeId, roster, attendeeIdToTileId, tiles])

    return (
        <ContentShareRoot>
            <ContentShare />
            {tiles.map((tileId) => {
                return (
                    tileId === attendeeIdToTileId[sharingAttendeeId?.replace("#content", "") || ""] && (
                        <ContentShareUserVideo attendee={sharingAttendee} tileId={tileId} />
                    )
                )
            })}
        </ContentShareRoot>
    )
}

interface ContentShareUserVideoProps {
    tileId: number
    attendee: any
}

function ContentShareUserVideo(props: ContentShareUserVideoProps) {
    const { tileId, attendee } = props

    return (
        <ContentShareUserVideoRoot>{attendee && <RemoteVideo attendee={attendee} tileId={tileId} />}</ContentShareUserVideoRoot>
    )
}

export default ContentShareCustom
