import { MicrophoneActivity, useActiveSpeakersState, useAttendeeStatus } from "amazon-chime-sdk-component-library-react"
import { useContext } from "react"
import { AvatarWithDefault } from "../../../../ui/AvatarWithDefault"
import { IconHandYellow } from "../../../../ui/Icons"
import { RaisedHandContext } from "../../../ConferenceRoom/ConferenceRoom"
import { AvatarContainer, AvatarNamePlate } from "../../RemoteAvatar/RemoteAvatar.styled"
import { RemoteAvatarMiniRoot } from "./RemoteAvatarMini.styled"

interface RemoteAvatarMiniProps {
    attendee: any
    isMouseHovering: boolean
}

export function RemoteAvatarMini(props: RemoteAvatarMiniProps) {
    const { attendee, isMouseHovering } = props
    const raisedHands = useContext(RaisedHandContext)
    const avatarSize = window.innerWidth <= 1920 ? 60 : 80
    const { muted } = useAttendeeStatus(attendee?.chimeAttendeeId)
    const activeSpeakers = useActiveSpeakersState()

    return (
        <RemoteAvatarMiniRoot>
            <AvatarContainer
                isHandRaised={raisedHands && raisedHands.includes(attendee?.id)}
                isSpeaking={(!muted && activeSpeakers && activeSpeakers.indexOf(attendee?.chimeAttendeeId) > -1) || false}
                showAvatarBorderAnimations={true}
            >
                <AvatarWithDefault
                    size={avatarSize}
                    badgesize={avatarSize}
                    alt={attendee?.name ? attendee?.name : ""}
                    src={attendee?.avatarUrl}
                    backgroundSize="cover"
                />
            </AvatarContainer>

            {!isMouseHovering && (
                <AvatarNamePlate>
                    {raisedHands && raisedHands.includes(attendee?.id) && <IconHandYellow height="18px" width="18px" />}
                    {attendee?.name}
                    <MicrophoneActivity className="activity" attendeeId={attendee?.chimeAttendeeId} />
                </AvatarNamePlate>
            )}
        </RemoteAvatarMiniRoot>
    )
}
