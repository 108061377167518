import { PrimaryButtonRoot } from "./PrimaryButton.styled"

interface ButtonProps {
    text: string
    onClickHandler: () => void
}

export function PrimaryButton(props: ButtonProps) {
    return <PrimaryButtonRoot onClick={props.onClickHandler}>{props.text}</PrimaryButtonRoot>
}

export default PrimaryButton
