import styled from "styled-components"

export const LogoPositionButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3px;
`

export const LogoPositionButton = styled.div<{ isActive?: boolean }>`
    background-color: ${(props) => (props.isActive ? " rgba(102, 102, 102, 0.3)" : "#666666")};
    border-radius: 3px;
    cursor: pointer;
    color: #fff;
    padding: 7px;
    font-size: 12px;
    line-height: 17px;
    transition: 0.3s;
    margin-right: 13px;
    width: 100%;
    text-align: center;

    :hover {
        background-color: rgba(102, 102, 102, 0.3);
    }
    svg {
        margin-right: 5px;
    }
`

export const LogoGalleryContainer = styled.div`
    display: grid;
    grid-gap: 3px;
    row-gap: 3px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 80px;
    height: 186px;
    overflow-y: auto;
`

export const LogoGalleryItem = styled.div<{ logo: string; isActive?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-image: ${(props) => (props.logo ? `url(${props.logo})` : "none")};
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: ${(props) => (props.isActive ? "inset 0px 0px 0px 2px #6DD400" : "none")};
    transition: box-shadow 0.3s;
    background-position: center;
    background-color: #37383a;
    cursor: pointer;
    border-radius: 3px;
    position: relative;
    text-align: center;
    line-height: 17px;
    font-size: 12px;

    overflow: hidden;

    :hover {
        box-shadow: inset 0px 0px 0px 2px #6dd400;
    }

    .actions {
        width: 20px;
        height: 18px;
        position: absolute;
        z-index: 20;
        right: 6px;
        top: 6px;
        svg:hover path {
            fill: red;
        }
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 3px;
    }
`
